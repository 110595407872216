import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import router from './router'
import store from './store'
import i18n from "@/plugins/i18n"
import {createGtm} from '@gtm-support/vue-gtm';

require('./assets/main.css')

loadFonts()

/** Create Vue application */
const app = createApp(App);
app.config.productionTip = false;

/** Use application extensions */
app.use(store)
    .use(router)
    .use(vuetify)
    .use(i18n)
    .use(
        createGtm({
            id: process.env.VUE_APP_GTM_ID,
            vueRouter: router
        })
    )

/** Register global components */
import LanguageSelector from "@/components/general/LanguageSelector";
import AppBar from "@/components/general/AppBar";
import AppFooter from "@/components/general/AppFooter";
import MakerLogo from "@/components/general/MakerLogo";
import EnglishLanguage from "@/components/general/languages/EnglishLanguage";
import PolishLanguage from "@/components/general/languages/PolishLanguage";
import UkrainianLanguage from "@/components/general/languages/UkrainianLanguage";
import ServiceLogo from "@/components/general/ServiceLogo";
import PedometerMenu from "@/components/form/PedometerMenu";
import CustomerRequestTypeDialog from "@/components/form/CustomerRequestTypeDialog";
import ComplaintTypeStep from "@/components/form/steps/ComplaintTypeStep";
import SubmitMenu from "@/components/form/SubmitMenu";
import AnimatedButton from "@/components/buttons/AnimatedButton";
import DeviceDetailsStep from "@/components/form/steps/DeviceDetailsStep";
import DeviceFaultsStep from "@/components/form/steps/DeviceFaultsStep";
import IncludedItemsStep from "@/components/form/steps/IncludedItemsStep";
import DeviceVisualConditionsStep from "@/components/form/steps/DeviceVisualConditionsStep";
import ClaimAttachmentsStep from "@/components/form/steps/ClaimAttachmentsStep";
import PersonalDataStep from "@/components/form/steps/PersonalDataStep";
import TransportStep from "@/components/form/steps/TransportStep";
import TransportInStep from "@/components/form/steps/TransportInStep";
import TransportOutStep from "@/components/form/steps/TransportOutStep";
import EcoTransportIn from "@/components/form/transport/EcoTransportIn";
import StandardTransportIn from "@/components/form/transport/StandardTransportIn";
import AppAlert from "@/components/general/AppAlert";
import FormHeader from "@/components/general/FormHeader";
import AppConfirm from "@/components/general/AppConfirm";
import AppInfoDialog from "@/components/general/AppInfoDialog";
import EcoTransportOut from "@/components/form/transport/EcoTransportOut";
import StandardTransportOut from "@/components/form/transport/StandardTransportOut";
import InpostGeoWidget from "@/components/form/transport/InpostGeoWidget";
import RegulationsStep from "@/components/form/steps/RegulationsStep";
import SummaryStep from "@/components/form/steps/SummaryStep";
import RegulationsGraphic from "@/components/graphics/RegulationsGraphic";
import MailGraphic from "@/components/graphics/MailGraphic";
import RegulationsImportantNote from "@/components/form/RegulationsImportantNote";
import RegulationsRodoDeclarations from "@/components/form/RegulationsRodoDeclarations";
import DhlGeoWidget from "@/components/form/transport/DhlGeoWidget";

/** Language */
app.component('app-language-selector', LanguageSelector)
app.component('app-language-english', EnglishLanguage)
app.component('app-language-polish', PolishLanguage)
app.component('app-language-ukrainian', UkrainianLanguage)

/** General */
app.component('app-main-bar', AppBar)
app.component('app-main-footer', AppFooter)
app.component('app-maker-logo', MakerLogo)
app.component('app-service-logo', ServiceLogo)
app.component('app-alert', AppAlert)
app.component('app-confirm', AppConfirm)
app.component('app-info-dialog', AppInfoDialog)
app.component('app-form-header', FormHeader)

/** Form */
app.component('app-form-pedometer-menu', PedometerMenu)
app.component('app-form-submit-menu', SubmitMenu)
app.component('app-animated-button', AnimatedButton)
app.component('app-customer-request-type-dialog', CustomerRequestTypeDialog)

/** Form - Steps */
app.component('app-form-step-complaint-type', ComplaintTypeStep)
app.component('app-form-step-device-details', DeviceDetailsStep)
app.component('app-form-step-device-faults', DeviceFaultsStep)
app.component('app-form-step-included-items', IncludedItemsStep)
app.component('app-form-step-device-visual-conditions', DeviceVisualConditionsStep)
app.component('app-form-step-claim-attachments', ClaimAttachmentsStep)
app.component('app-form-step-personal-data', PersonalDataStep)
app.component('app-form-step-transport', TransportStep)
app.component('app-form-step-transport-in', TransportInStep)
app.component('app-form-step-transport-out', TransportOutStep)
app.component('app-form-step-regulations', RegulationsStep)
app.component('app-regulations-important-note',RegulationsImportantNote)
app.component('app-regulations-rodo',RegulationsRodoDeclarations)
app.component('app-form-step-summary', SummaryStep)

/** Form - Transport */
app.component('app-form-transport-eco-in', EcoTransportIn)
app.component('app-form-transport-standard-in', StandardTransportIn)
app.component('app-form-transport-eco-out', EcoTransportOut)
app.component('app-form-transport-standard-out', StandardTransportOut)
app.component('app-form-transport-inpost-geo-widget', InpostGeoWidget)
app.component('app-form-transport-dhl-geo-widget', DhlGeoWidget)


/** Graphics */
app.component('app-graphic-regulations', RegulationsGraphic)
app.component('app-graphic-mail', MailGraphic)

/** Mount Vue application */
app.mount('#app')
