<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_837)">
            <path
              d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M29.29 31.66H31.49C32.36 31.66 33.05 32.36 33.05 33.22V42.03C33.05 42.9 32.35 43.59 31.49 43.59H29.29C28.42 43.59 27.73 42.89 27.73 42.03V33.22C27.73 32.35 28.43 31.66 29.29 31.66Z"
                fill="#0A863C" />
              <path
                d="M29.29 47.07H31.49C32.36 47.07 33.05 47.77 33.05 48.63V53.03C33.05 53.9 32.35 54.59 31.49 54.59H29.29C28.42 54.59 27.73 53.89 27.73 53.03V48.63C27.73 47.76 28.43 47.07 29.29 47.07Z"
                fill="#0A863C" />
              <path
                d="M82.6999 114.05H37.9699C33.6499 114.05 30.1499 110.55 30.1499 106.23V21.87C30.1499 17.55 33.6499 14.05 37.9699 14.05H82.6999C87.0199 14.05 90.5199 17.55 90.5199 21.87V106.24C90.5199 110.56 87.0199 114.06 82.6999 114.06V114.05ZM37.9699 17.17C36.7299 17.17 35.5399 17.67 34.6599 18.54C33.7799 19.42 33.2799 20.61 33.2799 21.86V106.23C33.2799 107.47 33.7699 108.67 34.6599 109.55C35.5399 110.43 36.7299 110.92 37.9699 110.92H82.6999C85.2899 110.92 87.3899 108.82 87.3899 106.23V21.87C87.3899 19.28 85.2899 17.18 82.6999 17.18H37.9699V17.17Z"
                fill="#0A863C" />
              <path
                d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
                fill="#D0F4DB" />
              <path
                d="M77.83 42.1L73.62 41.07C73.37 40.27 73.04 39.5 72.65 38.76C73.28 37.71 74.61 35.5 74.61 35.5C74.86 35.08 74.8 34.54 74.45 34.19L71.41 31.15C71.06 30.8 70.52 30.73 70.1 30.99L66.83 32.95C66.09 32.56 65.33 32.24 64.53 31.99L63.5 27.78C63.38 27.3 62.95 26.97 62.46 26.97H58.16C57.67 26.97 57.24 27.31 57.12 27.78C57.12 27.78 56.4 30.72 56.09 31.98C55.25 32.25 54.43 32.59 53.65 33.02L50.26 30.99C49.84 30.74 49.3 30.8 48.95 31.15L45.91 34.19C45.56 34.54 45.49 35.08 45.75 35.5L47.85 39C47.51 39.67 47.23 40.36 47 41.07L42.79 42.11C42.31 42.23 41.98 42.66 41.98 43.15V47.45C41.98 47.94 42.32 48.37 42.79 48.49L47 49.52C47.26 50.33 47.59 51.11 47.99 51.87L46.11 55C45.86 55.42 45.92 55.96 46.27 56.31L49.31 59.35C49.66 59.7 50.2 59.76 50.62 59.51C50.62 59.51 52.72 58.25 53.75 57.63C54.5 58.03 55.28 58.36 56.08 58.61L57.11 62.81C57.23 63.29 57.66 63.62 58.15 63.62H62.45C62.94 63.62 63.37 63.28 63.49 62.81L64.52 58.6C65.35 58.34 66.14 58 66.9 57.59C67.96 58.22 70.08 59.5 70.08 59.5C70.5 59.75 71.04 59.69 71.39 59.34L74.43 56.3C74.78 55.95 74.85 55.41 74.59 54.99L72.66 51.77C73.04 51.05 73.36 50.29 73.6 49.51L77.81 48.48C78.29 48.36 78.62 47.93 78.62 47.44V43.14C78.62 42.65 78.28 42.22 77.81 42.1H77.83ZM60.31 56.25C54.26 56.25 49.35 51.34 49.35 45.29C49.35 39.24 54.26 34.33 60.31 34.33C66.36 34.33 71.27 39.24 71.27 45.29C71.27 51.34 66.36 56.25 60.31 56.25Z"
                fill="#0A863C" />
              <path
                d="M89.0201 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0201 39.6C75.5167 39.6 64.5701 50.5466 64.5701 64.05C64.5701 77.5534 75.5167 88.5 89.0201 88.5Z"
                fill="#0A863C" />
              <path
                d="M89.0201 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0201 39.6C75.5167 39.6 64.5701 50.5466 64.5701 64.05C64.5701 77.5534 75.5167 88.5 89.0201 88.5Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                fill="white" />
              <path
                d="M100.49 72.61L94.95 67.07C95.89 65.57 96.44 63.8 96.44 61.9C96.44 56.53 92.08 52.17 86.71 52.17C81.34 52.17 76.98 56.53 76.98 61.9C76.98 67.27 81.34 71.63 86.71 71.63C88.78 71.63 90.69 70.98 92.27 69.89L97.74 75.36C98.12 75.74 98.62 75.93 99.12 75.93C99.62 75.93 100.12 75.74 100.5 75.36C101.26 74.6 101.26 73.37 100.5 72.61H100.49ZM86.71 68.48C83.08 68.48 80.13 65.54 80.13 61.9C80.13 58.26 83.08 55.32 86.71 55.32C90.34 55.32 93.29 58.27 93.29 61.9C93.29 65.53 90.34 68.48 86.71 68.48Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_837">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">
          {{ $t('buttons.phone_visual_condition_other') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "PhoneVisualConditionBrokenDisplay",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>