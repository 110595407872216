<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_50)">
            <path
              d="M50.32 102.11C71.6437 102.11 88.93 84.8237 88.93 63.5C88.93 42.1763 71.6437 24.89 50.32 24.89C28.9963 24.89 11.71 42.1763 11.71 63.5C11.71 84.8237 28.9963 102.11 50.32 102.11Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M74.12 23.76H35.88C34.8472 23.76 34.01 24.5972 34.01 25.63V107.11C34.01 108.143 34.8472 108.98 35.88 108.98H74.12C75.1528 108.98 75.99 108.143 75.99 107.11V25.63C75.99 24.5972 75.1528 23.76 74.12 23.76Z"
                fill="#D0F4DB" />
              <path
                d="M75.44 113.99H34.55C31.49 113.99 28.99 111.5 28.99 108.43V24.32C28.99 21.26 31.48 18.76 34.55 18.76H75.44C78.5 18.76 81 21.25 81 24.32V108.43C81 111.49 78.51 113.99 75.44 113.99ZM34.56 21.77C33.15 21.77 32 22.92 32 24.33V108.44C32 109.85 33.15 111 34.56 111H75.45C76.86 111 78.01 109.85 78.01 108.44V24.32C78.01 22.91 76.86 21.76 75.45 21.76H34.56V21.77Z"
                fill="#0A863C" />
              <path
                d="M45.91 13.93H64.08C66.15 13.93 67.83 15.61 67.83 17.68V21.1H42.16V17.68C42.16 15.61 43.84 13.93 45.91 13.93Z"
                fill="#0A863C" />
              <path
                d="M71.13 93.16H38.86C37.7554 93.16 36.86 94.0554 36.86 95.16V103.96C36.86 105.065 37.7554 105.96 38.86 105.96H71.13C72.2346 105.96 73.13 105.065 73.13 103.96V95.16C73.13 94.0554 72.2346 93.16 71.13 93.16Z"
                fill="#0A863C" />
              <path
                d="M71.13 76.31H38.86C37.7554 76.31 36.86 77.2054 36.86 78.31V87.11C36.86 88.2146 37.7554 89.11 38.86 89.11H71.13C72.2346 89.11 73.13 88.2146 73.13 87.11V78.31C73.13 77.2054 72.2346 76.31 71.13 76.31Z"
                fill="#0A863C" />
              <path
                d="M71.13 59.46H38.86C37.7554 59.46 36.86 60.3554 36.86 61.46V70.26C36.86 71.3646 37.7554 72.26 38.86 72.26H71.13C72.2346 72.26 73.13 71.3646 73.13 70.26V61.46C73.13 60.3554 72.2346 59.46 71.13 59.46Z"
                fill="#0A863C" />
              <path
                d="M89.02 88.49C102.523 88.49 113.47 77.5434 113.47 64.04C113.47 50.5366 102.523 39.59 89.02 39.59C75.5166 39.59 64.57 50.5366 64.57 64.04C64.57 77.5434 75.5166 88.49 89.02 88.49Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.59C102.52 39.59 113.47 50.54 113.47 64.04C113.47 77.54 102.52 88.49 89.02 88.49C75.52 88.49 64.57 77.54 64.57 64.04C64.57 50.54 75.52 39.59 89.02 39.59ZM89.02 36.77C73.98 36.77 61.75 49 61.75 64.04C61.75 79.08 73.98 91.31 89.02 91.31C104.06 91.31 116.29 79.08 116.29 64.04C116.29 49 104.06 36.77 89.02 36.77Z"
                fill="white" />
              <path
                d="M100.05 60.61C99.88 60.25 99.51 60.01 99.11 60.02L90.19 60.07L90.14 60.01L91.37 51.49C91.44 51.03 91.19 50.58 90.76 50.4C90.63 50.34 90.49 50.31 90.34 50.31C90.03 50.31 89.73 50.45 89.54 50.7L78.13 64.93C77.88 65.24 77.83 65.66 78 66.02C78.17 66.38 78.53 66.61 78.93 66.61H86.97L87.02 66.67L84.96 76.54C84.86 77 85.09 77.48 85.52 77.68C85.95 77.89 86.46 77.78 86.76 77.41L99.91 61.71C100.17 61.4 100.22 60.97 100.05 60.61Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_50">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">{{
          $t('buttons.included_item_battery')
        }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "IncludedItemBattery",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>