<template>

  <v-row dense no-gutters>
    <v-col>
      <v-card elevation="1" color="options_menu" class="pa-0" min-height="750">
        <v-card-text>
          <iframe src="https://parcelshop.dhl.pl/mapa" width="100%" height="750px"></iframe>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {useDisplay} from "vuetify";
import {computed} from "vue";

export default {
  name: "DhlGeoWidget",
  data() {
    return {
    }
  },
  setup() {
    const {name} = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs':
          return {
            density: 'compact',
          }
        case 'sm':
          return {
            density: 'comfortable',
          }

        default:
          return {
            density: 'default',
          }
      }
    });
    return {classes}
  },

  computed: {
    ...mapGetters('ClaimForm', {claim_form: 'claim_form'}),
  },
  methods: {
    ...mapActions('ClaimForm', {updateClaimForm: 'updateClaimForm', syncValue: 'syncValue'}),
    afterPointSelected(event) {
      let point = null;
      if (typeof event.data == 'object') {
        point = event.data;
      } else {
        point = JSON.parse(event.data);
      }

      console.log(point)

      if (point.id && point.sap) {
        this.updateClaimForm({
          key: 'transport_dhl_parcel_shop', value: point
        });
      }
      this.$emit('closeDHLDialog');
    }
  },
  created() {
    if (window.addEventListener) {
      window.addEventListener("message", this.afterPointSelected, false);
    } else {
      window.attachEvent("onmessage", this.afterPointSelected);
    }
  },
}

</script>

<style scoped>

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

</style>