import {createI18n} from 'vue-i18n'

import pl from '../locales/pl.json'
import en from '../locales/en.json'
import uk from '../locales/uk.json'
import de from '../locales/de.json'
import fr from '../locales/fr.json'
export default createI18n({
    locale: 'pl',
    messages: {
        pl, en, uk, de, fr
    }
})