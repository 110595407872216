import DeviceModelService from "@/services/DeviceModelService";

export const namespaced = true;

const getDefaultState = () => {
    return {
        device_models: [],
        loading: false,
        error: {},
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_DEVICE_MODELS(state, device_models) {
        state.device_models = device_models;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

export const actions = {
    getDeviceModels({commit}, caller) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", {});
        let payload = {
            include_producer_detail: false
        };
        if (caller.claim_form.maker) payload.producer_name = caller.claim_form.maker.name
        else payload.producer_name = caller.name
        /** #2426 - XIAOMI_DE CLIENT */
        if (
            caller.claim_form.fixed_client !== null
            && (caller.claim_form.fixed_client.toUpperCase() === 'XIAOMI_DE' || caller.claim_form.fixed_client.toUpperCase() === "XIAOMI_FR")
        ) {
            payload.client = 'XIAOMI_DE';
        }
        if (payload.producer_name) {
            DeviceModelService.index(payload)
                .then((response) => {
                    //remove duplicated model_handel names and containing "live demo"
                    const seen = new Set();
                    let models = response.data;
                    const pok = (caller.claim_form.fixed_client == 'pok') ?? false;
                    const modelsFiltered = models.filter(el => {
                        if (pok && el.model_handel.toLowerCase().includes("live demo")) {
                            return false;
                        }
                        const duplicate = seen.has(el.model_handel);
                        seen.add(el.model_handel);
                        return !duplicate;
                    });
                    commit('RESET_STATE');
                    commit("SET_DEVICE_MODELS", modelsFiltered);
                    commit("SET_LOADING", false);
                }).catch(() => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", true);
                return false;
            });
        } else {
            commit("SET_LOADING", false);
        }
        return true;
    },
    getDeviceModel({commit}, caller) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", {});
        let payload = {
            model_serwis: caller.model_serwis,
            producer_name: caller.producer_name
        };
        if (payload.model_serwis) {
            DeviceModelService.getModel(payload)
                .then((response) => {

                    const { models, ...producer } = response.data[0];

                    caller.updateClaimForm({key: 'maker', value: producer});
                    caller.updateClaimForm({key: 'device_model', value: models[0]});

                    commit('RESET_STATE');
                    commit("SET_LOADING", false);
                }).catch(() => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", true);
                return false;
            });
        } else {
            commit("SET_LOADING", false);
        }
        return true;
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
};

export const getters = {
    device_models: (state) => {
        return state.device_models;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};
