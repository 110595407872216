<template>
      <div>

            <v-row>
                  <v-col class="pb-0">
                        <svg @mouseenter="hovered = true"
                              @mouseleave="hovered = false" :width="width"
                              :height="height" viewBox="0 0 128 128" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_2_709)">
                                    <path d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
                                          :fill="c_base_color" />
                                    <g :class="{ hovered: hovered }">
                                          <path d="M29.29 31.66H31.49C32.36 31.66 33.05 32.36 33.05 33.22V42.03C33.05 42.9 32.35 43.59 31.49 43.59H29.29C28.42 43.59 27.73 42.89 27.73 42.03V33.22C27.73 32.35 28.43 31.66 29.29 31.66Z"
                                                fill="#0A863C" />
                                          <path d="M29.29 47.07H31.49C32.36 47.07 33.05 47.77 33.05 48.63V53.03C33.05 53.9 32.35 54.59 31.49 54.59H29.29C28.42 54.59 27.73 53.89 27.73 53.03V48.63C27.73 47.76 28.43 47.07 29.29 47.07Z"
                                                fill="#0A863C" />
                                          <path d="M82.6999 114.05H37.9699C33.6499 114.05 30.1499 110.55 30.1499 106.23V21.87C30.1499 17.55 33.6499 14.05 37.9699 14.05H82.6999C87.0199 14.05 90.5199 17.55 90.5199 21.87V106.24C90.5199 110.56 87.0199 114.06 82.6999 114.06V114.05ZM37.9699 17.17C36.7299 17.17 35.5399 17.67 34.6599 18.54C33.7799 19.42 33.2799 20.61 33.2799 21.86V106.23C33.2799 107.47 33.7699 108.67 34.6599 109.55C35.5399 110.43 36.7299 110.92 37.9699 110.92H82.6999C85.2899 110.92 87.3899 108.82 87.3899 106.23V21.87C87.3899 19.28 85.2899 17.18 82.6999 17.18H37.9699V17.17Z"
                                                fill="#0A863C" />
                                          <path d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
                                                fill="#D0F4DB" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M58.6799 28.23V31.5C58.6799 32.4 59.4099 33.14 60.3199 33.14C61.2299 33.14 61.9599 32.41 61.9599 31.5V28.23C61.9599 27.33 61.2299 26.59 60.3199 26.59C59.4099 26.59 58.6799 27.32 58.6799 28.23Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M45.32 37.83L48.15 39.47C48.93 39.92 49.93 39.65 50.39 38.87C50.84 38.09 50.57 37.09 49.79 36.63L46.96 34.99C46.18 34.54 45.18 34.81 44.72 35.59C44.27 36.37 44.54 37.37 45.32 37.83Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M46.96 54.19L49.79 52.55C50.57 52.1 50.84 51.1 50.39 50.31C49.94 49.53 48.94 49.26 48.15 49.71L45.32 51.35C44.54 51.8 44.27 52.8 44.72 53.59C45.17 54.37 46.17 54.64 46.96 54.19Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M43.9501 46.23H47.2201C48.1201 46.23 48.8601 45.5 48.8601 44.59C48.8601 43.68 48.1301 42.95 47.2201 42.95H43.9501C43.0501 42.95 42.3101 43.68 42.3101 44.59C42.3101 45.5 43.0401 46.23 43.9501 46.23Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M53.55 59.58L55.19 56.75C55.64 55.97 55.37 54.97 54.59 54.51C53.81 54.06 52.81 54.33 52.35 55.11L50.71 57.94C50.26 58.72 50.53 59.73 51.31 60.18C52.09 60.63 53.09 60.36 53.55 59.58Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M69.91 57.94L68.27 55.11C67.82 54.33 66.82 54.06 66.03 54.51C65.25 54.96 64.98 55.96 65.43 56.75L67.07 59.58C67.52 60.36 68.52 60.63 69.31 60.18C70.09 59.73 70.36 58.73 69.91 57.94Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M58.6799 57.68V60.95C58.6799 61.85 59.4099 62.59 60.3199 62.59C61.2299 62.59 61.9599 61.86 61.9599 60.95V57.68C61.9599 56.78 61.2299 56.04 60.3199 56.04C59.4099 56.04 58.6799 56.77 58.6799 57.68Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M70.83 52.55L73.66 54.19C74.44 54.64 75.44 54.37 75.9 53.59C76.35 52.81 76.08 51.81 75.3 51.35L72.47 49.71C71.69 49.26 70.69 49.53 70.23 50.31C69.78 51.09 70.05 52.09 70.83 52.55Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M72.47 39.46L75.3 37.82C76.08 37.37 76.35 36.37 75.9 35.58C75.45 34.8 74.45 34.53 73.66 34.98L70.83 36.62C70.05 37.07 69.78 38.07 70.23 38.86C70.68 39.64 71.68 39.91 72.47 39.46Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M73.4 46.23H76.67C77.57 46.23 78.31 45.5 78.31 44.59C78.31 43.68 77.58 42.95 76.67 42.95H73.4C72.5 42.95 71.76 43.68 71.76 44.59C71.76 45.5 72.49 46.23 73.4 46.23Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M68.27 34.07L69.91 31.24C70.36 30.46 70.09 29.46 69.31 29C68.53 28.55 67.53 28.82 67.07 29.6L65.43 32.43C64.98 33.21 65.25 34.21 66.03 34.67C66.81 35.12 67.81 34.85 68.27 34.07Z"
                                                fill="#0A863C" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M55.18 32.44L53.54 29.61C53.09 28.83 52.09 28.56 51.3 29.01C50.52 29.46 50.25 30.46 50.7 31.25L52.34 34.08C52.79 34.86 53.79 35.13 54.58 34.68C55.36 34.23 55.63 33.23 55.18 32.44Z"
                                                fill="#0A863C" />
                                          <path d="M89.0201 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0201 39.6C75.5167 39.6 64.5701 50.5466 64.5701 64.05C64.5701 77.5534 75.5167 88.5 89.0201 88.5Z"
                                                fill="#0A863C" />
                                          <path d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                                                fill="white" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M96.4501 71.08C96.4501 69.23 95.5501 67.5 94.0301 66.45C92.1101 65.11 89.5301 63.31 89.5301 63.31C89.2201 63.1 88.8201 63.1 88.5101 63.31C88.5101 63.31 85.9301 65.11 84.0101 66.45C82.5001 67.51 81.5901 69.24 81.5901 71.08V75.93C81.5901 76.42 81.9901 76.82 82.4801 76.82H95.5501C96.0401 76.82 96.4401 76.42 96.4401 75.93V71.08H96.4501Z"
                                                fill="white" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M96.4501 57.01V52.16C96.4501 51.67 96.0501 51.27 95.5601 51.27H82.4901C82.0001 51.27 81.6001 51.67 81.6001 52.16V57.01C81.6001 58.86 82.5001 60.59 84.0201 61.64C85.9401 62.98 88.5201 64.78 88.5201 64.78C88.8301 64.99 89.2301 64.99 89.5401 64.78C89.5401 64.78 92.1201 62.98 94.0401 61.64C95.5501 60.58 96.4601 58.85 96.4601 57.01H96.4501Z"
                                                fill="white" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M80.7001 53.06H97.3401C97.8301 53.06 98.2301 52.66 98.2301 52.17C98.2301 51.68 97.8301 51.28 97.3401 51.28H80.7001C80.2101 51.28 79.8101 51.68 79.8101 52.17C79.8101 52.66 80.2101 53.06 80.7001 53.06Z"
                                                fill="white" />
                                          <path fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M80.7001 76.82H97.3401C97.8301 76.82 98.2301 76.42 98.2301 75.93C98.2301 75.44 97.8301 75.04 97.3401 75.04H80.7001C80.2101 75.04 79.8101 75.44 79.8101 75.93C79.8101 76.42 80.2101 76.82 80.7001 76.82Z"
                                                fill="white" />
                                    </g>
                              </g>
                              <defs>
                                    <clipPath id="clip0_2_709">
                                          <rect width="128" height="128"
                                                fill="white" />
                                    </clipPath>
                              </defs>
                        </svg>


                  </v-col>
            </v-row>

            <v-row>
                  <v-col class="pt-5">
                        <span class="font-weight-medium"
                              :style="[cp_font_size]">{{ $t('buttons.phone_fault_freezes') }}</span>
                  </v-col>
            </v-row>

      </div>
</template>

<script>

export default {
      name: "PhoneFaultFreezes",
      props: {
            selected: { type: Boolean, required: false },
            height: { type: Number, required: 200 },
            width: { type: Number, required: 180 },
            font_size: { type: String, required: '15px' },

            base_color: { type: String, required: true },
            base_hovered_color: { type: String, required: true },
            base_selected_color: { type: String, required: true },

            base_border_color: { type: String, required: true },
            base_border_hovered_color: { type: String, required: true },
            base_border_selected_color: { type: String, required: true },

      },
      data() {
            return {
                  hovered: false
            }
      },
      computed: {
            cp_font_size() {
                  return `font-size: ${this.font_size}`
            },
            c_base_color() {
                  let color = this.base_color
                  if (this.selected) {
                        color = this.base_selected_color
                  } else {
                        if (this.hovered) color = this.base_hovered_color
                  }
                  return color
            },
            c_base_border_color() {
                  let color = this.base_border_color
                  if (this.selected) {
                        color = this.base_border_selected_color
                  } else {
                        if (this.hovered) color = this.base_border_hovered_color
                  }
                  return color
            }
      }
}
</script>

<style scoped></style>