export const namespaced = true;

const getDefaultState = () => {
    return {
        show: false,
        message: null,
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_SHOW(state, status) {
        state.show = status;
    },
    SET_MESSAGE(state, message) {
        state.message = message;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export const actions = {
    showInfoDialog({commit}, message) {
        commit("SET_MESSAGE", message);
        commit("SET_SHOW", true);
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
}

export const getters = {
    show: (state) => {
        return state.show;
    },
    message: (state) => {
        return state.message;
    },
};
