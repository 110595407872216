import * as AppAlert from './AppAlert';
import * as AppConfirm from './AppConfirm';
import * as AppInfoDialog from './AppInfoDialog';

import * as Maker from './Maker';
import * as DeviceModel from './DeviceModel';
import * as DeviceNumber from './DeviceNumber';

import * as ClaimAttachment from './ClaimAttachment';

import * as ClaimForm from './ClaimForm';

import * as Courier from './CourierAvailability';

export default {
    AppAlert, AppConfirm, AppInfoDialog,

    Maker, DeviceModel, DeviceNumber,

    ClaimAttachment,

    ClaimForm,

    Courier
}
