<template>

  <div id="standard-transport" style="min-height: 350px;">
    <v-container fluid :class="classes.buttons_container">
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button variant="transport_courier"
                               :selected="claim_form.transport_out === 'transport_courier'"
                               @click="updateClaimForm({ key: 'transport_out', value: 'transport_courier' })"/>
        </v-col>
        <v-col>
          <app-animated-button variant="transport_dhl_parcel_shop"
                               :selected="claim_form.transport_out === 'transport_dhl_parcel_shop'"
                               @click="updateClaimForm({ key: 'transport_out', value: 'transport_dhl_parcel_shop' });"/>
        </v-col>
        <v-col>
          <app-animated-button variant="transport_inpost"
                               :selected="claim_form.transport_out === 'transport_inpost'"
                               @click="updateClaimForm({ key: 'transport_out', value: 'transport_inpost' })"/>
        </v-col>
        <v-col>
          <app-animated-button variant="transport_self_deliver"
                               :selected="claim_form.transport_out === 'transport_self_deliver'"
                               @click="updateClaimForm({ key: 'transport_out', value: 'transport_self_deliver' })"/>
        </v-col>
      </v-row>
    </v-container>
    <!-- GEO WIDGETS -->
    <v-container fluid class="pa-5"
                 :class="['mt-0', claim_form.transport_out === 'transport_inpost' ? 'display-geo' : 'hide-geo']">


      <v-row dense no-gutters>
        <v-col cols="12">
          <v-card elevation="1" color="options_menu" class="pa-2 mt-3" >
            <v-card-text>
              <v-row dense no-gutters>
               <v-col cols="10">
              <label
                  class="sender-form-label">{{ $t('form.steps.transport.transport_paczkomat') }}:</label>
              <v-text-field v-model="selected_paczkomat"
                            variant="outlined" color="primary" :density="classes.density" readonly hide-details
              ></v-text-field>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center">
                  <v-dialog
                      v-model="dialog_inpost"
                      transition="dialog-top-transition"
                      width="100vw"
                      eager
                  >
                    <template v-slot:activator="{ attrs }">

                      <v-btn  color="primary"
                              class="menu-btn text-capitalize mt-5"
                              variant="outlined"
                              v-bind="attrs"
                              @click="dialog_inpost = !dialog_inpost"
                      >
                        {{ $t("select_btn") }}
                      </v-btn>

                    </template>
                    <v-card>
                      <div style="width: 90vw;">
                        <app-form-transport-inpost-geo-widget v-on:closeInpostDialog="closeInpostDialog()"/>
                      </div>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>

      </v-row>


    </v-container>
    <v-container fluid class="pa-5"
                 :class="['mt-0', claim_form.transport_out === 'transport_dhl_parcel_shop' ? 'display-geo' : 'hide-geo']">

      <v-row dense no-gutters>
        <v-col>
          <v-card elevation="1" color="options_menu" class="pa-2 mt-3">
            <v-card-text>
              <v-row>
                <v-col cols="10">
                  <label
                      class="sender-form-label">{{ $t('form.steps.transport.transport_dhl_parcel_shop') }}:</label>
                  <v-text-field v-model="selected_dhl_parcel_shop"
                                variant="outlined" color="primary" :density="classes.density" readonly hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center">
                  <v-dialog
                      v-model="dialog_dhl"
                      transition="dialog-top-transition"
                      width="100vw"
                      eager
                  >
                    <template v-slot:activator="{ attrs }">
                      <v-btn  color="primary"
                              class="menu-btn text-capitalize mt-5"
                              variant="outlined"
                              v-bind="attrs"
                              @click="dialog_dhl = !dialog_dhl"
                      >
                        {{ $t("select_btn") }}
                      </v-btn>

                    </template>
                    <v-card>
                      <div style="width: 90vw;">
                        <app-form-transport-dhl-geo-widget v-on:closeDHLDialog="closeDHLDialog()"/>
                      </div>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


    </v-container>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {useDisplay} from "vuetify";
import {computed} from "vue";

export default {
  name: "StandardTransportOut",
  setup() {
    const {name} = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value

      const isModal = window.self !== window.top;
      if(isModal) {
        return {
          density: 'compact',
          buttons_container: ['px-15', 'py-0']
        }
      } else {
        switch (name.value) {
          case 'xs':
            return {
              density: 'compact',
              buttons_container: ['pa-15']
            }
          case 'sm':
            return {
              density: 'comfortable',
              buttons_container: ['pa-15']
            }

          default:
            return {
              density: 'default',
              buttons_container: ['pa-15']
            }
        }
      }
    })
    return {classes}
  },
  data() {
    return {
      dialog_inpost: false,
      dialog_dhl: false
    }
  },
  computed: {
    ...mapGetters('ClaimForm', {claim_form: 'claim_form'}),
    selected_paczkomat() {
      if (this.claim_form.transport_inpost_paczkomat.name && this.claim_form.transport_inpost_paczkomat.location_description) {
        return `${this.claim_form.transport_inpost_paczkomat.name} - ${this.claim_form.transport_inpost_paczkomat.location_description}`;
      } else if (this.claim_form.transport_inpost_paczkomat.name) {
        return `${this.claim_form.transport_inpost_paczkomat.name}`;
      } else {
        return '';
      }
    },
    selected_dhl_parcel_shop() {
      if(this.claim_form.transport_dhl_parcel_shop.name) {
         return `${this.claim_form.transport_dhl_parcel_shop.name} - ${this.claim_form.transport_dhl_parcel_shop.zip} ${this.claim_form.transport_dhl_parcel_shop.city}`;
      } else {
        return '';
      }
    }
  },
  methods: {
    ...mapActions('ClaimForm', {updateClaimForm: 'updateClaimForm', syncValue: 'syncValue'}),
    closeInpostDialog() {
      this.dialog_inpost = false;
    },
    closeDHLDialog() {
      this.dialog_dhl = false;
    },
  },
}
</script>

<style scoped>

.display-geo {
  display: block;
}

.hide-geo {
  display: none;
}

</style>