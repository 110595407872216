<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_101)">
            <path
              d="M50.32 102.11C71.6437 102.11 88.93 84.8237 88.93 63.5C88.93 42.1763 71.6437 24.89 50.32 24.89C28.9963 24.89 11.71 42.1763 11.71 63.5C11.71 84.8237 28.9963 102.11 50.32 102.11Z"
              :fill="c_base_color" />
              <g :class="{ hovered: hovered }">
            <path
              d="M64.53 76.04C63.62 76.04 62.88 76.81 62.88 77.75V110.3C62.88 111.24 63.62 112.01 64.53 112.01C65.44 112.01 66.18 111.24 66.18 110.3V77.75C66.18 76.81 65.44 76.04 64.53 76.04Z"
              fill="#0A863C" />
            <path
              d="M102.25 97.51V67.6C102.25 66.64 101.58 65.86 100.75 65.86C99.92 65.86 99.25 66.64 99.25 67.6V96.27L90.59 99.75L64.53 110.21L64.4 110.16L29.82 96.28V67.61C29.82 66.65 29.15 65.87 28.32 65.87C27.49 65.87 26.82 66.65 26.82 67.61V97.52C26.82 98.26 27.23 98.92 27.83 99.16L64.05 113.7C64.36 113.83 64.71 113.83 65.03 113.7L101.25 99.16C101.86 98.92 102.26 98.26 102.26 97.52L102.25 97.51Z"
              fill="#0A863C" />
            <path d="M62.8 63.38L29.8 65.96V96.29L62.8 109.46V63.38Z"
              fill="#D0F4DB" />
            <path
              d="M60.8 65.54V106.51L31.8 94.94V67.81L60.8 65.54ZM62.8 63.38L29.8 65.96V96.29L62.8 109.46V63.38Z"
              fill="white" />
            <path d="M66.25 63.35L99.25 65.93V96.26L66.25 109.43V63.35Z"
              fill="#D0F4DB" />
            <path
              d="M68.25 65.51L97.25 67.78V94.91L68.25 106.48V65.51ZM66.25 63.35V109.43L99.25 96.26V65.93L66.25 63.35Z"
              fill="white" />
            <path
              d="M76.04 75.53C76.44 75.94 77.07 76.1 77.63 75.9L113.85 62.98C114.33 62.81 114.69 62.41 114.81 61.91C114.93 61.41 114.78 60.89 114.43 60.52L101.83 47.46C101.66 47.29 101.47 47.17 101.25 47.09L90.37 43.21C89.58 42.93 88.73 43.34 88.45 44.12C88.17 44.9 88.58 45.76 89.36 46.04L96.28 48.51L64.53 59.83L64.41 59.79L32.78 48.51L39.87 45.98C40.65 45.7 41.06 44.84 40.78 44.06C40.5 43.28 39.65 42.87 38.86 43.15L27.8 47.09C27.58 47.17 27.39 47.29 27.23 47.46L27.17 47.51L14.63 60.52C14.27 60.89 14.13 61.41 14.25 61.91C14.37 62.41 14.72 62.81 15.21 62.98L37.38 70.89C38.15 71.17 39.03 70.74 39.3 69.98C39.43 69.6 39.41 69.19 39.24 68.83C39.07 68.47 38.77 68.19 38.39 68.06L18.41 60.93L28.72 50.25L28.93 50.33L61.83 62.06L51.52 72.74L45.02 70.42C44.64 70.28 44.23 70.3 43.87 70.48C43.51 70.65 43.23 70.95 43.1 71.33C42.96 71.71 42.98 72.12 43.16 72.48C43.33 72.84 43.63 73.12 44.01 73.25L51.42 75.89C51.98 76.09 52.6 75.94 53.01 75.52L64.52 63.58L76.03 75.52L76.04 75.53ZM100.33 50.25L110.64 60.93L77.53 72.74L67.22 62.06L100.33 50.25Z"
              fill="#0A863C" />
            <path
              d="M51.52 72.75L61.83 62.06L28.72 50.25L18.41 60.94L51.52 72.75Z"
              fill="#D0F4DB" />
            <path
              d="M29.27 52.63L58.15 62.93L50.96 70.38L22.08 60.08L29.27 52.63ZM28.71 50.26L18.4 60.94L51.51 72.75L61.82 62.07L28.71 50.26Z"
              fill="white" />
            <path
              d="M77.53 72.75L67.22 62.06L100.34 50.25L110.64 60.94L77.53 72.75Z"
              fill="#D0F4DB" />
            <path
              d="M99.78 52.63L106.97 60.08L78.09 70.38L70.9 62.93L99.78 52.63ZM100.34 50.26L67.23 62.07L77.54 72.75L110.65 60.94L100.34 50.26Z"
              fill="white" />
            <mask id="mask0_2_101" style="mask-type:luminance"
              maskUnits="userSpaceOnUse" x="23" y="0" width="86" height="56">
              <path
                d="M24.89 42.54L64.05 55.71L108.39 41.04V0.709991H23.55L24.89 42.54Z"
                fill="white" />
            </mask>
            <g mask="url(#mask0_2_101)">
              <path
                d="M47.03 24.72H48.31C48.81 24.72 49.22 25.12 49.22 25.63V30.74C49.22 31.24 48.82 31.65 48.31 31.65H47.03C46.53 31.65 46.12 31.25 46.12 30.74V25.63C46.12 25.13 46.52 24.72 47.03 24.72Z"
                fill="#0A863C" />
              <path
                d="M47.03 33.66H48.31C48.81 33.66 49.22 34.06 49.22 34.57V37.13C49.22 37.63 48.82 38.04 48.31 38.04H47.03C46.53 38.04 46.12 37.64 46.12 37.13V34.57C46.12 34.07 46.52 33.66 47.03 33.66Z"
                fill="#0A863C" />
              <path
                d="M78.02 72.51H52.07C49.57 72.51 47.53 70.48 47.53 67.97V19.03C47.53 16.53 49.56 14.49 52.07 14.49H78.02C80.52 14.49 82.56 16.52 82.56 19.03V67.97C82.56 70.47 80.53 72.51 78.02 72.51ZM52.07 16.31C51.35 16.31 50.66 16.6 50.15 17.11C49.64 17.62 49.35 18.31 49.35 19.03V67.97C49.35 68.69 49.64 69.39 50.15 69.89C50.66 70.4 51.35 70.68 52.07 70.69H78.02C79.52 70.69 80.74 69.47 80.74 67.97V19.03C80.74 17.53 79.52 16.31 78.02 16.31H52.07Z"
                fill="#0A863C" />
              <path
                d="M77.81 17.18H52.25C51.1233 17.18 50.21 18.0933 50.21 19.22V67.78C50.21 68.9067 51.1233 69.82 52.25 69.82H77.81C78.9367 69.82 79.85 68.9067 79.85 67.78V19.22C79.85 18.0933 78.9367 17.18 77.81 17.18Z"
                fill="#D0F4DB" />
            </g>
            <path
              d="M89.02 88.49C102.523 88.49 113.47 77.5434 113.47 64.04C113.47 50.5366 102.523 39.59 89.02 39.59C75.5166 39.59 64.57 50.5366 64.57 64.04C64.57 77.5434 75.5166 88.49 89.02 88.49Z"
              fill="#0A863C" />
            <path
              d="M89.02 39.59C102.52 39.59 113.47 50.54 113.47 64.04C113.47 77.54 102.52 88.49 89.02 88.49C75.52 88.49 64.57 77.54 64.57 64.04C64.57 50.54 75.52 39.59 89.02 39.59ZM89.02 36.77C73.98 36.77 61.75 49 61.75 64.04C61.75 79.08 73.98 91.31 89.02 91.31C104.06 91.31 116.29 79.08 116.29 64.04C116.29 49 104.06 36.77 89.02 36.77Z"
              fill="white" />
            <path
              d="M89.02 77.57C81.67 77.57 75.66 71.56 75.66 64.21C75.66 56.86 81.67 50.85 89.02 50.85C96.37 50.85 102.38 56.86 102.38 64.21C102.38 71.56 96.37 77.57 89.02 77.57ZM89.02 53.51C83.14 53.51 78.33 58.32 78.33 64.2C78.33 70.08 83.14 74.89 89.02 74.89C94.9 74.89 99.71 70.08 99.71 64.2C99.71 58.32 94.9 53.51 89.02 53.51Z"
              fill="white" />
            <path
              d="M93.03 70.89C92.63 70.89 92.23 70.76 91.96 70.36L88.35 65.55H86.35V69.56C86.35 70.36 85.82 70.9 85.01 70.9C84.2 70.9 83.67 70.37 83.67 69.56V58.87C83.67 58.07 84.2 57.53 85.01 57.53H90.36C91.43 57.53 92.5 57.93 93.17 58.73C93.97 59.4 94.37 60.47 94.37 61.54C94.37 62.61 93.97 63.68 93.17 64.35C92.77 64.75 92.1 65.15 91.57 65.29L94.11 68.63C94.51 69.16 94.38 70.1 93.84 70.5C93.57 70.77 93.31 70.9 93.04 70.9L93.03 70.89ZM89.02 62.87H90.36C90.76 62.87 91.03 62.74 91.3 62.47C91.57 62.2 91.7 61.94 91.7 61.53C91.7 61.12 91.57 60.86 91.3 60.59C91.03 60.32 90.77 60.19 90.36 60.19H86.35V62.86H89.02V62.87Z"
              fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_101">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">{{
          $t('buttons.included_item_original_packaging')
        }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "IncludedItemOriginalPackaging",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>