<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_65)">
            <path
              d="M50.32 102.11C71.6437 102.11 88.93 84.8237 88.93 63.5C88.93 42.1763 71.6437 24.89 50.32 24.89C28.9963 24.89 11.71 42.1763 11.71 63.5C11.71 84.8237 28.9963 102.11 50.32 102.11Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M82.23 87.82V96.04C82.23 104.29 75.54 110.98 67.29 110.98C59.04 110.98 52.35 104.29 52.35 96.04V75.65H49.35V96.04C49.35 105.93 57.4 113.98 67.29 113.98C77.18 113.98 85.23 105.93 85.23 96.04V87.82H82.23Z"
                fill="#0A863C" />
              <path
                d="M56.51 17.08C57.57 17.08 58.43 17.94 58.43 19V24.75C60.54 24.75 62.26 26.47 62.26 28.58V36.25H39.26V28.58C39.26 26.47 40.98 24.75 43.09 24.75V19C43.09 17.94 43.95 17.08 45.01 17.08C46.07 17.08 46.93 17.94 46.93 19V24.75H54.6V19C54.6 17.94 55.46 17.08 56.52 17.08M56.52 14.08C53.81 14.08 51.6 16.29 51.6 19V21.75H49.93V19C49.93 16.29 47.72 14.08 45.01 14.08C42.3 14.08 40.09 16.29 40.09 19V22.45C37.83 23.57 36.26 25.89 36.26 28.57V39.25H65.26V28.58C65.26 25.89 63.69 23.56 61.43 22.45V19C61.43 16.29 59.22 14.08 56.51 14.08H56.52Z"
                fill="#0A863C" />
              <path
                d="M62.34 39.29C64.46 39.29 66.17 41.01 66.17 43.12V60.37C66.17 65.66 61.88 69.95 56.59 69.95H45.09C39.8 69.95 35.51 65.66 35.51 60.37V43.12C35.51 41 37.23 39.29 39.34 39.29H62.34ZM62.34 36.29H39.34C35.57 36.29 32.51 39.36 32.51 43.12V60.37C32.51 67.3 38.16 72.95 45.09 72.95H56.59C63.52 72.95 69.17 67.3 69.17 60.37V43.12C69.17 39.35 66.1 36.29 62.34 36.29Z"
                fill="#0A863C" />
              <path
                d="M62.34 39.29H39.34C37.22 39.29 35.51 41.01 35.51 43.12V60.37C35.51 65.66 39.8 69.95 45.09 69.95H56.59C61.88 69.95 66.17 65.66 66.17 60.37V43.12C66.17 41 64.45 39.29 62.34 39.29Z"
                fill="#D0F4DB" />
              <path
                d="M62.34 41.29C63.35 41.29 64.17 42.11 64.17 43.12V60.37C64.17 64.55 60.76 67.95 56.59 67.96H45.09C40.91 67.96 37.51 64.55 37.5 60.38V43.13C37.5 42.12 38.32 41.3 39.33 41.3H62.33M62.33 39.3H39.33C37.21 39.3 35.5 41.02 35.5 43.13V60.38C35.5 65.67 39.79 69.96 45.08 69.96H56.58C61.87 69.96 66.16 65.67 66.16 60.38V43.13C66.16 41.01 64.44 39.3 62.33 39.3Z"
                fill="white" />
              <path
                d="M57.45 52.67C57.35 52.46 57.15 52.33 56.92 52.33L51.84 52.36L51.81 52.33L52.51 47.48C52.55 47.22 52.41 46.96 52.16 46.86C52.09 46.83 52 46.81 51.92 46.81C51.74 46.81 51.57 46.89 51.46 47.03L44.97 55.13C44.83 55.31 44.8 55.55 44.9 55.75C45 55.95 45.2 56.08 45.43 56.08H50.01L50.04 56.12L48.87 61.74C48.81 62 48.95 62.27 49.19 62.39C49.43 62.51 49.73 62.44 49.9 62.24L57.38 53.3C57.53 53.12 57.56 52.88 57.46 52.67H57.45Z"
                fill="#0A863C" />
              <path
                d="M89.02 88.49C102.523 88.49 113.47 77.5434 113.47 64.04C113.47 50.5366 102.523 39.59 89.02 39.59C75.5166 39.59 64.57 50.5366 64.57 64.04C64.57 77.5434 75.5166 88.49 89.02 88.49Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.59C102.52 39.59 113.47 50.54 113.47 64.04C113.47 77.54 102.52 88.49 89.02 88.49C75.52 88.49 64.57 77.54 64.57 64.04C64.57 50.54 75.52 39.59 89.02 39.59ZM89.02 36.77C73.98 36.77 61.75 49 61.75 64.04C61.75 79.08 73.98 91.31 89.02 91.31C104.06 91.31 116.29 79.08 116.29 64.04C116.29 49 104.06 36.77 89.02 36.77Z"
                fill="white" />
              <path
                d="M80.74 68.47C83.1866 68.47 85.17 66.4866 85.17 64.04C85.17 61.5934 83.1866 59.61 80.74 59.61C78.2934 59.61 76.31 61.5934 76.31 64.04C76.31 66.4866 78.2934 68.47 80.74 68.47Z"
                fill="white" />
              <path
                d="M97.3 68.47C99.7466 68.47 101.73 66.4866 101.73 64.04C101.73 61.5934 99.7466 59.61 97.3 59.61C94.8534 59.61 92.87 61.5934 92.87 64.04C92.87 66.4866 94.8534 68.47 97.3 68.47Z"
                fill="white" />
              <path
                d="M45.73 74.97L46.81 82.25C46.98 83 47.67 83.52 48.43 83.5H53.24C54.01 83.52 54.69 83 54.87 82.25L55.94 74.97H45.72H45.73Z"
                fill="#0A863C" />
              <path
                d="M62.26 28.58C62.26 26.47 60.54 24.75 58.43 24.75V19C58.43 17.94 57.57 17.08 56.51 17.08C55.45 17.08 54.59 17.94 54.59 19V24.75H46.92V19C46.92 17.94 46.06 17.08 45 17.08C43.94 17.08 43.08 17.94 43.08 19V24.75C40.97 24.75 39.25 26.47 39.25 28.58V36.25H62.25V28.58H62.26Z"
                fill="#D0F4DB" />
              <path
                d="M56.59 26.75H58.42C59.43 26.75 60.26 27.58 60.26 28.59V34.26H41.26V28.6C41.26 27.59 42.09 26.76 43.1 26.76H44.93H56.6M56.52 17.09C55.46 17.09 54.6 17.95 54.6 19.01V24.76H46.93V19.01C46.93 17.95 46.07 17.09 45.01 17.09C43.95 17.09 43.09 17.95 43.09 19.01V24.76C40.98 24.76 39.26 26.48 39.26 28.59V36.26H62.26V28.59C62.26 26.48 60.54 24.76 58.43 24.76V19.01C58.43 17.95 57.57 17.09 56.51 17.09H56.52Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_65">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>


      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">{{
          $t('buttons.included_item_charger')
        }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "IncludedItemCharger",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>