<template>

  <div id="claim-attachments-step">
    <v-row>
      <v-col :class="[`mt-${classes.top_padding}`]">
        <div :class="classes.title" v-text="$t('form.steps.claim_attachments.title')"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :class="[`mt-${classes.top_padding}`]">
        <div v-for="n in 3" v-bind:key="n">
          <div>
            <v-file-input
                v-model="claim_form.attachments[n]"
                accept=".pdf,.jpg,.png"
                show-size
                label="Dodaj plik"
                class="test-class"
                prepend-icon=""
                @change="selectFile(n)"
                :loading="loading && fileLoading === n"
                :disabled="loading"
                :rules="attachmentRules"
                :key="n"
                @click:clear="removeFile(n)"
            >
              <template v-slot:append>
                <div class="text-primary" v-if="filesLoaded[n]">
                  {{ $t('btn_add_file_lbl') }}
                </div>
                <div class="text-error" v-if="filesToLarge[n]">
                  {{ $t('max_file_size_reached_msg') }}
                </div>
              </template>
            </v-file-input>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {useDisplay} from "vuetify";
import {computed} from "vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ClaimAttachmentsStep",
  setup() {
    const {name} = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return {
            top_padding: 2,
            title: ["title-xs", "pa-2"],
            info: ["info-xs", "pa-5", "text-justify"],
            graphic_size: 100
          };
        case "sm":
          return {
            top_padding: 3,
            title: ["title-sm", "pa-4"],
            info: ["info-sm", "pa-5", "text-justify"],
            graphic_size: 150
          };
        default:
          return {
            top_padding: 4,
            title: ["ccs-title", "pa-5"],
            info: ["info-default", "pa-5", "text-justify"],
            graphic_size: 200
          };
      }
    });
    return {
      classes
    };
  },
  data() {
    return {
      filesLoaded: [],
      filesToLarge: [],
      fileLoading: '',
      attachmentRules: [
        value => {
          return !value || !value.length || value[0].size < 5000000 || ''
        },
      ],
    }
  },
  computed: {
    ...mapGetters("ClaimForm", {claim_form: "claim_form"}),
    ...mapGetters('ClaimAttachment', {loading: 'loading'}),

  },
  methods: {
    ...mapActions('ClaimAttachment', {upload: 'upload'}),
    ...mapActions('AppAlert', {showAlert: 'showAlert'}),
    selectFile(n) {
      this.filesLoaded[n] = false;
      this.filesToLarge[n] = false;
      this.fileLoading = n;
      let currentFile = this.claim_form.attachments[n][0];
      if(currentFile.size < 5000000){
        this.addAttachments(this.claim_form.attachments[n][0], n);
      } else {
        this.filesToLarge[n] = true;
        this.claim_form.attachment_ids = false;
      }

    },
    removeFile(n) {
      this.filesLoaded[n] = false;
      this.filesToLarge[n] = false;
      this.setAttachmentsIds();
    },
    setAttachmentsIds() {
      let noID = false;
      this.claim_form.attachment_ids = [];
      this.claim_form.attachments.forEach(attachment => {
        if(attachment.length) {
          if(attachment[0].id === undefined) {
            noID = true;
          } else {
            this.claim_form.attachment_ids.push(attachment[0].id);
          }
        }
      });
      if (noID) {
        this.claim_form.attachment_ids = false;
      }
    },
    addAttachments(file, n) {
      if (file !== null) {
        let form = new FormData();
        form.append('file_name', file.name);
        form.append('file_type', file.type);
        form.append('fileData', file, file.name);
        form.append('file', file, file.name);
        let uploadFiles = this.upload(form);
        const checkStatus = async () => {
          const uploadResponse = await uploadFiles;
          if (uploadResponse.status) {
            file.id = uploadResponse.data.id;
            this.filesLoaded[n] = true;
            this.setAttachmentsIds();
          } else {
            this.claim_form.attachments[n] = [];
            this.showAlert({
              type: 'danger',
              message: this.$t('technical_error')
            })
          }
        };
        checkStatus();
      }
    }
  }
}
</script>

<style scoped>

</style>
