import SerialCheckService from "@/services/SerialCheckService";

export const namespaced = true;

const getDefaultState = () => {
    return {
        device_sn: [],
        loading: false,
        sn_loading: false,
        sn_overlay_loading: false,
        error: {},
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_SN_LOADING(state, sn_loading) {
        state.sn_loading = sn_loading;
    },
    SET_SN_INVALID(state, sn_invalid) {
        state.sn_invalid = sn_invalid;
    },
    SET_SN_OVERLAY_LOADING(state, sn_overlay_loading) {
        state.sn_overlay_loading = sn_overlay_loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

export const actions = {
    checkDeviceSN({commit}, caller) {
        commit("SET_SN_LOADING", true);
        commit("SET_ERROR", {});
        if(caller.claim_form.device_number_check_status == 'waiting') {
            commit("SET_SN_OVERLAY_LOADING", true);
        }

        SerialCheckService.index(caller.claim_form.device_imei_sn, caller.claim_form.device_model.model_serwis)
            .then((response) => {
                caller.claim_form.device_number_check_status = response.data.status;
                if (response.data.status == "valid"){
                    commit('RESET_STATE');
                    commit("SET_SN_LOADING", false);
                    commit("SET_SN_OVERLAY_LOADING", false);
                } else if (response.data.status == "invalid"){
                    commit("SET_SN_LOADING", false);
                    commit("SET_SN_OVERLAY_LOADING", false);
                }
            }).catch(() => {
            commit("SET_SN_OVERLAY_LOADING", false);
            commit("SET_SN_LOADING", false);
            commit("SET_ERROR", true);
            return false;
        });

    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
};

export const getters = {
    sn_loading: (state) => {
        return state.sn_loading;
    },
    sn_invalid: (state) => {
        return state.sn_invalid;
    },
    sn_overlay_loading: (state) => {
        return state.sn_overlay_loading;
    },
    error: (state) => {
        return state.error;
    },
};
