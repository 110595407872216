<template>

  <v-row dense no-gutters>
    <v-col>
      <v-card elevation="1" color="options_menu" class="pa-2 pt-8" min-height="500">
        <v-card-text>
          <div id="geo-widget" class="overlay"></div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {useDisplay} from "vuetify";
import {computed} from "vue";

export default {
  name: "InpostGeoWidget",
  emits: ['closeInpostDialog'],
  setup() {
    const {name} = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs':
          return {
            density: 'compact',
          }
        case 'sm':
          return {
            density: 'comfortable',
          }

        default:
          return {
            density: 'default',
          }
      }
    })

    let script = document.createElement('script')
    script.src = process.env.VUE_APP_INPOST_GEO_WIDGET_JS
    script.defer = true

    let link = document.createElement('link')
    link.rel = "stylesheet"
    link.href = process.env.VUE_APP_INPOST_GEO_WIDGET_CSS

    document.head.appendChild(script)
    document.head.appendChild(link)

    return {classes}
  },
  data() {
    return {
      geo_token: process.env.VUE_APP_INPOST_GEO_WIDGET_TOKEN,
      geo_id: Math.random(),
      is_created: false
    }
  },
  computed: {
    ...mapGetters('ClaimForm', {claim_form: 'claim_form'}),
  },
  methods: {
    ...mapActions('ClaimForm', {updateClaimForm: 'updateClaimForm', syncValue: 'syncValue'}),
    afterPointSelected(point) {
      this.updateClaimForm({
        key: 'transport_inpost_paczkomat', value: {
          name: point.detail.name ?? null,
          address_details: point.detail.address_details ?? null,
          location_description: point.detail.location_description ?? null,
          opening_hours: point.detail.opening_hours ?? null,
          payment_available: point.detail.payment_available ?? null,
        }
      })
      this.$emit('closeInpostDialog');
    }
  },
  created() {
    document.addEventListener('onpointselect', (event) => this.afterPointSelected(event));
  },
  mounted() {
      const geo_div = document.querySelector('#geo-widget');
      const geo_widget = document.createElement('inpost-geowidget')
      geo_widget.id = this.geo_id
      geo_widget.setAttribute("onpoint","onpointselect")
      geo_widget.setAttribute("token",this.geo_token)
      geo_widget.setAttribute("language",this.$i18n.locale)
      geo_widget.setAttribute("config","parcelcollect")
      geo_div.appendChild(geo_widget)
  }
}

</script>

<style scoped>

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

</style>