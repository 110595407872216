<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_130)">
            <path
              d="M50.32 102.11C71.6437 102.11 88.93 84.8237 88.93 63.5C88.93 42.1763 71.6437 24.89 50.32 24.89C28.9963 24.89 11.71 42.1763 11.71 63.5C11.71 84.8237 28.9963 102.11 50.32 102.11Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M60.82 19.79C64.16 19.79 67.47 20.18 70.67 21.18C80.74 24.33 88.31 33.84 90.06 44.33C94.64 65.89 77.81 77.78 76.62 80.09C75.36 82.67 73.86 88.27 74.87 108.07H43.4C43.81 104.16 43.53 100.18 42.55 96.4C42.14 94.51 41.36 92.47 39.76 91.35C38.68 90.57 37.45 90.32 36.16 90.32C34.43 90.32 32.57 90.77 30.81 91.01C30.42 91.06 30.01 91.09 29.59 91.09C26.77 91.09 23.72 89.84 23.6 87.16C23.47 84.73 26.05 82.06 24.45 80.24C23.89 79.61 22.97 79.34 22.56 78.57C21.71 76.95 24.04 75.42 24.38 73.6C25.15 69.53 16.83 68.97 16.9 64.92C16.9 61.53 26.27 60.78 26.27 47.71C26.27 43.01 26.47 38.13 28.36 33.78C31.93 25.6 41.73 22.25 50.54 20.77C53.94 20.2 57.4 19.79 60.83 19.79M60.83 16.79C57.63 16.79 54.21 17.11 50.05 17.81C37.17 19.97 28.95 24.94 25.62 32.58C23.46 37.53 23.28 42.99 23.28 47.71C23.28 54.91 20.09 57.43 17.53 59.46C15.84 60.8 13.93 62.31 13.91 64.89C13.86 68.61 17.1 70.5 19.25 71.76C19.92 72.15 20.99 72.77 21.38 73.18C21.26 73.4 21.02 73.76 20.85 74.02C20.05 75.21 18.58 77.42 19.9 79.97C20.49 81.07 21.33 81.66 21.9 82.05C21.8 82.37 21.64 82.8 21.52 83.12C21.08 84.28 20.53 85.72 20.61 87.34C20.8 91.33 24.49 94.12 29.6 94.12C30.15 94.12 30.69 94.08 31.22 94.01C31.81 93.93 32.41 93.83 33.01 93.73C34.16 93.54 35.24 93.35 36.17 93.35C37.24 93.35 37.72 93.6 38.02 93.81C38.53 94.16 39.18 94.99 39.64 97.06V97.11L39.67 97.16C40.55 100.57 40.81 104.24 40.44 107.77L40.09 111.09H78.05L77.89 107.94C77.17 93.78 77.62 85.16 79.27 81.58C79.49 81.31 80.08 80.71 80.56 80.22C85.09 75.58 97.13 63.25 93.03 43.8C91.03 31.96 82.42 21.74 71.59 18.35C68.31 17.32 64.79 16.82 60.84 16.82L60.83 16.79Z"
                fill="#0A863C" />
              <path
                d="M90.06 44.33C88.31 33.84 80.75 24.33 70.67 21.18C64.17 19.14 57.23 19.65 50.53 20.77C41.72 22.25 31.92 25.6 28.35 33.78C26.46 38.12 26.26 43 26.26 47.71C26.26 60.77 16.89 61.53 16.89 64.92C16.82 68.97 25.14 69.53 24.37 73.6C24.03 75.42 21.7 76.95 22.55 78.57C22.96 79.34 23.88 79.61 24.44 80.24C26.04 82.06 23.47 84.73 23.59 87.16C23.73 90.24 27.72 91.43 30.8 91.01C33.88 90.6 37.23 89.53 39.75 91.35C41.35 92.47 42.13 94.5 42.54 96.4C43.51 100.18 43.8 104.16 43.39 108.07H74.86C73.85 88.27 75.35 82.67 76.61 80.09C77.8 77.78 94.63 65.89 90.05 44.33H90.06Z"
                fill="#D0F4DB" />
              <path
                d="M60.82 21.79C64.26 21.79 67.28 22.21 70.08 23.09C79.18 25.94 86.41 34.61 88.09 44.66V44.7L88.11 44.74C91.69 61.61 81.37 72.18 76.96 76.69C75.82 77.86 75.19 78.51 74.83 79.2C73.44 82.04 71.97 87.68 72.78 106.06H45.56C45.71 102.64 45.35 99.24 44.51 95.92C43.86 92.96 42.68 90.93 40.94 89.72C39.63 88.77 38.07 88.31 36.17 88.31C34.81 88.31 33.47 88.54 32.17 88.76C31.63 88.85 31.09 88.94 30.55 89.02C30.24 89.06 29.93 89.08 29.6 89.08C27.71 89.08 25.67 88.37 25.61 87.04C25.58 86.48 25.89 85.66 26.2 84.87C26.8 83.29 27.72 80.9 25.96 78.9C25.56 78.45 25.12 78.15 24.76 77.91C24.67 77.85 24.54 77.76 24.45 77.69C24.57 77.44 24.83 77.05 25.02 76.77C25.52 76.02 26.15 75.08 26.36 73.95C27.02 70.48 23.99 68.71 21.78 67.41C20.45 66.63 18.95 65.75 18.92 64.92C19.07 64.59 19.97 63.88 20.64 63.35C23.5 61.09 28.29 57.3 28.29 47.68C28.29 43.46 28.44 38.63 30.21 34.55C32.82 28.56 39.78 24.58 50.88 22.72C54.76 22.07 57.92 21.77 60.84 21.77M60.84 19.77C57.41 19.77 53.95 20.18 50.55 20.75C41.74 22.23 31.94 25.58 28.37 33.76C26.48 38.1 26.28 42.98 26.28 47.69C26.28 60.75 16.91 61.51 16.91 64.9C16.84 68.95 25.16 69.51 24.39 73.58C24.05 75.4 21.72 76.93 22.57 78.55C22.98 79.32 23.9 79.59 24.46 80.22C26.06 82.04 23.49 84.71 23.61 87.14C23.74 89.83 26.78 91.07 29.6 91.07C30.01 91.07 30.42 91.04 30.82 90.99C32.58 90.75 34.44 90.3 36.17 90.3C37.47 90.3 38.7 90.55 39.77 91.33C41.37 92.45 42.15 94.48 42.56 96.38C43.53 100.16 43.82 104.14 43.41 108.05H74.88C73.87 88.25 75.37 82.65 76.63 80.07C77.82 77.76 94.65 65.87 90.07 44.31C88.32 33.82 80.76 24.31 70.68 21.16C67.47 20.16 64.16 19.77 60.83 19.77H60.84Z"
                fill="white" />
              <path
                d="M89.02 88.49C102.523 88.49 113.47 77.5434 113.47 64.04C113.47 50.5366 102.523 39.59 89.02 39.59C75.5166 39.59 64.57 50.5366 64.57 64.04C64.57 77.5434 75.5166 88.49 89.02 88.49Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.59C102.52 39.59 113.47 50.54 113.47 64.04C113.47 77.54 102.52 88.49 89.02 88.49C75.52 88.49 64.57 77.54 64.57 64.04C64.57 50.54 75.52 39.59 89.02 39.59ZM89.02 36.77C73.98 36.77 61.75 49 61.75 64.04C61.75 79.08 73.98 91.31 89.02 91.31C104.06 91.31 116.29 79.08 116.29 64.04C116.29 49 104.06 36.77 89.02 36.77Z"
                fill="white" />
              <path
                d="M60.01 35.32C62.0369 35.32 63.68 33.6769 63.68 31.65C63.68 29.6231 62.0369 27.98 60.01 27.98C57.9831 27.98 56.34 29.6231 56.34 31.65C56.34 33.6769 57.9831 35.32 60.01 35.32Z"
                fill="#0A863C" />
              <path
                d="M48.51 43.55C51.0892 43.55 53.18 41.4592 53.18 38.88C53.18 36.3008 51.0892 34.21 48.51 34.21C45.9308 34.21 43.84 36.3008 43.84 38.88C43.84 41.4592 45.9308 43.55 48.51 43.55Z"
                fill="#0A863C" />
              <path
                d="M53.45 60.71C56.8576 60.71 59.62 57.9476 59.62 54.54C59.62 51.1324 56.8576 48.37 53.45 48.37C50.0424 48.37 47.28 51.1324 47.28 54.54C47.28 57.9476 50.0424 60.71 53.45 60.71Z"
                fill="#0A863C" />
              <path
                d="M89.02 77.71C87.87 77.71 86.93 76.78 86.93 75.62C86.93 74.46 87.86 73.53 89.02 73.53C90.18 73.53 91.11 74.46 91.11 75.62C91.11 76.78 90.18 77.71 89.02 77.71Z"
                fill="white" />
              <path
                d="M92.21 66.41C91.51 66.74 91.07 67.47 91.1 68.25V69.1C91.1 70.25 90.17 71.19 89.01 71.19C87.85 71.19 86.92 70.26 86.92 69.1V68.22C86.87 65.75 88.32 63.5 90.59 62.53C92.01 61.94 93 60.62 93.15 59.09C93.3 57.56 92.6 56.07 91.32 55.21C90.04 54.35 88.39 54.27 87.04 55C85.68 55.72 84.83 57.14 84.83 58.68C84.83 59.83 83.9 60.77 82.74 60.77C81.58 60.77 80.65 59.84 80.65 58.68C80.65 56.22 81.74 53.88 83.63 52.3C85.49 50.73 87.95 50.06 90.35 50.46C92.75 50.86 94.86 52.28 96.12 54.36C97.38 56.44 97.67 58.97 96.91 61.28C96.15 63.59 94.42 65.46 92.17 66.39V66.41H92.21Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_130">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">{{
          $t('buttons.included_item_other')
        }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "IncludedItemOther",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>