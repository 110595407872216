import * as Api from "./Api";

export default {
    index(sn, model) {

        return Api.client.post(`/serialCheck`,{
            sn: sn,
            model: model
        });
    },
};
