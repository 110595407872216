<template>
      <div>

            <v-row>
                  <v-col class="pb-0">
                        <svg @mouseenter="hovered = true"
                              @mouseleave="hovered = false" :width="width"
                              :height="height" viewBox="0 0 128 128" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_2_1159)">
                                    <path d="M64 103.44C85.8097 103.44 103.49 85.7597 103.49 63.95C103.49 42.1403 85.8097 24.46 64 24.46C42.1903 24.46 24.51 42.1403 24.51 63.95C24.51 85.7597 42.1903 103.44 64 103.44Z"
                                          fill="#D0F4DB" />
                                    <path d="M64 27.11C84.31 27.11 100.83 43.63 100.83 63.94C100.83 84.25 84.31 100.77 64 100.77C43.69 100.77 27.17 84.25 27.17 63.94C27.17 43.63 43.69 27.11 64 27.11ZM64 24.46C42.19 24.46 24.51 42.14 24.51 63.95C24.51 85.76 42.19 103.44 64 103.44C85.81 103.44 103.49 85.76 103.49 63.95C103.49 42.14 85.81 24.46 64 24.46Z"
                                          fill="white" />
                                    <path d="M77.7101 91.37C99.0338 91.37 116.32 74.0837 116.32 52.76C116.32 31.4363 99.0338 14.15 77.7101 14.15C56.3864 14.15 39.1001 31.4363 39.1001 52.76C39.1001 74.0837 56.3864 91.37 77.7101 91.37Z"
                                          :fill="c_base_color" />
                                    <g :class="{ hovered: hovered }">
                                          <path d="M107.68 63.95C107.68 39.86 88.0801 20.27 64.0001 20.27C63.2701 20.27 62.5401 20.29 61.8101 20.33C60.6601 20.39 59.7801 21.37 59.8401 22.52C59.9001 23.67 60.9201 24.57 62.0301 24.49C62.6801 24.46 63.3401 24.44 64.0001 24.44C85.7901 24.44 103.52 42.17 103.52 63.96C103.52 85.75 85.7901 103.48 64.0001 103.48C42.2101 103.48 24.4801 85.75 24.4801 63.96C24.4801 63.3 24.5001 62.64 24.5301 61.99C24.5901 60.84 23.7001 59.87 22.5601 59.81C22.5301 59.81 22.4901 59.81 22.4501 59.81C21.3501 59.81 20.4301 60.68 20.3701 61.78C20.3301 62.51 20.3201 63.23 20.3201 63.97C20.3201 88.06 39.9201 107.65 64.0001 107.65C88.0801 107.65 107.68 88.06 107.68 63.97V63.95Z"
                                                :fill="c_base_clock_color" />
                                          <path d="M30.84 42.43C30.22 43.39 28.92 43.67 27.96 43.04C27 42.41 26.72 41.13 27.35 40.16C28.18 38.88 29.08 37.64 30.05 36.45C30.46 35.95 31.06 35.68 31.67 35.68C32.13 35.68 32.59 35.83 32.98 36.14C33.87 36.86 34.01 38.18 33.28 39.07C32.41 40.14 31.6 41.26 30.84 42.42V42.43Z"
                                                fill="#E28888" />
                                          <path d="M27.08 49.81C26.59 51.09 26.16 52.41 25.81 53.75C25.52 54.86 24.37 55.53 23.26 55.22C22.15 54.92 21.49 53.79 21.78 52.67C22.18 51.19 22.65 49.73 23.19 48.31C23.51 47.48 24.3 46.97 25.13 46.97C25.38 46.97 25.63 47.01 25.87 47.11C26.94 47.52 27.48 48.72 27.07 49.8L27.08 49.81Z"
                                                fill="#E28888" />
                                          <path d="M42.5 30.78C41.34 31.54 40.21 32.35 39.14 33.22C38.24 33.95 36.94 33.81 36.21 32.91C35.49 32.02 35.63 30.7 36.52 29.98C37.71 29.02 38.94 28.12 40.23 27.29C40.58 27.06 40.97 26.95 41.36 26.95C42.04 26.95 42.71 27.28 43.1 27.9C43.73 28.86 43.46 30.15 42.49 30.78H42.5Z"
                                                fill="#E28888" />
                                          <path d="M53.83 25.75C52.48 26.11 51.16 26.54 49.88 27.02C48.81 27.44 47.6 26.9 47.19 25.82C46.78 24.75 47.32 23.54 48.39 23.14C49.81 22.6 51.27 22.13 52.75 21.73C52.93 21.68 53.11 21.66 53.29 21.66C54.21 21.66 55.05 22.27 55.3 23.2C55.6 24.31 54.94 25.45 53.83 25.75Z"
                                                fill="#E28888" />
                                          <path d="M33.12 62.33C31.98 62.33 31.05 63.26 31.05 64.4C31.05 65.54 31.98 66.47 33.12 66.47H34.37C35.51 66.47 36.44 65.54 36.44 64.4C36.44 63.26 35.51 62.33 34.37 62.33H33.12Z"
                                                :fill="c_base_clock_color" />
                                          <path d="M65.5199 34.51V33.26C65.5199 32.12 64.5899 31.19 63.4499 31.19C62.3099 31.19 61.3799 32.12 61.3799 33.26V34.51C61.3799 35.65 62.3099 36.58 63.4499 36.58C64.5899 36.58 65.5199 35.65 65.5199 34.51Z"
                                                :fill="c_base_clock_color" />
                                          <path d="M61.3101 93.48V94.73C61.3101 95.87 62.2401 96.8 63.3801 96.8C64.5201 96.8 65.4501 95.87 65.4501 94.73V93.48C65.4501 92.34 64.5201 91.41 63.3801 91.41C62.2401 91.41 61.3101 92.34 61.3101 93.48Z"
                                                :fill="c_base_clock_color" />
                                          <path d="M53.2599 51.35C52.4499 50.54 51.1399 50.54 50.3399 51.35C49.5299 52.16 49.5299 53.47 50.3399 54.27L57.2099 61.14C56.6899 62.12 56.3899 63.24 56.3899 64.43C56.3899 68.31 59.5499 71.47 63.4299 71.47C67.3099 71.47 70.4699 68.31 70.4699 64.43C70.4699 63.24 70.1699 62.12 69.6499 61.14L83.5299 47.26C84.3399 46.45 84.3399 45.14 83.5299 44.33C82.7199 43.52 81.4099 43.52 80.5999 44.33L66.7199 58.21C65.7399 57.69 64.6199 57.39 63.4299 57.39C62.2399 57.39 61.1199 57.69 60.1399 58.21L53.2699 51.34L53.2599 51.35ZM66.3199 64.43C66.3199 66.03 65.0199 67.33 63.4199 67.33C61.8199 67.33 60.5199 66.03 60.5199 64.43C60.5199 62.83 61.8199 61.53 63.4199 61.53C65.0199 61.53 66.3199 62.83 66.3199 64.43Z"
                                                :fill="c_base_clock_color" />
                                          <path d="M94.74 110.5C108.243 110.5 119.19 99.5534 119.19 86.05C119.19 72.5466 108.243 61.6 94.74 61.6C81.2367 61.6 70.29 72.5466 70.29 86.05C70.29 99.5534 81.2367 110.5 94.74 110.5Z"
                                                fill="#E28888" />
                                          <path d="M94.74 111C80.98 111 69.79 99.81 69.79 86.05C69.79 72.29 80.98 61.1 94.74 61.1C108.5 61.1 119.69 72.29 119.69 86.05C119.69 99.81 108.5 111 94.74 111ZM94.74 62.1C81.53 62.1 70.79 72.84 70.79 86.05C70.79 99.26 81.53 110 94.74 110C107.95 110 118.69 99.26 118.69 86.05C118.69 72.84 107.95 62.1 94.74 62.1Z"
                                                fill="white" />
                                          <path d="M94.74 61.6C108.24 61.6 119.19 72.55 119.19 86.05C119.19 99.55 108.24 110.5 94.74 110.5C81.24 110.5 70.29 99.55 70.29 86.05C70.29 72.55 81.24 61.6 94.74 61.6ZM94.74 58.78C79.7 58.78 67.47 71.01 67.47 86.05C67.47 101.09 79.7 113.32 94.74 113.32C109.78 113.32 122.01 101.09 122.01 86.05C122.01 71.01 109.78 58.78 94.74 58.78Z"
                                                fill="white" />
                                          <path d="M109.04 86.53H105.31L101.79 73.61C101.56 72.75 100.76 72.13 99.8601 72.17C98.9601 72.19 98.1901 72.82 97.9901 73.7L94.2401 90.58L89.9901 78.69C89.7101 77.91 88.9701 77.39 88.1301 77.39C88.1101 77.39 88.1001 77.39 88.0801 77.39C87.2301 77.42 86.5001 77.99 86.2601 78.81L84.0501 86.53H80.3001C79.2201 86.53 78.3401 87.41 78.3401 88.49C78.3401 89.57 79.2201 90.45 80.3001 90.45H85.5201C86.3901 90.45 87.1601 89.87 87.4001 89.03L88.3401 85.75L92.8201 98.29C93.1001 99.07 93.8301 99.59 94.6601 99.59H94.7801C95.6401 99.53 96.3801 98.9 96.5701 98.06L100.07 82.28L101.91 89.01C102.14 89.86 102.92 90.45 103.8 90.45H109.02C110.1 90.45 110.98 89.57 110.98 88.49C110.98 87.41 110.1 86.53 109.02 86.53H109.04Z"
                                                fill="white" />
                                          <path d="M94.6901 99.83C93.7501 99.83 92.9101 99.24 92.6001 98.36L88.3801 86.56L87.6601 89.09C87.3901 90.03 86.5201 90.69 85.5401 90.69H80.3201C79.1001 90.69 78.1101 89.7 78.1101 88.48C78.1101 87.26 79.1001 86.27 80.3201 86.27H83.8801L86.0301 78.73C86.2901 77.8 87.1201 77.16 88.0801 77.13C89.0701 77.13 89.9101 77.72 90.2201 78.59L94.1801 89.66L97.7401 73.63C97.9601 72.64 98.8301 71.93 99.8401 71.91C100.83 71.85 101.75 72.54 102.02 73.53L105.49 86.27H109.03C110.25 86.27 111.24 87.26 111.24 88.48C111.24 89.7 110.25 90.69 109.03 90.69H103.81C102.82 90.69 101.94 90.02 101.68 89.06L100.11 83.31L96.8201 98.1C96.6101 99.05 95.7801 99.76 94.8001 99.82H94.6701L94.6901 99.83ZM88.3301 84.92L93.0701 98.19C93.3101 98.87 93.9601 99.32 94.6801 99.32H94.7901C95.5401 99.27 96.1801 98.72 96.3401 97.98L100.06 81.22L102.16 88.93C102.36 89.67 103.04 90.19 103.8 90.19H109.02C109.96 90.19 110.73 89.43 110.73 88.48C110.73 87.53 109.97 86.77 109.02 86.77H105.1L105.05 86.59L101.53 73.67C101.32 72.91 100.64 72.4 99.8501 72.42C99.0601 72.44 98.3901 72.99 98.2201 73.75L94.2801 91.47L89.7401 78.76C89.5001 78.08 88.8501 77.63 88.1201 77.63C87.3401 77.65 86.7001 78.15 86.5001 78.87L84.2401 86.77H80.3101C79.3701 86.77 78.6001 87.54 78.6001 88.48C78.6001 89.42 79.3701 90.19 80.3101 90.19H85.5301C86.2901 90.19 86.9601 89.68 87.1701 88.95L88.3201 84.92H88.3301Z"
                                                fill="#E28888" />
                                    </g>
                              </g>
                              <defs>
                                    <clipPath id="clip0_2_1159">
                                          <rect width="128" height="128"
                                                fill="white" />
                                    </clipPath>
                              </defs>
                        </svg>


                  </v-col>
            </v-row>

            <v-row>
                  <v-col class="pt-5">
                        <span class="font-weight-medium"
                              :style="[cp_font_size]">{{ $t('buttons.eco_fault_short_working_time') }}</span>
                  </v-col>
            </v-row>

      </div>
</template>

<script>

export default {
      name: "EcoFaultShortWorkingTime",
      props: {
            selected: { type: Boolean, required: false },
            height: { type: Number, required: 200 },
            width: { type: Number, required: 180 },
            font_size: { type: String, required: '15px' },

            base_color: { type: String, required: true },
            base_hovered_color: { type: String, required: true },
            base_selected_color: { type: String, required: true },

            base_border_color: { type: String, required: true },
            base_border_hovered_color: { type: String, required: true },
            base_border_selected_color: { type: String, required: true },

      },
      data() {
            return {
                  hovered: false
            }
      },
      computed: {
            cp_font_size() {
                  return `font-size: ${this.font_size}`
            },
            c_base_color() {
                  let color = this.base_color
                  if (this.selected) {
                        color = this.base_selected_color
                  } else {
                        if (this.hovered) color = this.base_hovered_color
                  }
                  return color
            },
            c_base_clock_color() {
                  let color = '#0A863C';
                  if (this.selected) {
                        color = '#D0F4DB';
                  } else {
                        if (this.hovered) color = '#D0F4DB'
                  }
                  return color
            },
            c_base_border_color() {
                  let color = this.base_border_color
                  if (this.selected) {
                        color = this.base_border_selected_color
                  } else {
                        if (this.hovered) color = this.base_border_hovered_color
                  }
                  return color
            }
      }
}
</script>

<style scoped></style>