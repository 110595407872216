<template>
  <div id="summary-step">
    <app-form-header :title="$t('form.steps.summary.title')"></app-form-header>
    <v-row class="move-top">
      <v-col :class="[`mt-${classes.top_padding}`]">
        <div v-if="claim_form.transport_in === 'transport_dhl_parcel_shop'" :class="classes.info"
             v-text="dhl_pop_info"></div>
        <div :class="classes.info" v-text="summary_info"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="10" :class="[`mt-${classes.top_padding}`]">
        <div :class="classes.greetings">
          {{ $t("form.steps.summary.greetings_1") }}<br/>
          {{ $t("form.steps.summary.greetings_2") }}
        </div>
      </v-col>
      <v-col cols="12" sm="2" class="px-5 py-0">
        <app-graphic-mail
            class="float-end"
            :height="classes.graphic_size"
            :width="classes.graphic_size"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {useDisplay} from "vuetify";
import {computed} from "vue";
import {mapGetters} from "vuex";

export default {
  name: "SummaryStep",
  setup() {
    const {name} = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      const isModal = window.self !== window.top;
      switch (name.value) {
        case "xs":
          return {
            top_padding: (isModal)?0:2,
            title: ["title-xs", "pa-2"],
            info: ["info-xs", "px-5 py-2", "text-justify"],
            greetings: ["greetings-xs", (isModal)?"px-5":"pa-5", "text-justify"],
            graphic_size: 100,
          };
        case "sm":
          return {
            top_padding: (isModal)?0:3,
            title: ["title-sm", "pa-4"],
            info: ["info-sm", "px-5 py-2", "text-justify"],
            greetings: ["greetings-sm", (isModal)?"px-5":"pa-5", "text-justify"],
            graphic_size: 125,
          };
        case "md":
          return {
            top_padding: (isModal)?0:3,
            title: ["title-sm", "pa-4"],
            info: ["info-sm", "px-5 py-2", "text-justify"],
            greetings: ["greetings-sm", (isModal)?"px-5":"pa-5", "text-justify"],
            graphic_size: 125,
          };

        default:
          return {
            top_padding: (isModal)?0:4,
            title: ["ccs-title", "pa-5"],
            info: ["info-default", "px-5 py-2", "text-justify"],
            greetings: ["greetings-default", "px-5 py-1", "text-justify"],
            graphic_size: 150,
          };
      }
    });
    return {
      classes,
    };
  },
  data() {
    return {
      contact_phone: "+48 22 726 38 10",
    };
  },
  computed: {
    ...mapGetters("ClaimForm", {claim_form: "claim_form"}),
    contact_email() {
      return this.claim_form.fixed_client === "terg"
          ? "e-commerce@ccsonline.pl"
          : "zgloszenia@ccsonline.pl";
    },
    summary_info() {
      let info = this.$t("form.steps.summary.info");
      if (this.claim_form.transport_in === "transport_dpd")
        info = this.$t("form.steps.summary.info_dpd");
      if (this.claim_form.transport_in === "transport_dhl")
        info = this.$t("form.steps.summary.info_dhl");
      if (this.claim_form.transport_in === "transport_self_deliver")
        info = this.$t("form.steps.summary.info_self");
      if (this.claim_form.transport_in === "transport_inpost")
        info = this.$t("form.steps.summary.info_inpost");
      if (this.claim_form.transport_in === "transport_dhl_parcel_shop")
        info = this.$t("form.steps.summary.info_dhl_parcel_shop");

      info = info.replace(":contact_phone", this.contact_phone);
      info = info.replace(":contact_email", this.contact_email);
      return info;
    },
    dhl_pop_info() {
      let text = this.$t("form.steps.summary.info_dhl_parcel_shop_point_data");
      text = text.replace(":point_number", this.claim_form.transport_dhl_parcel_shop.name);
      text = text.replace(":point_address", ` - ${this.claim_form.transport_dhl_parcel_shop.street} ${this.claim_form.transport_dhl_parcel_shop.streetNo}, ${this.claim_form.transport_dhl_parcel_shop.zip} ${this.claim_form.transport_dhl_parcel_shop.city}`);
      return text;
    },
  },
};
</script>

<style scoped>
</style>