<template>
  <div class="mt-2 mb-2 ml-5 text-left" :class="classes.daria_font_1">
  <p>{{$t('form.steps.regulations.rodo_admin')}}
  <a @click="getCcsRodoFull" target="_blank"><b style="text-decoration: underline">{{$t('form.steps.regulations.rodo_linkname')}}</b></a>
  </p>
    <p class="mt-3 mb-2" :class="classes.daria_font_2">{{$t('form.steps.regulations.rodo_warn')}}</p>
  </div>
  <v-row>
    <v-col :style="{'max-width': '30px'}">
      <v-icon :icon="claim_form.regulations_rodo1 ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
              @click="updateClaimForm({ key: 'regulations_rodo1', value: !claim_form.regulations_rodo1});switchClausules('rodo1');"/>
    </v-col>
    <v-col class="text-left">
      <span :class="classes.daria_font_1" @click="updateClaimForm({ key: 'regulations_rodo1', value: !claim_form.regulations_rodo1 });switchClausules('rodo1')">
        <b>{{ $t('form.steps.regulations.rodo1_bold') }}</b>{{ $t('form.steps.regulations.rodo1') }}
      </span>
    </v-col>
  </v-row>
  <v-row class="mt-0">
    <v-col :style="{'max-width': '30px'}">
      <v-icon :icon="claim_form.regulations_rodo2 ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
              @click="updateClaimForm({ key: 'regulations_rodo2', value: !claim_form.regulations_rodo2 });switchClausules('rodo2')"/>
    </v-col>
    <v-col class="text-left">
      <span :class="classes.daria_font_1" @click="updateClaimForm({ key: 'regulations_rodo2', value: !claim_form.regulations_rodo2 });switchClausules('rodo2')">
         <b>{{ $t('form.steps.regulations.rodo2_bold') }}</b>{{ $t('form.steps.regulations.rodo2') }}
         </span>
    </v-col>
  </v-row>
</template>

<script>
import {useDisplay} from "vuetify";
import {computed} from "vue";
import {mapActions, mapGetters} from "vuex";
import * as Api from "@/services/Api";

export default {
  name: "RegulationsRodoDeclarations",
  setup() {
    const {name} = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return {
            top_padding: 2,
            title: ["title-xs", "pa-2"],
            print_label_check: ['print-label-check', 'print-label-check-align-center', 'mt-3'],
            graphic_size: 100,
            daria_font: []
          };
        case "sm":
          return {
            top_padding: 3,
            title: ["title-sm", "pa-4"],
            print_label_check: ['print-label-check', 'print-label-check-align-center', 'pa-2', 'mt-3'],
            graphic_size: 150,
            daria_font: []
          };
        default:
          return {
            top_padding: 4,
            title: ["title-default", "pa-5"],
            print_label_check: ['print-label-check', 'print-label-check-align-justify', 'mt-3'],
            graphic_size: 200,
            daria_font_1: ['daria-font-size-13'],
            daria_font_2: ['daria-font-size-12'],
          };
      }
    });
    return {
      classes
    };
  },
  computed: {
    ...mapGetters("ClaimForm", {claim_form: "claim_form"}),

  },
  methods: {
    ...mapActions('AppAlert', {showAlert: 'showAlert'}),
    ...mapActions("ClaimForm", {updateClaimForm: "updateClaimForm", syncValue: "syncValue",}),
    getCcsRodoFull() {
      let link = 'getCurrentRodo/'+this.$i18n.locale;
      //let name = 'regulations_'+this.$i18n.locale+".pdf";
      Api.client
          .get(link, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            //link.download = name;
            link.target = "_blank";
            link.click();
            URL.revokeObjectURL(link.href)
          }).catch(() => {
            this.showAlert({
              type: 'danger',
              message: this.$t('technical_error')
            })
      })
    },
    switchClausules(id)
    {
      if(id==="rodo1")
      {
        this.claim_form.regulations_rodo2=false;
        this.updateClaimForm({ key: 'regulations_rodo2', value: this.claim_form.regulations_rodo2})
      }
      if(id==="rodo2")
      {
        this.claim_form.regulations_rodo1=false;
        this.updateClaimForm({ key: 'regulations_rodo1', value: this.claim_form.regulations_rodo1})
      }
    }
  }
}
</script>

<style scoped>
.daria-font-size-15{
  font-size: 15px;
}
.daria-font-size-13{
  font-size: 13px;
}
.daria-font-size-12{
  font-size: 12px;
}
</style>