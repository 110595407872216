// import MakerService from "../../services/MakerService";

import MakerService from "@/services/MakerService";

export const namespaced = true;


const getDefaultState = () => {
    return {
        makers: [],
        loading: false,
        error: {},
    }
}

function setMakers(commit, response) {
    commit("SET_MAKERS", response.data);
    commit("SET_LOADING", false);
}

export const state = getDefaultState();

export const mutations = {
    SET_MAKERS(state, makers) {
        state.makers = makers;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

export const actions = {
    getMakers({commit}, caller) {
        commit("SET_LOADING", true);
        commit("SET_ERROR", {});
        /** #2426 - XIAOMI_DE CLIENT */
        let payload = {};
        if (
            caller.claim_form.fixed_client !== null
            && (caller.claim_form.fixed_client.toUpperCase() === 'XIAOMI_DE' || caller.claim_form.fixed_client.toUpperCase() === 'XIAOMI_FR')
        ) {
            payload.params = {
                client: 'XIAOMI_DE'
            }
        }
        return MakerService.index(payload)
            .then((response) => {
                setMakers(commit, response)
                return true;
            }).catch(() => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", true);
                caller.showAlert({
                    type: 'danger',
                    message: 'Network error'
                });
                return false;
            })
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
};

export const getters = {
    makers: (state) => {
        return state.makers;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};
