<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_84)">
            <path
              d="M50.32 102.11C71.6437 102.11 88.93 84.8237 88.93 63.5C88.93 42.1763 71.6437 24.89 50.32 24.89C28.9963 24.89 11.71 42.1763 11.71 63.5C11.71 84.8237 28.9963 102.11 50.32 102.11Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M60.2 16.04C60.65 16.04 61.02 16.41 61.02 16.86V29.06H63.14C64.09 29.06 64.85 29.83 64.85 30.77V59.1C64.85 64.38 60.56 68.67 55.28 68.67H46.85C41.57 68.67 37.28 64.38 37.28 59.1V30.77C37.28 29.82 38.05 29.06 38.99 29.06H41.11V16.86C41.11 16.41 41.48 16.04 41.93 16.04H60.2ZM60.2 13.04H41.92C39.81 13.04 38.1 14.76 38.1 16.86V26.14C35.92 26.55 34.27 28.47 34.26 30.77V59.1C34.26 66.03 39.91 71.67 46.83 71.67H55.27C62.2 71.67 67.84 66.02 67.84 59.1V30.77C67.84 28.47 66.18 26.55 64 26.14V16.86C64 14.76 62.29 13.05 60.19 13.04H60.2Z"
                fill="#0A863C" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M60.2 16.04H41.93C41.48 16.04 41.11 16.41 41.11 16.86V29.06H38.99C38.04 29.06 37.28 29.83 37.28 30.77V59.1C37.28 64.38 41.57 68.67 46.85 68.67H55.28C60.56 68.67 64.85 64.38 64.85 59.1V30.77C64.85 29.82 64.08 29.06 63.14 29.06H61.02V16.86C61.02 16.41 60.65 16.04 60.2 16.04Z"
                fill="#D0F4DB" />
              <path
                d="M59.02 18.04V31.06H62.86V59.1C62.86 63.27 59.46 66.67 55.29 66.68H46.86C42.69 66.68 39.29 63.28 39.28 59.11V31.07H43.12V18.05H59.03M60.21 16.05H41.94C41.49 16.05 41.12 16.42 41.12 16.87V29.07H39C38.05 29.07 37.29 29.84 37.29 30.78V59.11C37.29 64.39 41.58 68.68 46.86 68.68H55.29C60.57 68.68 64.86 64.39 64.86 59.11V30.78C64.86 29.83 64.09 29.07 63.15 29.07H61.03V16.87C61.03 16.42 60.66 16.05 60.21 16.05Z"
                fill="white" />
              <path
                d="M49.47 22.39C49.47 21.4124 48.6775 20.62 47.7 20.62C46.7224 20.62 45.93 21.4124 45.93 22.39V28.04C45.93 29.0175 46.7224 29.81 47.7 29.81C48.6775 29.81 49.47 29.0175 49.47 28.04V22.39Z"
                fill="#0A863C" />
              <path
                d="M56.2 22.39C56.2 21.4124 55.4075 20.62 54.43 20.62C53.4525 20.62 52.66 21.4124 52.66 22.39V28.04C52.66 29.0175 53.4525 29.81 54.43 29.81C55.4075 29.81 56.2 29.0175 56.2 28.04V22.39Z"
                fill="#0A863C" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M51.35 39.93C51.52 39.93 51.69 40.01 51.8 40.15L53.47 42.25C53.56 42.37 53.61 42.52 53.59 42.67C53.57 42.82 53.5 42.96 53.38 43.05C53.26 43.14 53.11 43.19 52.96 43.17C52.81 43.15 52.67 43.08 52.58 42.96L51.94 42.15V50.13L55.21 48.15V45.08H54.9C54.58 45.08 54.33 44.82 54.33 44.51V42.75C54.33 42.43 54.59 42.18 54.9 42.18H56.66C56.98 42.18 57.23 42.44 57.23 42.75V44.51C57.23 44.83 56.97 45.08 56.66 45.08H56.35V48.48C56.35 48.68 56.24 48.87 56.07 48.97L51.93 51.48V54.38C52.72 54.66 53.2 55.47 53.06 56.3C52.92 57.13 52.2 57.74 51.36 57.74C50.52 57.74 49.8 57.13 49.66 56.3C49.52 55.47 50 54.66 50.79 54.38V53.54L46.27 50.8C46.1 50.7 45.99 50.51 45.99 50.31V48.11C45.25 47.83 44.81 47.06 44.95 46.28C45.09 45.5 45.77 44.93 46.56 44.93C47.35 44.93 48.03 45.5 48.17 46.28C48.31 47.06 47.87 47.83 47.13 48.11V49.99L50.78 52.21V42.17L50.14 42.98C49.94 43.23 49.58 43.27 49.33 43.07C49.08 42.87 49.04 42.51 49.24 42.26L50.91 40.16C51.02 40.02 51.18 39.94 51.36 39.94L51.35 39.93Z"
                fill="#0A863C" />
              <path
                d="M82.23 87.82V96.04C82.23 104.29 75.54 110.98 67.29 110.98C59.04 110.98 52.35 104.29 52.35 96.04V74.48H49.35V96.04C49.35 105.93 57.4 113.98 67.29 113.98C77.18 113.98 85.23 105.93 85.23 96.04V87.82H82.23Z"
                fill="#0A863C" />
              <path
                d="M89.02 88.49C102.523 88.49 113.47 77.5434 113.47 64.04C113.47 50.5366 102.523 39.59 89.02 39.59C75.5166 39.59 64.57 50.5366 64.57 64.04C64.57 77.5434 75.5166 88.49 89.02 88.49Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.59C102.52 39.59 113.47 50.54 113.47 64.04C113.47 77.54 102.52 88.49 89.02 88.49C75.52 88.49 64.57 77.54 64.57 64.04C64.57 50.54 75.52 39.59 89.02 39.59ZM89.02 36.77C73.98 36.77 61.75 49 61.75 64.04C61.75 79.08 73.98 91.31 89.02 91.31C104.06 91.31 116.29 79.08 116.29 64.04C116.29 49 104.06 36.77 89.02 36.77Z"
                fill="white" />
              <path
                d="M45.7 73.95L46.78 81.23C46.95 81.98 47.64 82.5 48.4 82.48H53.21C53.98 82.5 54.66 81.98 54.84 81.23L55.91 73.95H45.69H45.7Z"
                fill="#0A863C" />
              <path
                d="M104.14 57.99H73.89C73.33 57.99 72.88 58.44 72.88 59V69.08C72.88 69.64 73.33 70.09 73.89 70.09H104.14C104.7 70.09 105.15 69.64 105.15 69.08V59C105.15 58.44 104.7 57.99 104.14 57.99ZM103.13 65.05C103.13 65.61 102.68 66.06 102.12 66.06H99.1C99.1 66.62 98.65 67.07 98.09 67.07C97.53 67.07 97.08 66.62 97.08 66.06H93.05C93.05 66.62 92.6 67.07 92.04 67.07C91.48 67.07 91.03 66.62 91.03 66.06H87C87 66.62 86.55 67.07 85.99 67.07C85.43 67.07 84.98 66.62 84.98 66.06H80.95C80.95 66.62 80.5 67.07 79.94 67.07C79.38 67.07 78.93 66.62 78.93 66.06H75.91C75.35 66.06 74.9 65.61 74.9 65.05V61.02C74.9 60.46 75.35 60.01 75.91 60.01H102.12C102.68 60.01 103.13 60.46 103.13 61.02V65.05Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_84">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">{{
          $t('buttons.included_item_usb_cable')
        }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "IncludedItemUsbCable",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>