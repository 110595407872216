export const Validator = {
    data() {
        return {
            first_name_rules: [
                v => !!v || this.$t('form.validations.first_name.required'),
                v => (v && v.length <= 64) || this.$t('form.validations.first_name.max_64'),
            ],
            last_name_rules: [
                v => !!v || this.$t('form.validations.last_name.required'),
                v => (v && v.length <= 64) || this.$t('form.validations.last_name.max_64'),
            ],
            street_name_rules: [
                v => !!v || this.$t('form.validations.street.required'),
                v => (v && v.length <= 64) || this.$t('form.validations.street.max_64'),
            ],
            city_name_rules: [
                v => !!v || this.$t('form.validations.city.required'),
                v => (v && v.length <= 64) || this.$t('form.validations.city.max_64'),
            ],
            building_number_rules: [
                v => !!v || this.$t('form.validations.building_number.required'),
                v => (v && v.length <= 10) || this.$t('form.validations.building_number.max_10'),
            ],
            apartment_number_rules: [
                //v => (!!v || (v && v.length <= 10)) || this.$t('form.validations.apartment_number.max_10'),
            ],
            email_rules: [
                v => !!v || this.$t('form.validations.email.required'),
                v => (v && v.length <= 64) || this.$t('form.validations.email.max_64'),
                v => /.+@.+\..+/.test(v) || this.$t('form.validations.email.invalid'),
            ],
            phone_number_rules: [
                v => !!v || this.$t('form.validations.phone_number.required'),
                v => (v && v.length <= 9) || this.$t('form.validations.phone_number.max_9'),
                v => /^\d{9}$/.test(v) || this.$t('form.validations.phone_number.invalid'),
            ],
            nip_rules: [
                v => !!v || this.$t('form.validations.nip.required'),
                v => (v && v.length <= 10) || this.$t('form.validations.nip.max_10'),
                v => /^\d{10}$/.test(v) || this.$t('form.validations.nip.invalid'),
            ],
            postal_code_rules: [
                v => !!v || this.$t('form.validations.postal_code.required'),
                v => (v && v.length <= 6) || this.$t('form.validations.postal_code.max_6'),
                v => /^\d{2}-\d{3}$/.test(v) || this.$t('form.validations.postal_code.invalid'),
            ],

            required_company_name_rules: [
                v => !!v || this.$t('form.validations.company_name.required'),
                v => (v && v.length <= 64) || this.$t('form.validations.company_name.max_64'),
            ],

            terg_email_address_rules: [
                v => !!v || this.$t('form.validations.terg_email_address.required'),
            ],
            nr_rma_rules: [
                // v => !!v || this.$t('form.validations.nr_rma.required'),
            ],
            xiaomi_de_postal_code_rules: [
                v => !!v || this.$t('form.validations.postal_code.required'),
                v => (v && v.length <= 8) || this.$t('form.validations.postal_code.max_8'),
                v => /^\d+$/.test(v) || this.$t('form.validations.postal_code.invalid'),
            ],
            xiaomi_de_phone_number_rules: [
                v => !!v || this.$t('form.validations.phone_number.required'),
                v => (v && v.length <= 15) || this.$t('form.validations.phone_number.max_15'),
                v => /^[+]?\d+$/.test(v) || this.$t('form.validations.phone_number.invalid'),
            ],
        };
    },
};