<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_1575)">
            <path
              d="M81.4201 100.46C102.744 100.46 120.03 83.1737 120.03 61.85C120.03 40.5263 102.744 23.24 81.4201 23.24C60.0963 23.24 42.8101 40.5263 42.8101 61.85C42.8101 83.1737 60.0963 100.46 81.4201 100.46Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M17.49 113.84H110.51C111.34 113.84 112.01 113.17 112.01 112.34V55.33C112.01 55.33 112 55.28 111.99 55.25C111.99 55.15 111.96 55.05 111.93 54.95C111.9 54.86 111.89 54.77 111.85 54.69C111.81 54.61 111.76 54.54 111.7 54.47C111.64 54.38 111.58 54.3 111.49 54.22C111.47 54.2 111.46 54.18 111.44 54.16L103.94 48.15C103.89 48.11 103.83 48.1 103.77 48.06C103.68 48 103.59 47.95 103.49 47.92C103.39 47.89 103.29 47.87 103.19 47.86C103.13 47.86 103.07 47.82 103 47.82H25C24.94 47.82 24.88 47.85 24.82 47.86C24.72 47.87 24.61 47.89 24.51 47.92C24.41 47.96 24.32 48.01 24.23 48.06C24.18 48.09 24.11 48.11 24.06 48.15L16.56 54.16C16.56 54.16 16.53 54.2 16.51 54.22C16.43 54.29 16.37 54.38 16.3 54.47C16.25 54.54 16.19 54.61 16.15 54.69C16.11 54.77 16.09 54.86 16.07 54.95C16.04 55.05 16.02 55.15 16.01 55.25C16.01 55.28 16 55.3 16 55.33V112.34C16 113.17 16.67 113.84 17.5 113.84H17.49Z"
                fill="#D0F4DB" />
              <path
                d="M102.81 49.78C102.81 49.78 102.83 49.78 102.84 49.78C102.84 49.78 102.86 49.79 102.87 49.8L110.03 55.53H110.04L110.05 55.56V111.89H17.95V55.55L25.14 49.81C25.14 49.81 25.15 49.81 25.16 49.8C25.17 49.8 25.18 49.8 25.2 49.8H102.82M103.01 47.84H25C24.94 47.84 24.88 47.87 24.82 47.88C24.72 47.89 24.61 47.91 24.51 47.94C24.41 47.98 24.32 48.03 24.23 48.08C24.18 48.11 24.11 48.13 24.06 48.17L16.56 54.18C16.56 54.18 16.53 54.22 16.51 54.24C16.43 54.31 16.37 54.4 16.3 54.49C16.25 54.56 16.19 54.63 16.15 54.71C16.11 54.79 16.09 54.88 16.07 54.97C16.04 55.07 16.02 55.17 16.01 55.27C16.01 55.3 16 55.32 16 55.35V112.36C16 113.19 16.67 113.86 17.5 113.86H110.52C111.35 113.86 112.02 113.19 112.02 112.36V55.35C112.02 55.35 112.01 55.3 112 55.27C112 55.17 111.97 55.07 111.94 54.97C111.91 54.88 111.9 54.79 111.86 54.71C111.82 54.63 111.77 54.56 111.71 54.49C111.65 54.4 111.59 54.32 111.5 54.24C111.48 54.22 111.47 54.2 111.45 54.18L103.95 48.17C103.9 48.13 103.84 48.12 103.78 48.08C103.69 48.02 103.6 47.97 103.5 47.94C103.4 47.91 103.3 47.89 103.2 47.88C103.14 47.88 103.08 47.84 103.01 47.84Z"
                fill="white" />
              <path d="M51.98 104.84H37V110.84H51.98V104.84Z" fill="white" />
              <path
                d="M50.02 106.79V108.88H38.95V106.79H50.02ZM51.98 104.83H37V110.83H51.98V104.83Z"
                fill="white" />
              <path d="M51.98 95.84H37V101.84H51.98V95.84Z" fill="white" />
              <path
                d="M50.02 97.79V99.88H38.95V97.79H50.02ZM51.98 95.83H37V101.83H51.98V95.83Z"
                fill="white" />
              <path d="M51.98 86.81H37V92.84H51.98V86.81Z" fill="white" />
              <path
                d="M50.02 88.76V90.88H38.95V88.76H50.02ZM51.98 86.8H37V92.83H51.98V86.8Z"
                fill="white" />
              <path d="M51.98 68.83H37V83.81H51.98V68.83Z" fill="#D0F4DB" />
              <path
                d="M50.02 70.78V81.85H38.95V70.78H50.02ZM51.98 68.82H37V83.8H51.98V68.82Z"
                fill="white" />
              <path d="M51.98 56.83H37V65.83H51.98V56.83Z" fill="#D0F4DB" />
              <path
                d="M50.02 58.78V63.87H38.95V58.78H50.02ZM51.98 56.82H37V65.82H51.98V56.82Z"
                fill="white" />
              <path d="M72.98 95.84H54.98V110.84H72.98V95.84Z" fill="#D0F4DB" />
              <path
                d="M71.02 97.79V108.88H56.93V97.79H71.02ZM72.98 95.83H54.98V110.83H72.98V95.83Z"
                fill="white" />
              <path d="M72.98 86.81H54.98V92.84H72.98V86.81Z" fill="white" />
              <path
                d="M71.02 88.76V90.88H56.93V88.76H71.02ZM72.98 86.8H54.98V92.83H72.98V86.8Z"
                fill="white" />
              <path d="M72.98 56.83H54.98V65.83H72.98V56.83Z" fill="#D0F4DB" />
              <path
                d="M71.02 58.78V63.87H56.93V58.78H71.02ZM72.98 56.82H54.98V65.82H72.98V56.82Z"
                fill="white" />
              <path d="M91.01 104.84H75.98V110.84H91.01V104.84Z" fill="white" />
              <path
                d="M89.05 106.79V108.88H77.93V106.79H89.05ZM91.01 104.83H75.98V110.83H91.01V104.83Z"
                fill="white" />
              <path d="M91.01 95.84H75.98V101.84H91.01V95.84Z" fill="white" />
              <path
                d="M89.05 97.79V99.88H77.93V97.79H89.05ZM91.01 95.83H75.98V101.83H91.01V95.83Z"
                fill="white" />
              <path d="M91.01 86.81H75.98V92.84H91.01V86.81Z" fill="white" />
              <path
                d="M89.05 88.76V90.88H77.93V88.76H89.05ZM91.01 86.8H75.98V92.83H91.01V86.8Z"
                fill="white" />
              <path d="M91.01 68.83H75.98V83.81H91.01V68.83Z" fill="#D0F4DB" />
              <path
                d="M89.05 70.78V81.85H77.93V70.78H89.05ZM91.01 68.82H75.98V83.8H91.01V68.82Z"
                fill="white" />
              <path d="M91.01 56.83H75.98V65.83H91.01V56.83Z" fill="#D0F4DB" />
              <path
                d="M89.05 58.78V63.87H77.93V58.78H89.05ZM91.01 56.82H75.98V65.82H91.01V56.82Z"
                fill="white" />
              <path d="M109.01 104.84H94.0098V110.84H109.01V104.84Z"
                fill="white" />
              <path
                d="M107.05 106.79V108.88H95.9598V106.79H107.05ZM109.01 104.83H94.0098V110.83H109.01V104.83Z"
                fill="white" />
              <path d="M109.01 95.84H94.0098V101.84H109.01V95.84Z" fill="white" />
              <path
                d="M107.05 97.79V99.88H95.9598V97.79H107.05ZM109.01 95.83H94.0098V101.83H109.01V95.83Z"
                fill="white" />
              <path d="M109.01 86.81H94.0098V92.84H109.01V86.81Z" fill="white" />
              <path
                d="M107.05 88.76V90.88H95.9598V88.76H107.05ZM109.01 86.8H94.0098V92.83H109.01V86.8Z"
                fill="white" />
              <path d="M109.01 68.83H94.0098V83.81H109.01V68.83Z"
                fill="#D0F4DB" />
              <path
                d="M107.05 70.78V81.85H95.9598V70.78H107.05ZM109.01 68.82H94.0098V83.8H109.01V68.82Z"
                fill="white" />
              <path d="M109.01 56.83H94.0098V65.83H109.01V56.83Z"
                fill="#D0F4DB" />
              <path
                d="M107.05 58.78V63.87H95.9598V58.78H107.05ZM109.01 56.82H94.0098V65.82H109.01V56.82Z"
                fill="white" />
              <path d="M33.9902 56.83H18.9902V65.83H33.9902V56.83Z"
                fill="#D0F4DB" />
              <path
                d="M32.04 58.78V63.87H20.95V58.78H32.04ZM34 56.82H19V65.82H34V56.82Z"
                fill="white" />
              <path d="M33.9902 68.83H18.9902V83.81H33.9902V68.83Z"
                fill="#D0F4DB" />
              <path
                d="M32.04 70.78V81.85H20.95V70.78H32.04ZM34 68.82H19V83.8H34V68.82Z"
                fill="white" />
              <path d="M33.9902 86.81H18.9902V92.84H33.9902V86.81Z"
                fill="white" />
              <path
                d="M32.04 88.76V90.88H20.95V88.76H32.04ZM34 86.8H19V92.83H34V86.8Z"
                fill="white" />
              <path d="M33.9902 95.84H18.9902V101.84H33.9902V95.84Z"
                fill="white" />
              <path
                d="M32.04 97.79V99.88H20.95V97.79H32.04ZM34 95.83H19V101.83H34V95.83Z"
                fill="white" />
              <path d="M33.9902 104.84H18.9902V110.84H33.9902V104.84Z"
                fill="white" />
              <path
                d="M32.04 106.79V108.88H20.95V106.79H32.04ZM34 104.83H19V110.83H34V104.83Z"
                fill="white" />
              <path
                d="M57.9702 82.33H69.9702C70.8002 82.33 71.4702 81.66 71.4702 80.83V71.83C71.4702 71 70.8002 70.33 69.9702 70.33H57.9702C57.1402 70.33 56.4702 71 56.4702 71.83V80.83C56.4702 81.66 57.1402 82.33 57.9702 82.33ZM59.4702 73.33H68.4702V79.33H59.4702V73.33Z"
                fill="#D0F4DB" />
              <path
                d="M69.98 83.3H57.98C56.61 83.3 55.5 82.19 55.5 80.82V71.82C55.5 70.45 56.61 69.34 57.98 69.34H69.98C71.35 69.34 72.46 70.45 72.46 71.82V80.82C72.46 82.19 71.35 83.3 69.98 83.3ZM57.98 71.3C57.69 71.3 57.46 71.53 57.46 71.82V80.82C57.46 81.11 57.69 81.34 57.98 81.34H69.98C70.27 81.34 70.5 81.11 70.5 80.82V71.82C70.5 71.53 70.27 71.3 69.98 71.3H57.98ZM69.46 80.3H58.5V72.35H69.46V80.3ZM60.46 78.34H67.51V74.3H60.46V78.34Z"
                fill="white" />
              <path d="M74.1002 67.59H53.6802V84.43H74.1002V67.59Z"
                fill="white" />
              <path
                d="M17.49 113.84H110.51C111.34 113.84 112.01 113.17 112.01 112.34V55.33C112.01 55.33 112 55.28 111.99 55.25C111.99 55.15 111.96 55.05 111.93 54.95C111.9 54.86 111.89 54.77 111.85 54.69C111.81 54.61 111.76 54.54 111.7 54.47C111.64 54.38 111.58 54.3 111.49 54.22C111.47 54.2 111.46 54.18 111.44 54.16L103.94 48.15C103.89 48.11 103.83 48.1 103.77 48.06C103.68 48 103.59 47.95 103.49 47.92C103.39 47.89 103.29 47.87 103.19 47.86C103.13 47.86 103.07 47.82 103 47.82H25C24.94 47.82 24.88 47.85 24.82 47.86C24.72 47.87 24.61 47.89 24.51 47.92C24.41 47.96 24.32 48.01 24.23 48.06C24.18 48.09 24.11 48.11 24.06 48.15L16.56 54.16C16.56 54.16 16.53 54.2 16.51 54.22C16.43 54.29 16.37 54.38 16.3 54.47C16.25 54.54 16.19 54.61 16.15 54.69C16.11 54.77 16.09 54.86 16.07 54.95C16.04 55.05 16.02 55.15 16.01 55.25C16.01 55.28 16 55.3 16 55.33V112.34C16 113.17 16.67 113.84 17.5 113.84H17.49ZM51.97 110.84H36.99V104.84H51.97V110.84ZM51.97 101.84H36.99V95.84H51.97V101.84ZM51.97 92.84H36.99V86.81H51.97V92.84ZM51.97 83.81H36.99V68.83H51.97V83.81ZM51.97 65.83H36.99V56.83H51.97V65.83ZM72.97 110.84H54.97V95.84H72.97V110.84ZM72.97 92.84H54.97V86.81H72.97V92.84ZM72.97 83.81H54.97V68.83H72.97V83.81ZM72.97 65.83H54.97V56.83H72.97V65.83ZM91 110.84H75.97V104.84H91V110.84ZM91 101.84H75.97V95.84H91V101.84ZM91 92.84H75.97V86.81H91V92.84ZM91 83.81H75.97V68.83H91V83.81ZM91 65.83H75.97V56.83H91V65.83ZM109 110.84H94V104.84H109V110.84ZM109 101.84H94V95.84H109V101.84ZM109 92.84H94V86.81H109V92.84ZM109 83.81H94V68.83H109V83.81ZM109 65.83H94V56.83H109V65.83ZM25.51 50.82H102.47L106.23 53.83H21.77L25.53 50.82H25.51ZM18.98 56.83H33.98V65.83H18.98V56.83ZM18.98 68.83H33.98V83.81H18.98V68.83ZM18.98 86.81H33.98V92.84H18.98V86.81ZM18.98 95.84H33.98V101.84H18.98V95.84ZM18.98 104.84H33.98V110.84H18.98V104.84Z"
                fill="#0A863C" />
              <path
                d="M57.9702 82.33H69.9702C70.8002 82.33 71.4702 81.66 71.4702 80.83V71.83C71.4702 71 70.8002 70.33 69.9702 70.33H57.9702C57.1402 70.33 56.4702 71 56.4702 71.83V80.83C56.4702 81.66 57.1402 82.33 57.9702 82.33ZM59.4702 73.33H68.4702V79.33H59.4702V73.33Z"
                fill="#0A863C" />
              <path
                d="M46.7601 66.16C60.2634 66.16 71.2101 55.2134 71.2101 41.71C71.2101 28.2066 60.2634 17.26 46.7601 17.26C33.2567 17.26 22.3101 28.2066 22.3101 41.71C22.3101 55.2134 33.2567 66.16 46.7601 66.16Z"
                fill="#FFCB04" />
              <path
                d="M46.7602 17.27C60.2602 17.27 71.2102 28.22 71.2102 41.72C71.2102 55.22 60.2602 66.17 46.7602 66.17C33.2602 66.17 22.3102 55.22 22.3102 41.72C22.3102 28.22 33.2602 17.27 46.7602 17.27ZM46.7602 14.45C31.7202 14.45 19.4902 26.68 19.4902 41.72C19.4902 56.76 31.7202 68.99 46.7602 68.99C61.8002 68.99 74.0302 56.76 74.0302 41.72C74.0302 26.68 61.8002 14.45 46.7602 14.45Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M41.4 42.17C41.4 42.17 39.62 42.85 37.43 42.85C35.24 42.85 33.46 42.17 33.46 42.17C33.46 42.17 35.24 41.49 37.43 41.49C39.62 41.49 41.4 42.17 41.4 42.17Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M46.9 32.13C46.9 32.13 45.32 31.08 44.1 29.3C42.87 27.53 42.46 25.71 42.46 25.71C42.46 25.71 44.04 26.76 45.26 28.54C46.49 30.31 46.9 32.13 46.9 32.13Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M42.8701 36.46C42.8701 36.46 40.9701 36.25 39.0301 35.25C37.0901 34.25 35.8501 32.83 35.8501 32.83C35.8501 32.83 37.7501 33.04 39.6901 34.04C41.6301 35.04 42.8701 36.46 42.8701 36.46Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M46.7102 45.46C47.8402 45.45 49.2002 44.57 49.2002 44.57C49.2002 44.57 46.0502 43.62 46.2402 40.81C46.8602 36.2 50.6202 32.58 55.3302 31.99C54.8302 31.92 54.3202 31.89 53.8002 31.89C47.9802 31.92 43.2802 36.53 43.3102 42.2C43.3402 47.87 48.0802 52.44 53.9002 52.42C54.3902 52.42 54.8602 52.38 55.3302 52.32C51.2002 51.81 47.8402 48.97 46.6302 45.22"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M46.9 52.18C46.9 52.18 45.32 53.23 44.1 55.01C42.87 56.78 42.46 58.6 42.46 58.6C42.46 58.6 44.04 57.55 45.26 55.77C46.49 54 46.9 52.18 46.9 52.18Z"
                fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M42.8701 47.85C42.8701 47.85 40.9701 48.06 39.0301 49.06C37.0901 50.06 35.8501 51.48 35.8501 51.48C35.8501 51.48 37.7501 51.27 39.6901 50.27C41.6301 49.27 42.8701 47.85 42.8701 47.85Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_1575">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">{{
          $t('buttons.transport_inpost')
        }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "TransportInpost",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>