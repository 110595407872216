<template>

  <div id="pedometer-menu">
    {{step}}
    <v-btn-toggle
        rounded="xl" variant="outlined"
        v-model="toggle" class="elevation-4"
        color="primary" dense
      mandatory
    >
      <v-btn outlined :value="1">{{ $t('step_1')}}</v-btn>
      <v-btn outlined :value="2">{{ $t('step_2')}}</v-btn>
      <v-btn outlined :value="3">{{ $t('step_3')}}</v-btn>
      <v-btn outlined :value="4">{{ $t('step_4')}}</v-btn>
      <v-btn outlined :value="5">{{ $t('step_5')}}</v-btn>
      <v-btn outlined :value="6">{{ $t('step_6')}}</v-btn>
      <v-btn outlined :value="7">{{ $t('step_7')}}</v-btn>
      <v-btn outlined :value="8">{{ $t('step_8')}}</v-btn>
    </v-btn-toggle>

  </div>

</template>

<script>
export default {
  name: "PedometerMenu",
  props:{
    step:{
      type:Number,
      reuqired:true
    }
  },
  data() {
    return {
      toggle: 1,
    }
  },
}
</script>

<style scoped>

</style>