<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_1121)">
            <path
              d="M91.1301 60.89L92.4301 61.54L93.0801 62.84C93.1701 63.03 93.3601 63.14 93.5701 63.14C93.7801 63.14 93.9701 63.02 94.0601 62.84L94.7101 61.54L96.0101 60.89C96.2001 60.8 96.3101 60.61 96.3101 60.4C96.3101 60.19 96.1901 60 96.0101 59.91L94.7101 59.26L94.0601 57.96C93.8701 57.59 93.2601 57.59 93.0701 57.96L92.4201 59.26L91.1201 59.91C90.9301 60 90.8201 60.19 90.8201 60.4C90.8201 60.61 90.9401 60.8 91.1201 60.89H91.1301Z"
              fill="white" />
            <path
              d="M99.1499 57.1L100.07 57.56L100.53 58.48C100.6 58.61 100.73 58.7 100.88 58.7C101.03 58.7 101.16 58.62 101.23 58.48L101.69 57.56L102.61 57.1C102.74 57.03 102.83 56.9 102.83 56.75C102.83 56.6 102.75 56.47 102.61 56.4L101.69 55.94L101.23 55.02C101.1 54.76 100.66 54.76 100.53 55.02L100.07 55.94L99.1499 56.4C99.0199 56.47 98.9299 56.6 98.9299 56.75C98.9299 56.9 99.0099 57.03 99.1499 57.1Z"
              fill="white" />
            <path
              d="M77.7101 91.37C99.0338 91.37 116.32 74.0837 116.32 52.76C116.32 31.4363 99.0338 14.15 77.7101 14.15C56.3864 14.15 39.1001 31.4363 39.1001 52.76C39.1001 74.0837 56.3864 91.37 77.7101 91.37Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M49.0999 22.65C66.8999 22.65 81.3199 37.08 81.3199 54.87C81.3199 72.66 66.8899 87.09 49.0999 87.09C31.3099 87.09 16.8799 72.66 16.8799 54.87C16.8799 37.08 31.2999 22.65 49.0999 22.65ZM49.0999 19.65C29.6799 19.65 13.8799 35.45 13.8799 54.87C13.8799 74.29 29.6799 90.09 49.0999 90.09C68.5199 90.09 84.3199 74.29 84.3199 54.87C84.3199 35.45 68.5199 19.65 49.0999 19.65Z"
                fill="#0A863C" />
              <path
                d="M49.0999 87.09C66.8945 87.09 81.3199 72.6646 81.3199 54.87C81.3199 37.0754 66.8945 22.65 49.0999 22.65C31.3053 22.65 16.8799 37.0754 16.8799 54.87C16.8799 72.6646 31.3053 87.09 49.0999 87.09Z"
                fill="#D0F4DB" />
              <path
                d="M49.0999 24.65C65.7599 24.65 79.3199 38.21 79.3199 54.87C79.3199 71.53 65.7599 85.09 49.0999 85.09C32.4399 85.09 18.8799 71.53 18.8799 54.87C18.8799 38.21 32.4399 24.65 49.0999 24.65ZM49.0999 22.65C31.2999 22.65 16.8799 37.08 16.8799 54.87C16.8799 72.66 31.3099 87.09 49.0999 87.09C66.8899 87.09 81.3199 72.66 81.3199 54.87C81.3199 37.08 66.8899 22.65 49.0999 22.65Z"
                fill="white" />
              <path
                d="M32.47 57.1C31.92 51.23 34.43 45.91 38.59 42.53C40.21 41.21 42.64 42.34 42.64 44.42C42.64 45.19 42.28 45.89 41.69 46.37C38.74 48.77 36.97 52.56 37.4 56.74C37.96 62.19 42.32 66.6 47.76 67.2C54.86 68 60.89 62.44 60.89 55.5C60.89 51.82 59.19 48.52 56.53 46.36C55.94 45.88 55.58 45.18 55.58 44.42C55.58 42.36 57.97 41.19 59.57 42.48C63.37 45.55 65.81 50.24 65.81 55.49C65.81 65.1 57.64 72.85 47.88 72.15C39.8 71.58 33.22 65.15 32.47 57.09V57.1Z"
                fill="#0A863C" />
              <path
                d="M49.17 35.53C47.81 35.53 46.71 36.64 46.71 37.99V49.33C46.71 50.69 47.82 51.79 49.17 51.79C50.52 51.79 51.63 50.68 51.63 49.33V37.99C51.63 36.63 50.53 35.53 49.17 35.53Z"
                fill="#0A863C" />
              <path
                d="M90.5201 110.5C104.023 110.5 114.97 99.5534 114.97 86.05C114.97 72.5466 104.023 61.6 90.5201 61.6C77.0167 61.6 66.0701 72.5466 66.0701 86.05C66.0701 99.5534 77.0167 110.5 90.5201 110.5Z"
                fill="#E28888" />
              <path
                d="M90.5201 111C76.7601 111 65.5701 99.81 65.5701 86.05C65.5701 72.29 76.7601 61.1 90.5201 61.1C104.28 61.1 115.47 72.29 115.47 86.05C115.47 99.81 104.28 111 90.5201 111ZM90.5201 62.1C77.3101 62.1 66.5701 72.84 66.5701 86.05C66.5701 99.26 77.3101 110 90.5201 110C103.73 110 114.47 99.26 114.47 86.05C114.47 72.84 103.73 62.1 90.5201 62.1Z"
                fill="white" />
              <path
                d="M90.52 61.6C104.02 61.6 114.97 72.55 114.97 86.05C114.97 99.55 104.02 110.5 90.52 110.5C77.02 110.5 66.07 99.55 66.07 86.05C66.07 72.55 77.02 61.6 90.52 61.6ZM90.52 58.78C75.48 58.78 63.25 71.01 63.25 86.05C63.25 101.09 75.48 113.32 90.52 113.32C105.56 113.32 117.79 101.09 117.79 86.05C117.79 71.01 105.56 58.78 90.52 58.78Z"
                fill="white" />
              <path
                d="M100.02 97.65C99.48 97.65 98.94 97.44 98.53 97.03L79.53 78.03C78.71 77.21 78.71 75.88 79.53 75.06C80.35 74.24 81.68 74.24 82.51 75.06L101.51 94.06C102.33 94.88 102.33 96.21 101.51 97.03C101.1 97.44 100.56 97.65 100.02 97.65Z"
                fill="white" />
              <path
                d="M81.02 97.65C80.48 97.65 79.94 97.44 79.53 97.03C78.71 96.21 78.71 94.88 79.53 94.06L98.53 75.06C99.35 74.24 100.68 74.24 101.51 75.06C102.33 75.88 102.33 77.21 101.51 78.03L82.51 97.03C82.1 97.44 81.56 97.65 81.02 97.65Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_1121">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>



      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium"
          :style="[cp_font_size]">{{ $t('buttons.eco_fault_not_turn_on') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "EcoFaultNotTurnOn",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>