<template>
  <div id="maker-logo" >
    <img v-if="maker_logo!==null" :src="maker_logo" alt="Device Maker Logo"  height="30">
  </div>
</template>

<script>
import {useDisplay} from "vuetify";
import {computed} from "vue";
import {mapGetters} from "vuex";

export default {
  name: "MakerLogo",
  setup() {
    const {name} = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs':
          return 42
        default:
          return 41
      }
    })
    return {
      height
    }
  },
  computed: {
    ...mapGetters('Maker', {makers: 'makers'}),
    ...mapGetters('ClaimForm', {claim_form: 'claim_form'}),
    maker_logo() {
      if (this.claim_form.maker && this.claim_form.maker.with_logo) {
        return this.claim_form.maker.logo_url
      } else {
        return null
      }
    }
  },
}
</script>

<style scoped>

</style>