<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_674)">
            <path
              d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M29.29 31.66H31.49C32.36 31.66 33.05 32.36 33.05 33.22V42.03C33.05 42.9 32.35 43.59 31.49 43.59H29.29C28.42 43.59 27.73 42.89 27.73 42.03V33.22C27.73 32.35 28.43 31.66 29.29 31.66Z"
                fill="#0A863C" />
              <path
                d="M29.29 47.07H31.49C32.36 47.07 33.05 47.77 33.05 48.63V53.03C33.05 53.9 32.35 54.59 31.49 54.59H29.29C28.42 54.59 27.73 53.89 27.73 53.03V48.63C27.73 47.76 28.43 47.07 29.29 47.07Z"
                fill="#0A863C" />
              <path
                d="M82.7 114.05H37.97C33.65 114.05 30.15 110.55 30.15 106.23V21.87C30.15 17.55 33.65 14.05 37.97 14.05H82.7C87.02 14.05 90.52 17.55 90.52 21.87V106.24C90.52 110.56 87.02 114.06 82.7 114.06V114.05ZM37.97 17.17C36.73 17.17 35.54 17.67 34.66 18.54C33.78 19.42 33.28 20.61 33.28 21.86V106.23C33.28 107.47 33.77 108.67 34.66 109.55C35.54 110.43 36.73 110.92 37.97 110.92H82.7C85.29 110.92 87.39 108.82 87.39 106.23V21.87C87.39 19.28 85.29 17.18 82.7 17.18H37.97V17.17Z"
                fill="#0A863C" />
              <path
                d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
                fill="#D0F4DB" />
              <mask id="mask0_2_674" style="mask-type:luminance"
                maskUnits="userSpaceOnUse" x="34" y="18" width="52" height="92">
                <path
                  d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
                  fill="white" />
              </mask>
              <g mask="url(#mask0_2_674)">
                <path
                  d="M100.6 60.43L91.4201 54.73L91.1801 54.58L90.6601 54.53L88.9001 54.37L93.8701 45.16L85.7901 54.08L79.2201 53.48L76.5501 51.86L72.6101 49.1L79.6101 39.33L80.0701 38.69L80.0301 38.22L79.4101 30.28L90.8001 17.39L77.7701 29.36L77.59 29.54L77.5401 29.95L76.5301 37.33L67.7901 46.32L61.6901 49.27L58.8301 46.15L56.9101 47.9L56.2401 40.88L58.4501 28.5L58.6001 27.68L58.1801 27.23L47.2001 16.55L55.3101 28.38L51.8701 40.27L51.8401 40.38L51.8101 40.81L51.2901 49.25L38.1901 42.92L37.7001 42.69H37.4601L21.2801 42.88L36.5501 46.18L45.9501 52.53L44.5001 61.64L18.9301 79.31L45.5301 66.82L50.1801 67.11L48.8001 74.34L38.2401 77.47L48.2901 77L44.7201 95.65L53.9301 72.13L54.6701 74.8L62.0001 72.77L76.8901 117.22L70.6001 84.86L75.7301 75.35L69.9501 81.73L68.2101 73.42L75.3301 74.89L100.72 97.42L77.9901 69.72L75.6201 61.16L84.7701 58.27L90.2901 58.13L100.28 62.25L100.9 62.51L101.26 62.12L108.03 54.63L100.62 60.44L100.6 60.43ZM63.4701 66.59L57.9901 68.94L56.2601 62.71L50.8501 62.37L50.5701 59.92L48.2401 57.19L52.5501 50.8L56.5701 53.75L60.3201 51.45L66.1201 54.35L68.7401 50.37L73.8701 57.04L69.6001 60.84L71.5601 70.21L63.4601 66.61L63.4701 66.59Z"
                  fill="#0A863C" />
              </g>
              <path
                d="M89.0199 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0199 39.6C75.5166 39.6 64.5699 50.5466 64.5699 64.05C64.5699 77.5534 75.5166 88.5 89.0199 88.5Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                fill="white" />
              <path
                d="M89.02 71C87.15 71 85.63 72.52 85.63 74.39C85.8 78.88 92.23 78.88 92.4 74.39C92.4 72.52 90.88 71 89.01 71H89.02Z"
                fill="white" />
              <path
                d="M92.41 51.91C90.73 49.83 87.32 49.82 85.64 51.91C84.8 52.91 84.44 54.23 84.67 55.52C85.34 59.36 86.29 64.79 86.72 67.23C87.2 69.79 90.85 69.79 91.33 67.23L93.38 55.52C93.61 54.23 93.25 52.92 92.41 51.91Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_674">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium"
          :style="[cp_font_size]">{{ $t('buttons.phone_fault_broken_display') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "PhoneFaultBrokenDisplay",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>