<template>
      <div>

            <v-row>
                  <v-col class="pb-0">
                        <svg @mouseenter="hovered = true"
                              @mouseleave="hovered = false" :width="width"
                              :height="height" viewBox="0 0 128 128" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_2_797)">
                                    <path d="M50.76 105.01C72.0837 105.01 89.37 87.7237 89.37 66.4C89.37 45.0763 72.0837 27.79 50.76 27.79C29.4363 27.79 12.15 45.0763 12.15 66.4C12.15 87.7237 29.4363 105.01 50.76 105.01Z"
                                          :fill="c_base_color" />
                                    <g :class="{ hovered: hovered }">
                                          <path d="M85.04 44.29L82.89 44.77C82.04 44.96 81.52 45.79 81.7 46.64L82.66 50.94C82.85 51.79 83.68 52.31 84.53 52.13L86.68 51.65C87.53 51.46 88.05 50.63 87.87 49.78L86.91 45.48C86.72 44.63 85.89 44.11 85.04 44.29Z"
                                                fill="#0A863C" />
                                          <path d="M92.45 102.9L74.27 21.2C73.85 19.3 71.97 18.11 70.07 18.53L27.08 28.09C25.18 28.51 23.99 30.39 24.41 32.29L40.27 103.59C41.14 102.19 41.74 101.21 41.74 101.21L44.41 108.88L52.91 105.55L49.24 113.55L54.57 114.72L54.39 114.98L89.77 107.11C91.67 106.69 92.86 104.81 92.44 102.91L92.45 102.9Z"
                                                fill="#0A863C" />
                                          <path d="M81.69 29.24L79.54 29.72C78.69 29.91 78.17 30.74 78.35 31.59L80.26 40.19C80.45 41.04 81.28 41.56 82.13 41.38L84.28 40.9C85.13 40.71 85.65 39.88 85.47 39.03L83.56 30.43C83.37 29.58 82.54 29.06 81.69 29.24Z"
                                                fill="#0A863C" />
                                          <path d="M97.05 102.22L78.73 19.87C77.79 15.66 73.62 13 69.4 13.94L25.74 23.65C21.53 24.59 18.87 28.76 19.81 32.98L36.77 109.21C37.54 107.98 38.38 106.65 39.13 105.43L22.85 32.3C22.29 29.77 23.88 27.27 26.41 26.7L70.07 16.99C71.28 16.72 72.55 16.95 73.6 17.61C74.65 18.27 75.4 19.33 75.66 20.55L93.98 102.9C94.25 104.11 94.03 105.39 93.36 106.44C92.69 107.49 91.63 108.23 90.42 108.5L53.16 116.79L50.63 120.56L91.11 111.55C95.32 110.61 97.98 106.44 97.04 102.22H97.05Z"
                                                fill="#0A863C" />
                                          <path d="M34.58 39.35C37.0487 39.35 39.05 37.3487 39.05 34.88C39.05 32.4113 37.0487 30.41 34.58 30.41C32.1113 30.41 30.11 32.4113 30.11 34.88C30.11 37.3487 32.1113 39.35 34.58 39.35Z"
                                                fill="#D0F4DB" />
                                          <path d="M36.99 50.18C39.4587 50.18 41.46 48.1787 41.46 45.71C41.46 43.2413 39.4587 41.24 36.99 41.24C34.5213 41.24 32.52 43.2413 32.52 45.71C32.52 48.1787 34.5213 50.18 36.99 50.18Z"
                                                fill="#D0F4DB" />
                                          <path d="M89.47 91.39C102.973 91.39 113.92 80.4434 113.92 66.94C113.92 53.4366 102.973 42.49 89.47 42.49C75.9667 42.49 65.02 53.4366 65.02 66.94C65.02 80.4434 75.9667 91.39 89.47 91.39Z"
                                                fill="#0A863C" />
                                          <path d="M89.47 42.49C102.97 42.49 113.92 53.44 113.92 66.94C113.92 80.44 102.97 91.39 89.47 91.39C75.97 91.39 65.0199 80.44 65.0199 66.94C65.0199 53.44 75.97 42.49 89.47 42.49ZM89.47 39.67C74.43 39.67 62.2 51.9 62.2 66.94C62.2 81.98 74.43 94.21 89.47 94.21C104.51 94.21 116.74 81.98 116.74 66.94C116.74 51.9 104.51 39.67 89.47 39.67Z"
                                                fill="white" />
                                          <path d="M89.47 73.89C87.6 73.89 86.08 75.41 86.08 77.28C86.25 81.77 92.68 81.77 92.85 77.28C92.85 75.41 91.33 73.89 89.46 73.89H89.47Z"
                                                fill="white" />
                                          <path d="M92.85 54.8C91.17 52.72 87.76 52.71 86.08 54.8C85.24 55.8 84.88 57.12 85.11 58.41C85.78 62.25 86.73 67.68 87.16 70.12C87.64 72.68 91.2899 72.68 91.7699 70.12L93.82 58.41C94.05 57.12 93.69 55.81 92.85 54.8Z"
                                                fill="white" />
                                          <path d="M18.96 99.01C18.13 99.01 17.46 98.34 17.46 97.51V76.49C17.46 75.66 18.13 74.99 18.96 74.99C19.79 74.99 20.46 75.66 20.46 76.49V97.51C20.46 98.34 19.79 99.01 18.96 99.01Z"
                                                fill="#E8EEEA" />
                                          <path d="M24.52 104.88C23.69 104.88 23.02 104.21 23.02 103.38V82.36C23.02 81.53 23.69 80.86 24.52 80.86C25.35 80.86 26.02 81.53 26.02 82.36V103.38C26.02 104.21 25.35 104.88 24.52 104.88Z"
                                                fill="#E8EEEA" />
                                          <path d="M87.96 29.51C87.13 29.51 86.46 28.84 86.46 28.01V6.98999C86.46 6.15999 87.13 5.48999 87.96 5.48999C88.79 5.48999 89.46 6.15999 89.46 6.98999V28.01C89.46 28.84 88.79 29.51 87.96 29.51Z"
                                                fill="#E8EEEA" />
                                          <path d="M93.52 35.38C92.69 35.38 92.02 34.71 92.02 33.88V12.86C92.02 12.03 92.69 11.36 93.52 11.36C94.35 11.36 95.02 12.03 95.02 12.86V33.88C95.02 34.71 94.35 35.38 93.52 35.38Z"
                                                fill="#E8EEEA" />
                                          <path d="M59.48 101.72L57.1 110.34L65.92 107.19L59.48 101.72Z"
                                                fill="white" />
                                          <path d="M38.48 116.25L41.1 124.8L46.85 117.41L38.48 116.25Z"
                                                fill="#0A863C" />
                                          <path d="M29.66 105.64L24.03 112.59L33.37 113.23L29.66 105.64Z"
                                                fill="#0A863C" />
                                    </g>
                              </g>
                              <defs>
                                    <clipPath id="clip0_2_797">
                                          <rect width="128" height="128"
                                                fill="white" />
                                    </clipPath>
                              </defs>
                        </svg>
                  </v-col>
            </v-row>

            <v-row>
                  <v-col class="pt-5">
                        <span class="font-weight-medium" :style="[cp_font_size]">
                              {{ $t('buttons.phone_visual_condition_broken_case') }}</span>
                  </v-col>
            </v-row>

      </div>
</template>

<script>

export default {
      name: "PhoneVisualConditionBrokenCase",
      props: {
            selected: { type: Boolean, required: false },
            height: { type: Number, required: 200 },
            width: { type: Number, required: 180 },
            font_size: { type: String, required: '15px' },

            base_color: { type: String, required: true },
            base_hovered_color: { type: String, required: true },
            base_selected_color: { type: String, required: true },

            base_border_color: { type: String, required: true },
            base_border_hovered_color: { type: String, required: true },
            base_border_selected_color: { type: String, required: true },

      },
      data() {
            return {
                  hovered: false
            }
      },
      computed: {
            cp_font_size() {
                  return `font-size: ${this.font_size}`
            },
            c_base_color() {
                  let color = this.base_color
                  if (this.selected) {
                        color = this.base_selected_color
                  } else {
                        if (this.hovered) color = this.base_hovered_color
                  }
                  return color
            },
            c_base_border_color() {
                  let color = this.base_border_color
                  if (this.selected) {
                        color = this.base_border_selected_color
                  } else {
                        if (this.hovered) color = this.base_border_hovered_color
                  }
                  return color
            }
      }
}
</script>

<style scoped></style>