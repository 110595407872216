<template>

  <div id="app-info-dialog">
    <v-dialog v-model="show" @click:outside="resetState()" max-width="500">
      <v-card>
        <v-card-text class="text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="resetState()">Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AppInfoDialog",

  computed: {
    ...mapGetters('AppInfoDialog', {show: 'show', message: 'message'}),
  },

  methods: {
    ...mapActions('AppInfoDialog', {resetState: 'resetState'}),
  }
}
</script>

<style scoped>

</style>