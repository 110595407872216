<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_694)">
            <path
              d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M29.29 31.66H31.49C32.36 31.66 33.05 32.36 33.05 33.22V42.03C33.05 42.9 32.35 43.59 31.49 43.59H29.29C28.42 43.59 27.73 42.89 27.73 42.03V33.22C27.73 32.35 28.43 31.66 29.29 31.66Z"
                fill="#0A863C" />
              <path
                d="M29.29 47.07H31.49C32.36 47.07 33.05 47.77 33.05 48.63V53.03C33.05 53.9 32.35 54.59 31.49 54.59H29.29C28.42 54.59 27.73 53.89 27.73 53.03V48.63C27.73 47.76 28.43 47.07 29.29 47.07Z"
                fill="#0A863C" />
              <path
                d="M82.7 114.05H37.97C33.65 114.05 30.15 110.55 30.15 106.23V21.87C30.15 17.55 33.65 14.05 37.97 14.05H82.7C87.02 14.05 90.52 17.55 90.52 21.87V106.24C90.52 110.56 87.02 114.06 82.7 114.06V114.05ZM37.97 17.17C36.73 17.17 35.54 17.67 34.66 18.54C33.78 19.42 33.28 20.61 33.28 21.86V106.23C33.28 107.47 33.77 108.67 34.66 109.55C35.54 110.43 36.73 110.92 37.97 110.92H82.7C85.29 110.92 87.39 108.82 87.39 106.23V21.87C87.39 19.28 85.29 17.18 82.7 17.18H37.97V17.17Z"
                fill="#0A863C" />
              <path
                d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
                fill="#D0F4DB" />
              <path
                d="M68.25 92.35C68.13 92.09 67.86 91.92 67.58 91.92L61.16 91.96L61.12 91.92L62 85.79C62.05 85.46 61.87 85.13 61.56 85C61.47 84.96 61.36 84.94 61.26 84.94C61.04 84.94 60.82 85.04 60.68 85.22L52.47 95.46C52.29 95.68 52.26 95.99 52.38 96.25C52.5 96.51 52.76 96.67 53.05 96.67H58.84L58.88 96.72L57.4 103.83C57.33 104.16 57.5 104.5 57.8 104.65C58.11 104.8 58.48 104.72 58.7 104.46L68.16 93.16C68.35 92.94 68.39 92.63 68.26 92.37L68.25 92.35Z"
                fill="#0A863C" />
              <path
                d="M89.0199 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0199 39.6C75.5166 39.6 64.5699 50.5466 64.5699 64.05C64.5699 77.5534 75.5166 88.5 89.0199 88.5Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                fill="white" />
              <path
                d="M80.34 74.62C79.81 74.65 79.3 74.48 78.9 74.13C78.11 73.34 78.11 72.06 78.9 71.27L96.13 54.04C96.95 53.27 98.23 53.32 99 54.14C99.69 54.88 99.73 56.02 99.09 56.8L81.76 74.13C81.37 74.47 80.86 74.64 80.34 74.62Z"
                fill="white" />
              <path
                d="M97.5499 74.62C97.0199 74.62 96.5099 74.41 96.1299 74.03L78.8999 56.8C78.1699 55.95 78.2699 54.67 79.1199 53.94C79.8799 53.29 80.9999 53.29 81.7599 53.94L99.0899 71.17C99.9099 71.94 99.9499 73.22 99.1799 74.04C99.1499 74.07 99.1199 74.1 99.0899 74.13C98.6699 74.5 98.1099 74.68 97.5499 74.62Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_694">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>


      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium"
          :style="[cp_font_size]">{{ $t('buttons.phone_fault_not_charging') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "PhoneFaultNotCharging",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>