<template>
      <div>

            <v-row>
                  <v-col class="pb-0">
                        <svg @mouseenter="hovered = true"
                              @mouseleave="hovered = false" :width="width"
                              :height="height" viewBox="0 0 128 128" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_2_757)">
                                    <path d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
                                    :fill="c_base_color" />
                                    <g :class="{ hovered: hovered }">
                                          <path d="M88.9499 31.66H86.7499C85.8799 31.66 85.1899 32.36 85.1899 33.22V42.03C85.1899 42.9 85.8899 43.59 86.7499 43.59H88.9499C89.8199 43.59 90.5099 42.89 90.5099 42.03V33.22C90.5099 32.35 89.8099 31.66 88.9499 31.66Z"
                                                fill="#0A863C" />
                                          <path d="M88.9499 47.07H86.7499C85.8799 47.07 85.1899 47.77 85.1899 48.63V53.03C85.1899 53.9 85.8899 54.59 86.7499 54.59H88.9499C89.8199 54.59 90.5099 53.89 90.5099 53.03V48.63C90.5099 47.76 89.8099 47.07 88.9499 47.07Z"
                                                fill="#0A863C" />
                                          <path d="M33.15 100.38L32.37 100.22V105.9C32.37 107.84 33.95 109.42 35.89 109.42H43.34L33.16 100.38H33.15Z"
                                                fill="#0A863C" />
                                          <path d="M79.93 18.67H35.89C33.95 18.67 32.37 20.25 32.37 22.19V72.16L34.11 70.37L34.92 64.47L34.96 64.14L35.1 63.99L45.51 54.42L36.41 64.72L36.91 71.07L36.94 71.44L36.57 71.95L32.36 77.82V80.73L34.12 81.96L36.26 83.26L41.51 83.74L47.97 76.61L44 83.97L45.41 84.1L45.83 84.14L46.02 84.26L53.36 88.82L59.29 84.18L53.88 90.16L53.59 90.48L53.09 90.27L45.11 86.97L40.7 87.08L33.39 89.39L35.28 96.23L46.09 109.4H79.94C81.88 109.4 83.46 107.82 83.46 105.88V22.19C83.46 20.25 81.88 18.67 79.94 18.67H79.93Z"
                                                fill="#0A863C" />
                                          <path d="M80.27 14.05H35.54C31.22 14.05 27.72 17.55 27.72 21.87V76.94L30.85 73.72V21.87C30.85 19.28 32.95 17.18 35.54 17.18H80.27C81.51 17.18 82.7 17.68 83.59 18.55C84.47 19.43 84.97 20.62 84.97 21.87V106.24C84.97 107.48 84.48 108.68 83.59 109.56C82.71 110.44 81.52 110.93 80.27 110.93H47.31L49.88 114.06H80.27C84.59 114.06 88.09 110.56 88.09 106.24V21.87C88.09 17.55 84.59 14.05 80.27 14.05Z"
                                                fill="#0A863C" />
                                          <path d="M45.03 110.92H35.54C32.95 110.92 30.85 108.82 30.85 106.23V99.91L27.72 99.27V106.24C27.72 110.56 31.22 114.06 35.54 114.06H48.55L45.03 110.93V110.92Z"
                                                fill="#0A863C" />
                                          <path d="M89.0199 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0199 39.6C75.5166 39.6 64.5699 50.5466 64.5699 64.05C64.5699 77.5534 75.5166 88.5 89.0199 88.5Z"
                                                fill="#0A863C" />
                                          <path d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                                                fill="white" />
                                          <path d="M89.02 71C87.15 71 85.63 72.52 85.63 74.39C85.8 78.88 92.23 78.88 92.4 74.39C92.4 72.52 90.88 71 89.01 71H89.02Z"
                                                fill="white" />
                                          <path d="M92.41 51.91C90.73 49.83 87.32 49.82 85.64 51.91C84.8 52.91 84.44 54.23 84.67 55.52C85.34 59.36 86.29 64.79 86.72 67.23C87.2 69.79 90.85 69.79 91.33 67.23L93.38 55.52C93.61 54.23 93.25 52.92 92.41 51.91Z"
                                                fill="white" />
                                          <path d="M41.74 31.4C44.2087 31.4 46.21 29.3987 46.21 26.93C46.21 24.4613 44.2087 22.46 41.74 22.46C39.2713 22.46 37.27 24.4613 37.27 26.93C37.27 29.3987 39.2713 31.4 41.74 31.4Z"
                                                fill="#D0F4DB" />
                                          <path d="M41.74 42.5C44.2087 42.5 46.21 40.4987 46.21 38.03C46.21 35.5613 44.2087 33.56 41.74 33.56C39.2713 33.56 37.27 35.5613 37.27 38.03C37.27 40.4987 39.2713 42.5 41.74 42.5Z"
                                                fill="#D0F4DB" />
                                    </g>
                              </g>
                              <defs>
                                    <clipPath id="clip0_2_757">
                                          <rect width="128" height="128"
                                                fill="white" />
                                    </clipPath>
                              </defs>
                        </svg>
                  </v-col>
            </v-row>

            <v-row>
                  <v-col class="pt-5">
                        <span class="font-weight-medium" :style="[cp_font_size]">
                              {{ $t('buttons.phone_visual_condition_cracked_cover') }}</span>
                  </v-col>
            </v-row>

      </div>
</template>

<script>

export default {
      name: "PhoneVisualConditionCrackedCover",
      props: {
            selected: { type: Boolean, required: false },
            height: { type: Number, required: 200 },
            width: { type: Number, required: 180 },
            font_size: { type: String, required: '15px' },

            base_color: { type: String, required: true },
            base_hovered_color: { type: String, required: true },
            base_selected_color: { type: String, required: true },

            base_border_color: { type: String, required: true },
            base_border_hovered_color: { type: String, required: true },
            base_border_selected_color: { type: String, required: true },

      },
      data() {
            return {
                  hovered: false
            }
      },
      computed: {
            cp_font_size() {
                  return `font-size: ${this.font_size}`
            },
            c_base_color() {
                  let color = this.base_color
                  if (this.selected) {
                        color = this.base_selected_color
                  } else {
                        if (this.hovered) color = this.base_hovered_color
                  }
                  return color
            },
            c_base_border_color() {
                  let color = this.base_border_color
                  if (this.selected) {
                        color = this.base_border_selected_color
                  } else {
                        if (this.hovered) color = this.base_border_hovered_color
                  }
                  return color
            }
      }
}
</script>

<style scoped></style>