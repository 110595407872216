import * as Api from "./Api";

export default {
    index(payload) {
        return Api.client.post(`/deviceModels`, payload);
    },
    getModel(payload) {
        return Api.client.post(`/deviceModel`, payload);
    }
};
