<template>

  <div id="eco-transport">
    <v-container fluid class="pa-15">
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button variant="transport_courier"
                               :selected="claim_form.transport_out === 'transport_courier'"
                               @click="updateClaimForm({ key: 'transport_out', value: 'transport_courier' })"/>
        </v-col>
        <v-col>
          <app-animated-button variant="transport_self_deliver"
                               :selected="claim_form.transport_out === 'transport_self_deliver'"
                               @click="updateClaimForm({ key: 'transport_out', value: 'transport_self_deliver' })"/>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EcoTransportOut",
  computed: {
    ...mapGetters('ClaimForm', {claim_form: 'claim_form'}),
  },
  methods: {
    ...mapActions('ClaimForm', {updateClaimForm: 'updateClaimForm', syncValue: 'syncValue'}),
  },
}
</script>

<style scoped>

.transport-menu {
  padding: 20px;
  background-color: #EBEDED;
  border-radius: 15px;
}

.center-txt {
  text-align: center;
}

.print-label-check {
  border: solid 2px #0c6f5d;
  border-radius: 5px;
  color: #0c6f5d;
  cursor: pointer;
  text-align: justify;
}

.print-label-check-checked {
  background-color: rgba(12, 111, 93, 0.05);
}

.print-label-check-align-justify {
  text-align: justify;
}

.print-label-check-align-center {
  text-align: center;
}

</style>