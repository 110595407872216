<template>

  <v-footer color="white" class="application-footer"
            v-if="$route.name !== 'InpostGeoWidget'">
    <v-row>
      <v-col align="center">
        <table :class="classes.table">
          <tbody>
          <tr>
            <td>
              <b>{{$t('footer.contact')}}</b>
            </td>
            <td>:</td>
            <td>Tel: 22 726 38 10</td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td>Fax: 22 726 38 11</td>
          </tr>
          <tr>
            <td colspan="2"></td>
            <td>
              e-mail:&nbsp;<a href="mailto:info@ccsonline.pl">info@ccsonline.pl</a>
            </td>
          </tr>
          </tbody>
        </table>
      </v-col>
      <v-col align="center">
        <span :style="[`font-size: ${classes.info_size}`, 'color: #c0c0c0']">
          Cyfrowe Centrum Serwisowe S.A. © {{ now_year }}.</span><br>
        <span v-if="!hide_option" :style="[`font-size: ${classes.info_size}`, 'color: #c0c0c0']">{{$t('footer.info_1')}} <a
            href="https://www.ccsonline.pl/CCS_polityka_prywatnosci.pdf" target="_blank">{{$t('footer.privacy_policy')}}</a>
          {{$t('footer.info_2')}} <a href="https://www.ccsonline.pl/images/stories/regulamin.pdf"
                                     target="_blank">{{$t('footer.regulation')}}</a>.</span>
      </v-col>
      <v-col align="center" :hidden="classes.hide_additional_logo">
        <a class="navbar-brand" href="https://www.ccsonline.pl"><img
            src="https://www2.ccsonline.pl/templates/deco_15/images/motto.gif" alt="Zaufaj profesjonalistom"
            border="0"></a>
      </v-col>
    </v-row>
  </v-footer>

</template>

<script>
import {useDisplay} from "vuetify";
import {computed} from "vue";

export default {
  name: "AppFooter",
  setup() {
    const {name} = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs':
          return {
            table: ['table-xs'],
            info_size: '10px',
            hide_additional_logo: true,
          }
        case 'sm':
          return {
            table: ['table-sm'],
            info_size: '14px',
            hide_additional_logo: true,
          }

        default:
          return {
            table: ['table-md'],
            info_size: '14px',
            hide_additional_logo: false,
          }

      }
    })
    return {classes}
  },
  computed: {
    hide_option() {
      /** #2426 - XIAOMI_DE CLIENT */
      return this.$route.query.client
          && (this.$route.query.client.toUpperCase() === "XIAOMI_DE" || this.$route.query.client.toUpperCase() === "XIAOMI_FR");
    },
    now_year() {
      return (new Date()).getFullYear()
    }
  },
}
</script>

<style scoped>

.application-footer {
  border-top: solid 5px #0c6f5d;
}

.table-md {
  font-size: 1rem;
}

.table-sm {
  font-size: 0.8rem;
}

.table-xs {
  font-size: 0.6rem;
}


</style>