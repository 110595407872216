<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_1209)">
            <path
              d="M50.4 102.06C71.7238 102.06 89.01 84.7737 89.01 63.45C89.01 42.1263 71.7238 24.84 50.4 24.84C29.0763 24.84 11.79 42.1263 11.79 63.45C11.79 84.7737 29.0763 102.06 50.4 102.06Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M66.1299 89.14C67.9399 86.72 69.9199 84.38 71.8299 82.12C74.5099 78.96 77.2799 75.68 79.5699 72.18C80.3499 70.98 81.1099 69.72 81.8399 68.5L81.8599 68.47C83.9199 65.03 86.0399 61.47 89.1299 58.76C89.8299 58.15 90.4699 57.5 91.0899 56.87C91.8399 56.12 92.6099 55.34 93.5099 54.59C94.3299 53.91 94.9699 53.26 95.5299 52.53C95.5499 52.51 97.1799 50.43 98.8799 50.54L100.05 50.62L99.6499 51.73C99.2299 52.88 98.43 53.96 97.66 55V55.02C97.3299 55.44 97.0399 55.84 96.7899 56.22L96.6099 56.49C95.1499 58.7 93.6799 60.92 92.2099 63.13C88.0399 69.41 83.7399 75.9 79.6799 82.38L79.5299 82.62C77.3599 86.08 75.1199 89.66 71.7799 92.16C71.3599 92.47 70.9199 92.77 70.4899 93.06C69.4999 93.73 68.5699 94.36 67.8599 95.2C67.0599 96.14 66.5299 97.36 66.0299 98.55L65.9399 98.77C63.7499 103.86 61.1399 108.82 58.1799 113.5C58.0099 113.76 57.7399 113.91 57.4399 113.91C57.3499 113.91 57.2599 113.9 57.1699 113.87C56.7799 113.75 56.5299 113.4 56.5499 112.99C56.8499 105.27 60.0699 97.24 66.1299 89.12V89.14Z"
                fill="#0A863C" />
              <path
                d="M58.71 109.23C60.8 105.64 62.68 101.9 64.31 98.09L64.4 97.87C64.96 96.56 65.54 95.21 66.51 94.07C67.38 93.05 68.45 92.32 69.5 91.62C69.91 91.34 70.33 91.06 70.72 90.77C73.8 88.46 75.96 85.02 78.04 81.7L78.19 81.47C82.26 74.98 86.56 68.49 90.72 62.21L90.75 62.17C92.22 59.96 93.68 57.75 95.14 55.54L95.16 55.51C94.99 55.66 94.82 55.81 94.64 55.96C93.81 56.65 93.1 57.37 92.35 58.13C91.71 58.78 91.04 59.45 90.3 60.1C87.42 62.63 85.36 66.07 83.38 69.39C82.63 70.64 81.86 71.93 81.05 73.17C78.7 76.76 75.89 80.08 73.18 83.28C71.28 85.52 69.33 87.83 67.55 90.21C62.72 96.67 59.76 103.06 58.72 109.24L58.71 109.23Z"
                fill="#D0F4DB" />
              <path
                d="M39.31 107.48C40.2 106.32 40.38 105.12 40.59 103.71C40.73 102.78 40.88 101.82 41.23 100.78C41.55 99.83 42.29 98.9 43 97.99C43.62 97.2 44.25 96.39 44.48 95.73C45.34 93.24 46.6 90.9 47.83 88.75C50.27 84.48 52.96 80.28 55.83 76.29C58.73 72.25 61.89 68.31 64.96 64.49C65.62 63.67 66.3 62.82 67.17 62.11C67.85 61.56 68.59 61.13 69.31 60.72C70 60.32 70.66 59.95 71.23 59.48C72.37 58.54 73.2 57.17 74 55.85C74.16 55.58 74.32 55.32 74.49 55.06C75.8 52.96 77.31 50.98 78.78 49.07L83.55 42.85C84.87 41.13 86.23 39.35 87.74 37.72C90.05 35.23 92.79 32.45 95.69 30.72C96 30.54 96.38 30.56 96.67 30.77C96.96 30.98 97.09 31.34 97.01 31.69C96.81 32.53 96.23 33.23 95.72 33.86C95.59 34.02 95.47 34.17 95.36 34.32L87.89 44.26C86.86 45.62 85.8 47.04 85.07 48.56C84.79 49.14 84.54 49.77 84.31 50.38C83.96 51.28 83.59 52.22 83.09 53.1C82.52 54.09 81.81 54.96 81.13 55.8V55.82C79.13 58.26 77.07 60.78 75.08 63.29C70.38 69.23 65.84 75.44 61.43 81.45L45.83 102.76C44.29 104.86 42.7 107.04 40.59 108.74C40.43 108.87 40.24 108.94 40.03 108.94C39.8 108.94 39.58 108.85 39.41 108.69C39.09 108.38 39.05 107.88 39.32 107.52L39.31 107.48Z"
                fill="#0A863C" />
              <path
                d="M91.87 35.97C90.85 36.95 89.9 37.96 89.04 38.89C87.59 40.46 86.25 42.21 84.96 43.89L80.19 50.11C78.75 51.99 77.26 53.93 76 55.96C75.85 56.2 75.7 56.44 75.56 56.68L75.53 56.73C74.65 58.18 73.74 59.68 72.37 60.81C71.68 61.37 70.93 61.81 70.2 62.22C69.51 62.61 68.87 62.98 68.3 63.45C67.58 64.04 66.95 64.82 66.35 65.57C63.31 69.36 60.16 73.29 57.29 77.29C54.46 81.23 51.8 85.38 49.39 89.6C48.2 91.68 46.99 93.94 46.17 96.29C45.84 97.23 45.11 98.16 44.41 99.06C43.79 99.85 43.15 100.67 42.92 101.34C42.61 102.23 42.48 103.11 42.35 103.97C42.33 104.13 42.3 104.29 42.27 104.46C43.01 103.55 43.71 102.6 44.39 101.67L59.99 80.36C64.4 74.34 68.96 68.12 73.68 62.14C75.68 59.62 77.73 57.1 79.73 54.66L79.75 54.63C80.39 53.85 81.05 53.03 81.55 52.17C81.99 51.4 82.31 50.57 82.66 49.69C82.91 49.05 83.17 48.39 83.48 47.74C84.29 46.05 85.4 44.57 86.48 43.14L91.88 35.95L91.87 35.97Z"
                fill="#D0F4DB" />
              <path
                d="M30.87 85.7C34.79 73.64 43.67 63.16 52.26 53.02C54.54 50.33 56.7 47.78 58.78 45.19L67.89 33.83C68.6 32.95 69.33 32.04 70.24 31.26C70.88 30.72 71.57 30.27 72.24 29.84H72.26C72.5 29.67 72.74 29.52 72.97 29.36C75.05 27.97 76.93 26.19 78.75 24.48C79.13 24.12 79.51 23.76 79.89 23.4C81.01 22.36 82.22 21.26 83.51 20.28C83.61 20.2 83.74 20.1 83.88 19.99H83.9C85.54 18.67 87 17.61 88.16 17.95C88.6 18.08 88.87 18.53 88.77 18.99C88.16 21.79 85.67 24.51 83.67 26.7L83.61 26.77C83.35 27.05 83.1 27.33 82.87 27.59C82.27 28.26 81.65 28.93 81.06 29.58L81.04 29.6C79.39 31.39 77.69 33.25 76.23 35.23C75.15 36.69 74.16 38.28 73.2 39.82C71.99 41.77 70.73 43.79 69.24 45.63C68.2 46.92 67.03 48.12 65.91 49.29C64.21 51.05 62.45 52.88 61.16 54.95C60.72 55.66 60.32 56.42 59.94 57.16L59.6 56.98L59.93 57.18C59.61 57.78 59.29 58.41 58.93 59.02C57.76 61.04 56.28 62.87 54.98 64.48C51 69.42 46.68 74.67 42.07 79.6C41.89 79.79 41.71 79.98 41.53 80.17L41.47 80.23C40.41 81.35 39.32 82.51 38.35 83.73C36.21 86.42 34.58 89.49 33.1 92.4C32.93 92.73 32.62 92.92 32.27 92.92C32.15 92.92 32.03 92.9 31.92 92.85C31.47 92.66 31.15 92.33 30.85 89.47C30.74 88.42 30.59 86.54 30.87 85.7Z"
                fill="#0A863C" />
              <path
                d="M32.6299 89.48C33.8099 87.26 35.2299 84.82 36.9699 82.62C37.9799 81.35 39.0999 80.16 40.1899 79.01L40.7799 78.38C45.3499 73.5 49.6499 68.28 53.6099 63.36C54.8699 61.79 56.2999 60.02 57.4099 58.12C57.7499 57.54 58.0599 56.93 58.3699 56.34C58.7699 55.57 59.1899 54.78 59.6599 54.01C61.0499 51.77 62.8699 49.88 64.6399 48.05C65.7399 46.91 66.8699 45.73 67.8699 44.5C69.2899 42.75 70.5199 40.78 71.6999 38.87C72.6699 37.31 73.6799 35.69 74.8099 34.17C76.3199 32.12 78.0599 30.23 79.7399 28.4C80.3099 27.78 80.9499 27.08 81.5599 26.41C81.8199 26.12 82.0899 25.82 82.3799 25.51C83.7799 23.98 85.4599 22.14 86.4099 20.31C85.8499 20.69 85.2899 21.14 85.0099 21.36C84.8499 21.49 84.6999 21.6 84.5899 21.69C83.3599 22.63 82.1899 23.69 81.0999 24.7C80.7199 25.05 80.3399 25.41 79.9599 25.77C78.0899 27.53 76.1599 29.36 73.9599 30.83C73.7099 31 73.4599 31.16 73.1999 31.32C72.5499 31.74 71.9399 32.13 71.3899 32.6C70.6099 33.26 69.9299 34.11 69.2699 34.93L60.1599 46.28C58.0699 48.89 55.8999 51.44 53.6099 54.15C45.1299 64.16 36.3599 74.5 32.5499 86.23C32.4099 86.67 32.4699 88.09 32.6299 89.47V89.48Z"
                fill="#D0F4DB" />
              <path
                d="M28.6799 64.19L36.0099 51.04C37.7399 47.94 39.5299 44.73 41.8099 41.87C43.2799 40.04 44.9399 38.36 46.5599 36.73L55.9099 27.31C58.1099 25.1 60.3799 22.81 63.2499 21.28C64.0299 20.86 64.8499 20.52 65.6399 20.18C66.7199 19.72 67.7299 19.28 68.6599 18.7C68.8699 18.56 69.3999 18.11 69.9499 17.64C72.8099 15.18 74.9299 13.5 76.3099 14.33C76.7399 14.59 76.9899 15.02 77.0099 15.52C77.0899 17.58 72.5899 21.04 72.5399 21.08C71.6999 21.68 70.8299 22.27 69.9799 22.84C67.5299 24.5 64.9999 26.21 63.0599 28.41C62.2599 29.32 61.5299 30.35 60.8299 31.35C60.3999 31.96 59.9599 32.59 59.4899 33.21C57.5499 35.75 55.2199 37.98 52.9599 40.14C52.4799 40.6 51.9999 41.06 51.5199 41.52C46.0299 46.84 41.6899 51.93 38.2599 57.1C36.9499 59.07 35.7099 61.15 34.5199 63.15L26.4199 76.74C26.2599 77.01 25.9699 77.17 25.6599 77.17C25.5899 77.17 25.5299 77.17 25.4599 77.15C25.0799 77.06 24.8099 76.75 24.7799 76.36C24.4099 71.82 26.7699 67.6 28.6599 64.21L28.6799 64.19Z"
                fill="#0A863C" />
              <path
                d="M26.9099 72.49L33.0399 62.21C34.2399 60.19 35.4899 58.11 36.8199 56.1C40.3199 50.83 44.7399 45.64 50.3199 40.23C50.7699 39.79 51.2199 39.36 51.6799 38.93L51.7699 38.85C53.9699 36.74 56.2599 34.57 58.1099 32.13C58.5499 31.55 58.9899 30.93 59.4099 30.33C60.1399 29.3 60.8899 28.23 61.7499 27.25C63.8399 24.87 66.4699 23.1 69.0099 21.38L69.0399 21.36C69.8599 20.8 70.7099 20.23 71.5299 19.65C72.4899 18.96 74.5199 16.99 75.1099 16.01C74.8699 16.11 74.5199 16.3 74.0199 16.65C73.0599 17.31 71.9899 18.23 71.1299 18.98C70.4699 19.55 69.9499 20 69.6299 20.2C68.5799 20.86 67.4499 21.34 66.3599 21.81C65.6099 22.13 64.8299 22.47 64.1099 22.85C61.4699 24.25 59.2999 26.44 57.1899 28.56L47.8399 37.98C46.2599 39.57 44.6299 41.22 43.2199 42.98C41.0299 45.72 39.2799 48.87 37.5799 51.91L30.2499 65.06C29.0499 67.22 27.6099 69.8 26.9399 72.5L26.9099 72.49Z"
                fill="#D0F4DB" />
              <path
                d="M89.0201 112.5C102.523 112.5 113.47 101.553 113.47 88.05C113.47 74.5466 102.523 63.6 89.0201 63.6C75.5167 63.6 64.5701 74.5466 64.5701 88.05C64.5701 101.553 75.5167 112.5 89.0201 112.5Z"
                fill="#E28888" />
              <path
                d="M89.0201 113C75.2601 113 64.0701 101.81 64.0701 88.05C64.0701 74.29 75.2601 63.1 89.0201 63.1C102.78 63.1 113.97 74.29 113.97 88.05C113.97 101.81 102.78 113 89.0201 113ZM89.0201 64.1C75.8101 64.1 65.0701 74.84 65.0701 88.05C65.0701 101.26 75.8101 112 89.0201 112C102.23 112 112.97 101.26 112.97 88.05C112.97 74.84 102.23 64.1 89.0201 64.1Z"
                fill="white" />
              <path
                d="M89.02 63.6C102.52 63.6 113.47 74.55 113.47 88.05C113.47 101.55 102.52 112.5 89.02 112.5C75.52 112.5 64.57 101.55 64.57 88.05C64.57 74.55 75.52 63.6 89.02 63.6ZM89.02 60.78C73.98 60.78 61.75 73.01 61.75 88.05C61.75 103.09 73.98 115.32 89.02 115.32C104.06 115.32 116.29 103.09 116.29 88.05C116.29 73.01 104.06 60.78 89.02 60.78Z"
                fill="white" />
              <path
                d="M89.0199 95C87.1499 95 85.6299 96.52 85.6299 98.39C85.7999 102.88 92.2299 102.88 92.3999 98.39C92.3999 96.52 90.8799 95 89.0099 95H89.0199Z"
                fill="white" />
              <path
                d="M92.4099 75.91C90.7299 73.83 87.3199 73.82 85.6399 75.91C84.7999 76.91 84.4399 78.23 84.6699 79.52C85.3399 83.36 86.2899 88.79 86.7199 91.23C87.1999 93.79 90.8499 93.79 91.3299 91.23L93.3799 79.52C93.6099 78.23 93.2499 76.92 92.4099 75.91Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_1209">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">
          {{ $t('buttons.eco_visual_condition_scratches') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "EcoVisualConditionScratches",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>