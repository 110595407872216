<template>
    <div class="form__header">
        <div class="text-left px-4 form-type-info sender-form-label mt-1" style="position: absolute"
            v-text="store.state.ClaimForm.claim_form.repair_type === 'paid_repair' ? $t('form.steps.title_4_paid_repairs') : ''">
          </div>
        <div :class="classes.title" v-text="title">
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useStore } from "vuex";
const store = useStore();
// eslint-disable-next-line
const props = defineProps({
    title: String
})

const classes = computed(() => {
    switch (name.value) {
        case 'xs':
            return {
                title: ['title-xs', 'pa-2',],
            }
        case 'sm':
            return {
                title: ['title-sm', 'pa-4'],
            }

        default:
            return {
                title: ['ccs-title', 'pa-7',],
            }
    }
})
</script>