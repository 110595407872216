<template>
  <div>

    <v-row>
      <v-col class="pb-0">

        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_588)">
            <path
              d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M29.29 31.66H31.49C32.36 31.66 33.05 32.36 33.05 33.22V42.03C33.05 42.9 32.35 43.59 31.49 43.59H29.29C28.42 43.59 27.73 42.89 27.73 42.03V33.22C27.73 32.35 28.43 31.66 29.29 31.66Z"
                fill="#0A863C" />
              <path
                d="M29.29 47.07H31.49C32.36 47.07 33.05 47.77 33.05 48.63V53.03C33.05 53.9 32.35 54.59 31.49 54.59H29.29C28.42 54.59 27.73 53.89 27.73 53.03V48.63C27.73 47.76 28.43 47.07 29.29 47.07Z"
                fill="#0A863C" />
              <path
                d="M82.6999 114.05H37.9699C33.6499 114.05 30.1499 110.55 30.1499 106.23V21.87C30.1499 17.55 33.6499 14.05 37.9699 14.05H82.6999C87.0199 14.05 90.5199 17.55 90.5199 21.87V106.24C90.5199 110.56 87.0199 114.06 82.6999 114.06V114.05ZM37.9699 17.17C36.7299 17.17 35.5399 17.67 34.6599 18.54C33.7799 19.42 33.2799 20.61 33.2799 21.86V106.23C33.2799 107.47 33.7699 108.67 34.6599 109.55C35.5399 110.43 36.7299 110.92 37.9699 110.92H82.6999C85.2899 110.92 87.3899 108.82 87.3899 106.23V21.87C87.3899 19.28 85.2899 17.18 82.6999 17.18H37.9699V17.17Z"
                fill="#0A863C" />
              <path
                d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
                fill="#D0F4DB" />
              <path
                d="M60.3099 26.22C59.6799 26.22 59.1799 26.73 59.1799 27.35V63.74C59.1799 64.37 59.6899 64.87 60.3099 64.87C60.9299 64.87 61.4399 64.36 61.4399 63.74V27.35C61.4399 26.72 60.9299 26.22 60.3099 26.22Z"
                fill="#0A863C" />
              <path
                d="M64.84 32.04C64.21 32.04 63.71 32.55 63.71 33.17V57.92C63.71 58.55 64.22 59.05 64.84 59.05C65.46 59.05 65.97 58.54 65.97 57.92V33.17C65.97 32.54 65.46 32.04 64.84 32.04Z"
                fill="#0A863C" />
              <path
                d="M69.37 37.2C68.74 37.2 68.24 37.71 68.24 38.33V52.76C68.24 53.39 68.75 53.89 69.37 53.89C69.99 53.89 70.5 53.38 70.5 52.76V38.33C70.5 37.7 69.99 37.2 69.37 37.2Z"
                fill="#0A863C" />
              <path
                d="M73.9 39.98C73.27 39.98 72.77 40.49 72.77 41.11V49.98C72.77 50.61 73.28 51.11 73.9 51.11C74.52 51.11 75.03 50.6 75.03 49.98V41.11C75.03 40.48 74.52 39.98 73.9 39.98Z"
                fill="#0A863C" />
              <path
                d="M78.43 42.24C77.8 42.24 77.3 42.75 77.3 43.37V47.72C77.3 48.35 77.81 48.85 78.43 48.85C79.05 48.85 79.56 48.34 79.56 47.72V43.37C79.56 42.74 79.05 42.24 78.43 42.24Z"
                fill="#0A863C" />
              <path
                d="M55.7799 32.04C55.1499 32.04 54.6499 32.55 54.6499 33.17V57.92C54.6499 58.55 55.1599 59.05 55.7799 59.05C56.3999 59.05 56.9099 58.54 56.9099 57.92V33.17C56.9099 32.54 56.3999 32.04 55.7799 32.04Z"
                fill="#0A863C" />
              <path
                d="M51.2501 37.2C50.6201 37.2 50.1201 37.71 50.1201 38.33V52.76C50.1201 53.39 50.6301 53.89 51.2501 53.89C51.8701 53.89 52.3801 53.38 52.3801 52.76V38.33C52.3801 37.7 51.8701 37.2 51.2501 37.2Z"
                fill="#0A863C" />
              <path
                d="M46.7201 39.98C46.0901 39.98 45.5901 40.49 45.5901 41.11V49.98C45.5901 50.61 46.1001 51.11 46.7201 51.11C47.3401 51.11 47.8501 50.6 47.8501 49.98V41.11C47.8501 40.48 47.3401 39.98 46.7201 39.98Z"
                fill="#0A863C" />
              <path
                d="M42.1901 42.24C41.5601 42.24 41.0601 42.75 41.0601 43.37V47.72C41.0601 48.35 41.5701 48.85 42.1901 48.85C42.8101 48.85 43.3201 48.34 43.3201 47.72V43.37C43.3201 42.74 42.8101 42.24 42.1901 42.24Z"
                fill="#0A863C" />
              <path
                d="M89.0201 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0201 39.6C75.5167 39.6 64.5701 50.5466 64.5701 64.05C64.5701 77.5534 75.5167 88.5 89.0201 88.5Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                fill="white" />
              <path
                d="M96.25 64.05C96.25 64.98 96.06 65.86 95.74 66.68L97.18 68.12C97.8 66.89 98.18 65.52 98.18 64.05H96.25ZM102.52 76.18L101.16 77.55L94.73 71.13C93.4 72.2 91.78 72.92 89.99 73.12V77.56H88.06V73.12C83.47 72.64 79.86 68.78 79.86 64.06H81.79C81.79 68.05 85.03 71.29 89.02 71.29C90.67 71.29 92.16 70.7 93.37 69.77L92.34 68.74C91.39 69.42 90.27 69.85 89.02 69.85C85.83 69.85 83.23 67.26 83.23 64.06V59.63L75.52 51.92L76.88 50.56L83.23 56.91L85.16 58.84L92.32 66.01L94.74 68.43L96.1 69.78L102.51 76.19L102.52 76.18Z"
                fill="white" />
              <path
                d="M94.8101 56.33V64.04C94.8101 64.55 94.7201 65.04 94.6001 65.52L83.5701 54.49C84.3401 52.2 86.4801 50.55 89.0301 50.55C92.2201 50.55 94.8201 53.14 94.8201 56.34L94.8101 56.33Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_588">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>



      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium"
          :style="[cp_font_size]">{{ $t('buttons.phone_fault_audio_problems') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "PhoneFaultAudioProblems",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>