<template>
  <div id="transport-out-step">
    <app-form-header :title="$t('form.steps.transport.title_2')"></app-form-header>
    <div id="transport-out-options" v-if="claim_form.transport_in">
      <app-form-transport-eco-out
        v-if="claim_form.device_model.courier_type === 'DPD'" />
      <app-form-transport-standard-out v-else />
    </div>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TransportOutStep",
  setup() {
    const { name } = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
        const isModal = window.self !== window.top;
          switch (name.value) {
            case "xs":
              return {
                top_padding: (isModal)?0:2,
                density: "compact",
                title: ["title-xs", "pa-2"],
              };
            case "sm":
              return {
                top_padding: (isModal)?0:3,
                density: "comfortable",
                title: ["title-sm", "pa-4"],
              };
            default:
              return {
                top_padding: (isModal)?0:4,
                density: (isModal)?"compact":"default",
                title: ["ccs-title", "pa-5"],
              };
          }

    });
    return { classes };
  },
  computed: {
    ...mapGetters("ClaimForm", { claim_form: "claim_form" }),
  },
  methods: {
    ...mapActions("ClaimForm", {
      updateClaimForm: "updateClaimForm",
      syncValue: "syncValue",
    }),
  },
};
</script>

<style></style>