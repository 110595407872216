export const namespaced = true;

const getDefaultState = () => {
    return {
        show: false,
        type: 'primary',
        message: null,
        textClass: ''
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_SHOW(state, status) {
        state.show = status;
    },
    SET_TYPE(state, type) {
        state.type = type;
    },
    SET_MESSAGE(state, message) {
        state.message = message;
    },
    SET_TEXTCLASS(state, textClass) {
        state.textClass = textClass;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export const actions = {
    showAlert({commit}, params) {
        let tmp_type = 'primary';
        if (params.type === 'success') tmp_type = 'green darken-4';
        if (params.type === 'danger') tmp_type = 'red darken';
        if (params.type === 'info') tmp_type = 'blue darken-4';
        commit("SET_TYPE", tmp_type);
        commit("SET_MESSAGE", params.message);
        commit("SET_TEXTCLASS", params.textClass);
        commit("SET_SHOW", true);
    },
    setAlertType({commit}, type) {
        let tmp_type = 'primary';
        if (type === 'success') tmp_type = 'green darken-4';
        if (type === 'danger') tmp_type = 'alert_danger';
        if (type === 'info') tmp_type = 'blue darken-4';
        commit("SET_TYPE", tmp_type);
    },
    setAlertMessage({commit}, message) {
        commit("SET_MESSAGE", message);
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
}

export const getters = {
    show: (state) => {
        return state.show;
    },
    type: (state) => {
        return state.type;
    },
    message: (state) => {
        return state.message;
    },
    textClass: (state) => {
        return state.textClass;
    }
};
