<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_568)">
            <path
              d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
              :fill="c_base_color"/>
            <g :class="{ hovered: hovered }">
            <path
              d="M29.29 31.66H31.49C32.36 31.66 33.05 32.36 33.05 33.22V42.03C33.05 42.9 32.35 43.59 31.49 43.59H29.29C28.42 43.59 27.73 42.89 27.73 42.03V33.22C27.73 32.35 28.43 31.66 29.29 31.66Z"
              fill="#0A863C" />
            <path
              d="M29.29 47.07H31.49C32.36 47.07 33.05 47.77 33.05 48.63V53.03C33.05 53.9 32.35 54.59 31.49 54.59H29.29C28.42 54.59 27.73 53.89 27.73 53.03V48.63C27.73 47.76 28.43 47.07 29.29 47.07Z"
              fill="#0A863C" />
            <path
              d="M82.7 114.05H37.97C33.65 114.05 30.15 110.55 30.15 106.23V21.87C30.15 17.55 33.65 14.05 37.97 14.05H82.7C87.02 14.05 90.52 17.55 90.52 21.87V106.24C90.52 110.56 87.02 114.06 82.7 114.06V114.05ZM37.97 17.17C36.73 17.17 35.54 17.67 34.66 18.54C33.78 19.42 33.28 20.61 33.28 21.86V106.23C33.28 107.47 33.77 108.67 34.66 109.55C35.54 110.43 36.73 110.92 37.97 110.92H82.7C85.29 110.92 87.39 108.82 87.39 106.23V21.87C87.39 19.28 85.29 17.18 82.7 17.18H37.97V17.17Z"
              fill="#0A863C"/>
            <path
              d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
              fill="#D0F4DB" />
            <path
              d="M60.31 54.88C67.9536 54.88 74.15 48.6836 74.15 41.04C74.15 33.3964 67.9536 27.2 60.31 27.2C52.6663 27.2 46.47 33.3964 46.47 41.04C46.47 48.6836 52.6663 54.88 60.31 54.88Z"
              fill="#E28888" />
            <path
              d="M60.31 55.38C52.4 55.38 45.97 48.95 45.97 41.04C45.97 33.13 52.4 26.7 60.31 26.7C68.22 26.7 74.65 33.13 74.65 41.04C74.65 48.95 68.22 55.38 60.31 55.38ZM60.31 27.7C52.96 27.7 46.97 33.68 46.97 41.04C46.97 48.4 52.95 54.38 60.31 54.38C67.67 54.38 73.65 48.4 73.65 41.04C73.65 33.68 67.67 27.7 60.31 27.7Z"
              fill="white" />
            <path
              d="M60.31 27.21C67.95 27.21 74.15 33.41 74.15 41.05C74.15 48.69 67.95 54.89 60.31 54.89C52.67 54.89 46.47 48.69 46.47 41.05C46.47 33.41 52.67 27.21 60.31 27.21ZM60.31 25.61C51.8 25.61 44.87 32.53 44.87 41.05C44.87 49.57 51.79 56.49 60.31 56.49C68.83 56.49 75.75 49.57 75.75 41.05C75.75 32.53 68.83 25.61 60.31 25.61Z"
              fill="white" />
            <path
              d="M60.31 56.98C51.52 56.98 44.37 49.83 44.37 41.04C44.37 32.25 51.52 25.1 60.31 25.1C69.1 25.1 76.25 32.25 76.25 41.04C76.25 49.83 69.1 56.98 60.31 56.98ZM60.31 26.11C52.07 26.11 45.37 32.81 45.37 41.05C45.37 49.29 52.07 55.99 60.31 55.99C68.55 55.99 75.25 49.29 75.25 41.05C75.25 32.81 68.55 26.11 60.31 26.11ZM60.31 55.38C52.4 55.38 45.97 48.95 45.97 41.04C45.97 33.13 52.4 26.7 60.31 26.7C68.22 26.7 74.65 33.13 74.65 41.04C74.65 48.95 68.22 55.38 60.31 55.38ZM60.31 27.7C52.96 27.7 46.97 33.68 46.97 41.04C46.97 48.4 52.95 54.38 60.31 54.38C67.67 54.38 73.65 48.4 73.65 41.04C73.65 33.68 67.67 27.7 60.31 27.7Z"
              fill="white" />
            <path
              d="M65.97 47.96C65.65 47.96 65.33 47.84 65.08 47.59L53.76 36.27C53.27 35.78 53.27 34.99 53.76 34.5C54.25 34.01 55.04 34.01 55.53 34.5L66.85 45.82C67.34 46.31 67.34 47.1 66.85 47.59C66.61 47.83 66.28 47.96 65.96 47.96H65.97Z"
              fill="white" />
            <path
              d="M54.65 47.96C54.33 47.96 54.01 47.84 53.76 47.59C53.27 47.1 53.27 46.31 53.76 45.82L65.08 34.5C65.57 34.01 66.36 34.01 66.85 34.5C67.34 34.99 67.34 35.78 66.85 36.27L55.53 47.59C55.29 47.83 54.96 47.96 54.64 47.96H54.65Z"
              fill="white" />
            <path
              d="M89.0199 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0199 39.6C75.5166 39.6 64.5699 50.5466 64.5699 64.05C64.5699 77.5534 75.5166 88.5 89.0199 88.5Z"
              fill="#0A863C" />
            <path
              d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
              fill="white" />
            <path
              d="M75.61 65.34C75.17 60.62 77.19 56.34 80.53 53.63C81.83 52.57 83.78 53.48 83.78 55.15C83.78 55.77 83.49 56.33 83.01 56.72C80.64 58.65 79.21 61.7 79.56 65.06C80.01 69.44 83.52 72.99 87.89 73.47C93.6 74.11 98.45 69.64 98.45 64.06C98.45 61.1 97.08 58.45 94.94 56.71C94.46 56.33 94.18 55.76 94.18 55.15C94.18 53.49 96.1 52.56 97.39 53.59C100.45 56.06 102.41 59.83 102.41 64.06C102.41 71.79 95.84 78.02 87.99 77.46C81.5 77 76.2 71.83 75.6 65.35L75.61 65.34Z"
              fill="white" />
            <path
              d="M89.0401 47.99C87.9401 47.99 87.0601 48.88 87.0601 49.97V59.09C87.0601 60.19 87.9501 61.07 89.0401 61.07C90.1301 61.07 91.0201 60.18 91.0201 59.09V49.97C91.0201 48.88 90.1401 47.99 89.0401 47.99Z"
              fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_568">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium"
          :style="[cp_font_size]">{{ $t('buttons.phone_fault_not_turn_on') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "PhoneFaultNotTurnOn",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>