<template>
      <div>

            <v-row>
                  <v-col class="pb-0">
                        <svg @mouseenter="hovered = true"
                              @mouseleave="hovered = false" :width="width"
                              :height="height" viewBox="0 0 128 128" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_2_820)">
                                    <path d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
                                          :fill="c_base_color" />
                                    <g :class="{ hovered: hovered }">
                                          <path d="M29.29 31.66H31.49C32.36 31.66 33.05 32.36 33.05 33.22V42.03C33.05 42.9 32.35 43.59 31.49 43.59H29.29C28.42 43.59 27.73 42.89 27.73 42.03V33.22C27.73 32.35 28.43 31.66 29.29 31.66Z"
                                                fill="#0A863C" />
                                          <path d="M29.29 47.07H31.49C32.36 47.07 33.05 47.77 33.05 48.63V53.03C33.05 53.9 32.35 54.59 31.49 54.59H29.29C28.42 54.59 27.73 53.89 27.73 53.03V48.63C27.73 47.76 28.43 47.07 29.29 47.07Z"
                                                fill="#0A863C" />
                                          <path d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
                                                fill="#D0F4DB" />
                                          <path d="M50.67 82.5L49.88 82.67L35.57 85.74L34.77 85.38V93.01L35.44 93.2L36.28 92.82L50.53 86.36L81.94 98.76L51.13 82.71L50.67 82.5Z"
                                                fill="#0A863C" />
                                          <path d="M33.2799 62.44L30.1499 64.47V83.3L33.2799 84.71V62.44Z"
                                                fill="#0A863C" />
                                          <path d="M82.6999 14.05H37.9699C33.6499 14.05 30.1499 17.55 30.1499 21.87V54.29L33.2799 52.87V21.87C33.2799 20.63 33.7699 19.43 34.6599 18.55C35.5399 17.67 36.7299 17.18 37.9699 17.18H82.6999C85.2899 17.18 87.3899 19.28 87.3899 21.87V106.24C87.3899 108.83 85.2899 110.93 82.6999 110.93H37.9699C36.7299 110.93 35.5399 110.43 34.6599 109.56C33.7799 108.68 33.2799 107.49 33.2799 106.24V92.6L30.1499 91.72V106.24C30.1499 110.56 33.6499 114.06 37.9699 114.06H82.6999C87.0199 114.06 90.5199 110.56 90.5199 106.24V21.87C90.5199 17.55 87.0199 14.05 82.6999 14.05Z"
                                                fill="#0A863C" />
                                          <path d="M45.83 37.09L45.43 37.8L38.3 50.58L34.77 52.19V61.47L42.53 56.44L43.82 55.6L44.09 54.71L48.63 39.75L78.68 24.32L46.29 36.89L45.83 37.09Z"
                                                fill="#0A863C" />
                                          <path d="M89.0201 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0201 39.6C75.5167 39.6 64.5701 50.5466 64.5701 64.05C64.5701 77.5534 75.5167 88.5 89.0201 88.5Z"
                                                fill="#0A863C" />
                                          <path d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                                                fill="white" />
                                          <path d="M89.5099 77.32C88.3599 77.32 87.4199 76.39 87.4199 75.23C87.4199 74.07 88.3499 73.14 89.5099 73.14C90.6699 73.14 91.5999 74.07 91.5999 75.23C91.5999 76.39 90.6699 77.32 89.5099 77.32Z"
                                                fill="white" />
                                          <path d="M92.6999 66.03C91.9999 66.36 91.5599 67.09 91.5899 67.87V68.72C91.5899 69.87 90.6599 70.81 89.4999 70.81C88.3399 70.81 87.4099 69.88 87.4099 68.72V67.84C87.3599 65.37 88.8099 63.12 91.0799 62.15C92.4999 61.56 93.4899 60.24 93.6399 58.71C93.7899 57.18 93.0899 55.69 91.8099 54.83C90.5299 53.97 88.8799 53.89 87.5299 54.62C86.1699 55.34 85.3199 56.76 85.3199 58.3C85.3199 59.45 84.3899 60.39 83.2299 60.39C82.0699 60.39 81.1399 59.46 81.1399 58.3C81.1399 55.84 82.2299 53.5 84.1199 51.92C85.9799 50.35 88.4399 49.68 90.8399 50.08C93.2399 50.48 95.3499 51.9 96.6099 53.98C97.8699 56.06 98.1599 58.59 97.3999 60.9C96.6399 63.21 94.9099 65.08 92.6599 66.01V66.03H92.6999Z"
                                                fill="white" />
                                    </g>
                              </g>
                              <defs>
                                    <clipPath id="clip0_2_820">
                                          <rect width="128" height="128"
                                                fill="white" />
                                    </clipPath>
                              </defs>
                        </svg>


                  </v-col>
            </v-row>

            <v-row>
                  <v-col class="pt-5">
                        <span class="font-weight-medium"
                              :style="[cp_font_size]">{{ $t('buttons.phone_fault_other') }}</span>
                  </v-col>
            </v-row>

      </div>
</template>

<script>

export default {
      name: "PhoneFaultOther",
      props: {
            selected: { type: Boolean, required: false },
            height: { type: Number, required: 200 },
            width: { type: Number, required: 180 },
            font_size: { type: String, required: '15px' },

            base_color: { type: String, required: true },
            base_hovered_color: { type: String, required: true },
            base_selected_color: { type: String, required: true },

            base_border_color: { type: String, required: true },
            base_border_hovered_color: { type: String, required: true },
            base_border_selected_color: { type: String, required: true },

      },
      data() {
            return {
                  hovered: false
            }
      },
      computed: {
            cp_font_size() {
                  return `font-size: ${this.font_size}`
            },
            c_base_color() {
                  let color = this.base_color
                  if (this.selected) {
                        color = this.base_selected_color
                  } else {
                        if (this.hovered) color = this.base_hovered_color
                  }
                  return color
            },
            c_base_border_color() {
                  let color = this.base_border_color
                  if (this.selected) {
                        color = this.base_border_selected_color
                  } else {
                        if (this.hovered) color = this.base_border_hovered_color
                  }
                  return color
            }
      }
}
</script>

<style scoped></style>