import ClaimAttachmentService from "@/services/ClaimAttachmentService";

export const namespaced = true;

const getDefaultState = () => {
    return {
        claim_attachments: [],
        meta: {},
        links: {},
        loading: false,
        error: null,
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    }
};

export const actions = {
    upload({commit}, payload) {
        commit("SET_ERROR", {});
        commit("SET_LOADING", true);
        return ClaimAttachmentService.upload(payload)
            .then((response) => {
                commit("SET_LOADING", false);
                return response;
            }).catch(() => {
                commit("SET_LOADING", false);
                commit("SET_ERROR", true);
                return false;
            });
    },

    resetState({commit}) {
        commit('RESET_STATE');
    },
};

export const getters = {
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};