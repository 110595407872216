<template>

  <v-app-bar color="white" class="application-bar pl-10 pr-10 pt-5 pb-5"
             v-if="$route.name !== 'InpostGeoWidget'">
    <app-service-logo/>
    <v-spacer/>
    <app-maker-logo/>
  </v-app-bar>

</template>

<script>
export default {
  name: "AppBar",
}
</script>

<style scoped>

.application-bar {
  border-bottom: solid 5px #0c6f5d;
}

</style>