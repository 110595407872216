export const namespaced = true;

const getDefaultState = () => {
    return {
        show: false,
        message: null,
        question: null,
        confirm_btn: 'OK',
        cancel_btn: 'Anuluj',
        onConfirm: null
    }
}

export const state = getDefaultState();

export const mutations = {
    SET_SHOW(state, status) {
        state.show = status;
    },
    SET_MESSAGE(state, message) {
        state.message = message;
    },
    SET_QUESTION(state, question) {
        state.question = question;
    },
    SET_CONFIRM_BTN(state, confirm_btn) {
        state.confirm_btn = confirm_btn;
    },
    SET_CANCEL_BTN(state, cancel_btn) {
        state.cancel_btn = cancel_btn;
    },
    SET_CONFIRM(state, onConfirm) {
        state.onConfirm = onConfirm;
    },
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

export const actions = {
    showConfirm({commit}, params) {
        commit("SET_MESSAGE", params.message);
        commit("SET_QUESTION", params.question);
        commit("SET_CONFIRM_BTN", params.confirm_btn);
        commit("SET_CANCEL_BTN", params.cancel_btn);
        commit("SET_CONFIRM", params.onConfirm);
        commit("SET_SHOW", true);
    },
    do_confirm({commit}, onConfirm) {
        onConfirm();
        commit('RESET_STATE');
    },
    resetState({commit}) {
        commit('RESET_STATE');
    },
}

export const getters = {
    show: (state) => {
        return state.show;
    },
    message: (state) => {
        return state.message;
    },
    question: (state) => {
        return state.question;
    },
    confirm_btn: (state) => {
        return state.confirm_btn;
    },
    cancel_btn: (state) => {
        return state.cancel_btn;
    },
    onConfirm: (state) => {
        return state.onConfirm;
    },
};
