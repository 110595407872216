<template>
  <div>

    <v-row>
      <v-col class="pb-0">

        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_1139)">
            <path
              d="M50.32 102.11C71.6437 102.11 88.93 84.8237 88.93 63.5C88.93 42.1763 71.6437 24.89 50.32 24.89C28.9962 24.89 11.71 42.1763 11.71 63.5C11.71 84.8237 28.9962 102.11 50.32 102.11Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M82.2301 87.82V96.04C82.2301 104.29 75.5401 110.98 67.2901 110.98C59.0401 110.98 52.3501 104.29 52.3501 96.04V75.65H49.3501V96.04C49.3501 105.93 57.4001 113.98 67.2901 113.98C77.1801 113.98 85.2301 105.93 85.2301 96.04V87.82H82.2301Z"
                fill="#0A863C" />
              <path
                d="M56.51 17.08C57.57 17.08 58.43 17.94 58.43 19V24.75C60.54 24.75 62.26 26.47 62.26 28.58V36.25H39.26V28.58C39.26 26.47 40.98 24.75 43.09 24.75V19C43.09 17.94 43.95 17.08 45.01 17.08C46.07 17.08 46.93 17.94 46.93 19V24.75H54.6V19C54.6 17.94 55.46 17.08 56.52 17.08M56.52 14.08C53.81 14.08 51.6 16.29 51.6 19V21.75H49.93V19C49.93 16.29 47.72 14.08 45.01 14.08C42.3 14.08 40.09 16.29 40.09 19V22.45C37.83 23.57 36.26 25.89 36.26 28.57V39.25H65.26V28.58C65.26 25.89 63.69 23.56 61.43 22.45V19C61.43 16.29 59.22 14.08 56.51 14.08H56.52Z"
                fill="#0A863C" />
              <path
                d="M62.34 39.29C64.46 39.29 66.17 41.01 66.17 43.12V60.37C66.17 65.66 61.88 69.95 56.59 69.95H45.09C39.8 69.95 35.51 65.66 35.51 60.37V43.12C35.51 41 37.23 39.29 39.34 39.29H62.34ZM62.34 36.29H39.34C35.57 36.29 32.51 39.36 32.51 43.12V60.37C32.51 67.3 38.16 72.95 45.09 72.95H56.59C63.52 72.95 69.17 67.3 69.17 60.37V43.12C69.17 39.35 66.1 36.29 62.34 36.29Z"
                fill="#0A863C" />
              <path
                d="M62.34 39.29H39.34C37.22 39.29 35.51 41.01 35.51 43.12V60.37C35.51 65.66 39.8 69.95 45.09 69.95H56.59C61.88 69.95 66.17 65.66 66.17 60.37V43.12C66.17 41 64.45 39.29 62.34 39.29Z"
                fill="#D0F4DB" />
              <path
                d="M62.34 41.29C63.35 41.29 64.17 42.11 64.17 43.12V60.37C64.17 64.55 60.76 67.95 56.59 67.96H45.09C40.91 67.96 37.51 64.55 37.5 60.38V43.13C37.5 42.12 38.32 41.3 39.33 41.3H62.33M62.33 39.3H39.33C37.21 39.3 35.5 41.02 35.5 43.13V60.38C35.5 65.67 39.79 69.96 45.08 69.96H56.58C61.87 69.96 66.16 65.67 66.16 60.38V43.13C66.16 41.01 64.44 39.3 62.33 39.3Z"
                fill="white" />
              <path
                d="M57.4499 52.67C57.3499 52.46 57.1499 52.33 56.9199 52.33L51.8399 52.36L51.8099 52.33L52.5099 47.48C52.5499 47.22 52.4099 46.96 52.1599 46.86C52.0899 46.83 51.9999 46.81 51.9199 46.81C51.7399 46.81 51.5699 46.89 51.4599 47.03L44.9699 55.13C44.8299 55.31 44.7999 55.55 44.8999 55.75C44.9999 55.95 45.1999 56.08 45.4299 56.08H50.0099L50.0399 56.12L48.8699 61.74C48.8099 62 48.9499 62.27 49.1899 62.39C49.4299 62.51 49.7299 62.44 49.8999 62.24L57.3799 53.3C57.5299 53.12 57.5599 52.88 57.4599 52.67H57.4499Z"
                fill="#0A863C" />
              <path
                d="M45.73 74.97L46.81 82.25C46.98 83 47.67 83.52 48.43 83.5H53.24C54.01 83.52 54.69 83 54.87 82.25L55.94 74.97H45.72H45.73Z"
                fill="#0A863C" />
              <path
                d="M62.26 28.58C62.26 26.47 60.54 24.75 58.43 24.75V19C58.43 17.94 57.57 17.08 56.51 17.08C55.45 17.08 54.59 17.94 54.59 19V24.75H46.92V19C46.92 17.94 46.06 17.08 45 17.08C43.94 17.08 43.08 17.94 43.08 19V24.75C40.97 24.75 39.25 26.47 39.25 28.58V36.25H62.25V28.58H62.26Z"
                fill="#D0F4DB" />
              <path
                d="M56.59 26.75H58.42C59.43 26.75 60.26 27.58 60.26 28.59V34.26H41.26V28.6C41.26 27.59 42.09 26.76 43.1 26.76H44.93H56.6M56.52 17.09C55.46 17.09 54.6 17.95 54.6 19.01V24.76H46.93V19.01C46.93 17.95 46.07 17.09 45.01 17.09C43.95 17.09 43.09 17.95 43.09 19.01V24.76C40.98 24.76 39.26 26.48 39.26 28.59V36.26H62.26V28.59C62.26 26.48 60.54 24.76 58.43 24.76V19.01C58.43 17.95 57.57 17.09 56.51 17.09H56.52Z"
                fill="white" />
              <path
                d="M90.72 115.34C104.223 115.34 115.17 104.393 115.17 90.89C115.17 77.3866 104.223 66.44 90.72 66.44C77.2167 66.44 66.27 77.3866 66.27 90.89C66.27 104.393 77.2167 115.34 90.72 115.34Z"
                fill="#E28888" />
              <path
                d="M90.72 115.84C76.96 115.84 65.77 104.65 65.77 90.89C65.77 77.13 76.96 65.94 90.72 65.94C104.48 65.94 115.67 77.13 115.67 90.89C115.67 104.65 104.48 115.84 90.72 115.84ZM90.72 66.94C77.51 66.94 66.77 77.68 66.77 90.89C66.77 104.1 77.51 114.84 90.72 114.84C103.93 114.84 114.67 104.1 114.67 90.89C114.67 77.68 103.93 66.94 90.72 66.94Z"
                fill="white" />
              <path
                d="M90.72 66.45C104.22 66.45 115.17 77.4 115.17 90.9C115.17 104.4 104.22 115.35 90.72 115.35C77.22 115.35 66.2699 104.4 66.2699 90.9C66.2699 77.4 77.22 66.45 90.72 66.45ZM90.72 63.63C75.68 63.63 63.45 75.86 63.45 90.9C63.45 105.94 75.68 118.17 90.72 118.17C105.76 118.17 117.99 105.94 117.99 90.9C117.99 75.86 105.76 63.63 90.72 63.63Z"
                fill="white" />
              <path
                d="M100.22 102.5C99.68 102.5 99.14 102.29 98.73 101.88L79.73 82.88C78.91 82.06 78.91 80.73 79.73 79.91C80.55 79.09 81.88 79.09 82.71 79.91L101.71 98.91C102.53 99.73 102.53 101.06 101.71 101.88C101.3 102.29 100.76 102.5 100.22 102.5Z"
                fill="white" />
              <path
                d="M81.22 102.5C80.68 102.5 80.14 102.29 79.73 101.88C78.91 101.06 78.91 99.73 79.73 98.91L98.73 79.91C99.55 79.09 100.88 79.09 101.71 79.91C102.53 80.73 102.53 82.06 101.71 82.88L82.71 101.88C82.3 102.29 81.76 102.5 81.22 102.5Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_1139">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>


      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium"
          :style="[cp_font_size]">{{ $t('buttons.eco_fault_not_charging') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "EcoFaultNotCharging",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>