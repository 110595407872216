<template>

  <svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-pl" :height="height" viewBox="0 0 640 480"
       class="elevation-3">
    <g fill-rule="evenodd">
      <path fill="#fff" d="M640 480H0V0h640z"/>
      <path fill="#dc143c" d="M640 480H0V240h640z"/>
    </g>
  </svg>

</template>

<script>

import {useDisplay} from 'vuetify'
import {computed} from "vue";

export default {
  name: "PolishLanguage",
  setup() {
    const {name} = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs':
          return 20
        case 'sm':
          return 30
        default:
          return 40
      }
    })
    return {height}
  }
}
</script>

<style scoped>

</style>