<template>
  <v-app class="main-bg">
    <app-main-bar v-if="!store.state.isModal" />
    <app-alert />
    <app-confirm />
    <app-info-dialog />
    <v-main>
      <router-view />
    </v-main>
    <app-main-footer v-if="!store.state.isModal" />
  </v-app>
</template>
<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();

function handleFormAppearance(mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.type === "childList") {
      const formElement = document.querySelector('.v-form');
      if (formElement) {
        const formHeight = formElement.getBoundingClientRect().height;
        console.log(formHeight);
        window.parent.postMessage({
          formHeight: formHeight,
        }, '*');
        checkContentHeight();
        observer.disconnect();
      }
    }
  }
}
function checkContentHeight() {
  setTimeout(() => {
    const height = document.querySelector('body').getBoundingClientRect().height;
    if (height <= 760) {
      document.querySelector('body').classList.add('hide-scroll');
    } else {
      document.querySelector('body').classList.remove('hide-scroll');
    }
  })

}
onMounted(async () => {
  // Load user data from Drupal service.
  document.querySelector('body').classList.add('hide-scroll');
  if (window.self !== window.top) {
    store.state.isModal = true;
  } else {
    store.state.isModal = false;
  }

  if (store.state.isModal) {
    const observer = new MutationObserver(handleFormAppearance);
    observer.observe(document.body, { childList: true, subtree: true });
  }
  window.addEventListener(
    "message",
    function (event) {
      if (typeof event.data == "object" && event.data.call == "sendValue") {
        const userData = event.data.value;
        for (const key in userData) {
          if (key === 'uuid') {
            store.state.ClaimForm.claim_form['user_uuid'] = userData[key];
            continue;
          }
          for (const field in userData[key]) {
            const element = userData[key][field];
            if (key === 'shipping') {
              store.state.ClaimForm.claim_form['sender'][field] = element;
              continue;
            }
            if (key === 'payer' && field === 'company_name') {
              store.state.ClaimForm.claim_form['sender'][field] = element;
            }
            store.state.ClaimForm.claim_form[key][field] = element;
          }
        }
      }
    },
    false
  );
});
</script>
<style lang="scss">
body.hide-scroll {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.ccs-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  color: #1d3a37;
  text-align: center;
}

.v-text-field .v-field__field {
  padding: 4px 0;
}

.v-text-field .v-input__details {
  padding-inline-start: 0 !important;
  /* overried vuetify component styling*/
}

.v-autocomplete .v-field__field {
  padding-top: 18px;
  padding-bottom: 6px;
}

.v-select__selection-text {
  display: flex;
  align-items: center;
}
</style>
