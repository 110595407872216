import axios from "axios"

export const client = axios.create({
    baseURL: process.env.VUE_APP_CLAIM_FORM_API_URL + "/api",
    // withCredentials: true, // required to handle the CSRF token
})

/*
 * Add a request interceptor
 */
client.interceptors.request.use(
    config => {
        return config;
    }
)

/*
 * Add a response interceptor
 */
client.interceptors.response.use(
    (response) => {
        return response;
    }
)
