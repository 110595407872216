<template>

  <v-dialog
      v-model="customer_request_type_dialog"
      max-width="500"
  >
    <template v-slot:activator="{ attrs }">
      <app-animated-button variant="pledge_repair" :selected="claim_form.repair_type === 'pledge_repair'"
                           v-bind="attrs"
                           @click="updateClaimForm({ key: 'repair_type', value: 'pledge_repair' });customer_request_type_dialog = !customer_request_type_dialog"
                          />
    </template>
    <v-card>
      <v-card-title class="crt-dialog-title px-8 py-6" v-text="$t('customer_request')">
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-radio-group v-model="customer_request_type">
          <v-radio
              v-for="(type, index) in customer_request_types"
              :key="index"
              :label="type.label"
              :value="type.value"
              color="primary"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn
            class="menu-btn text-capitalize"
            append-icon="mdi-chevron-right"
            color="primary" elevation="0"
            variant="contained"
            @click="updateClaimForm({ key: 'customer_request_type', value: customer_request_type });customer_request_type_dialog = false;closeVCard();"
        >
          {{ $t('forward_btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "CustomerRequestTypeDialog",
  computed: {
    ...mapGetters('ClaimForm', {claim_form: 'claim_form'}),
    customer_request_types: function () {
      return [
        {label: this.$t('customer_request_types.repair'), value: 'repair'},
        {label: this.$t('customer_request_types.exchange'), value: 'exchange'},
        {label: this.$t('customer_request_types.cashback'), value: 'cashback'},
        {label: this.$t('customer_request_types.expertise'), value: 'expertise'},
      ]
    }
  },
  methods: {
    ...mapActions('ClaimForm', {updateClaimForm: 'updateClaimForm'}),
    closeVCard() {
      let that = this;
      setTimeout(function () {
        that.goNextStep()
      }, 100);
    },
    goNextStep() {
      const forward = document.querySelector('#btn_go_forward');
      if (forward) {
        forward.click();
      }
    }
  },
  data() {
    return {
      customer_request_type_dialog: false,
      customer_request_type: ''
    }
  },
  mounted() {
    this.customer_request_type = this.claim_form.customer_request_type || 'repair';
  }
}
</script>

<style scoped>

.v-card-title.crt-dialog-title {
  font-size: 1.75rem;
}

.menu-btn {
  min-width: 150px;
}

</style>