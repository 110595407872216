<template>
  <div>

    <v-row>
      <v-col class="pb-0">

        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_18)">
            <path
              d="M50.32 102.11C71.6437 102.11 88.93 84.8237 88.93 63.5C88.93 42.1763 71.6437 24.89 50.32 24.89C28.9963 24.89 11.71 42.1763 11.71 63.5C11.71 84.8237 28.9963 102.11 50.32 102.11Z"
              :fill="c_base_color" />
            <g :class="{hovered:hovered}">
              <path
                d="M87.1 17.01C88.52 17.01 89.67 18.16 89.67 19.58V54.78C89.67 56.2 88.52 57.35 87.1 57.35H30.68C29.26 57.35 28.11 56.2 28.11 54.78V19.58C28.11 18.16 29.26 17.01 30.68 17.01H87.1ZM87.1 14.01H30.68C27.61 14.01 25.11 16.51 25.11 19.58V54.78C25.11 57.85 27.61 60.35 30.68 60.35H87.1C90.17 60.35 92.67 57.85 92.67 54.78V19.58C92.67 16.51 90.17 14.01 87.1 14.01Z"
                fill="#0A863C" />
              <path
                d="M30.12 20.99L30.12 53.38C30.12 54.4846 31.0154 55.38 32.12 55.38H85.65C86.7546 55.38 87.65 54.4846 87.65 53.38V20.99C87.65 19.8854 86.7546 18.99 85.65 18.99H32.12C31.0154 18.99 30.12 19.8854 30.12 20.99Z"
                fill="#D0F4DB" />
              <path d="M53.92 29.76L51.39 44.63H55.44L57.98 29.76H53.92Z"
                fill="#9FCCB0" />
              <path
                d="M47.99 29.78L44.02 39.92L43.6 38.39C42.81 36.55 40.59 33.89 37.98 32.23L41.61 44.63H45.9L52.29 29.78H47.99Z"
                fill="#9FCCB0" />
              <path
                d="M42.05 30.85C41.82 29.94 41.13 29.67 40.29 29.64H34.01L33.96 29.94C38.85 31.12 42.09 33.98 43.43 37.42L42.06 30.85H42.05Z"
                fill="#9FCCB0" />
              <path
                d="M66.19 32.62C67.52 32.6 68.48 32.89 69.23 33.19L69.59 33.36L70.14 30.13C69.34 29.83 68.08 29.51 66.51 29.51C62.5 29.51 59.68 31.53 59.66 34.42C59.63 36.56 61.67 37.75 63.21 38.46C64.79 39.19 65.32 39.65 65.31 40.31C65.29 41.31 64.05 41.77 62.88 41.77C61.26 41.77 60.4 41.55 59.06 40.99L58.54 40.76L57.97 44.09C58.92 44.5 60.67 44.86 62.49 44.88C66.75 44.88 69.52 42.88 69.55 39.8C69.57 38.11 68.49 36.82 66.15 35.76C64.73 35.07 63.86 34.61 63.87 33.91C63.87 33.29 64.61 32.63 66.19 32.63V32.62Z"
                fill="#9FCCB0" />
              <path
                d="M80.54 29.78H77.41C76.44 29.78 75.72 30.05 75.29 31.02L69.27 44.65H73.53C73.53 44.65 74.23 42.81 74.38 42.42C74.84 42.42 78.98 42.42 79.57 42.42C79.69 42.94 80.06 44.65 80.06 44.65H83.82L80.54 29.78ZM75.54 39.37C75.88 38.52 77.16 35.21 77.16 35.21C77.14 35.25 77.49 34.35 77.7 33.79L77.98 35.07C77.98 35.07 78.76 38.62 78.92 39.37H75.55H75.54Z"
                fill="#9FCCB0" />
              <path
                d="M87.1 70.81C88.52 70.81 89.67 71.96 89.67 73.38V108.58C89.67 110 88.52 111.15 87.1 111.15H30.68C29.26 111.15 28.11 110 28.11 108.58V73.38C28.11 71.96 29.26 70.81 30.68 70.81H87.1ZM87.1 67.81H30.68C27.61 67.81 25.11 70.31 25.11 73.38V108.58C25.11 111.65 27.61 114.15 30.68 114.15H87.1C90.17 114.15 92.67 111.65 92.67 108.58V73.38C92.67 70.31 90.17 67.81 87.1 67.81Z"
                fill="#0A863C" />
              <path
                d="M30.06 75.17L30.06 106.8C30.06 108.103 31.1166 109.16 32.42 109.16H85.34C86.6434 109.16 87.7 108.103 87.7 106.8V75.17C87.7 73.8666 86.6434 72.81 85.34 72.81H32.42C31.1166 72.81 30.06 73.8666 30.06 75.17Z"
                fill="#D0F4DB" />
              <path opacity="0.6"
                d="M66.54 102.14C72.6483 102.14 77.6 97.1883 77.6 91.08C77.6 84.9717 72.6483 80.02 66.54 80.02C60.4317 80.02 55.48 84.9717 55.48 91.08C55.48 97.1883 60.4317 102.14 66.54 102.14Z"
                fill="#9FCCB0" />
              <path opacity="0.6"
                d="M51.23 101.94C57.3383 101.94 62.29 96.9883 62.29 90.88C62.29 84.7717 57.3383 79.82 51.23 79.82C45.1217 79.82 40.17 84.7717 40.17 90.88C40.17 96.9883 45.1217 101.94 51.23 101.94Z"
                fill="#9FCCB0" />
              <path
                d="M89.02 88.49C102.523 88.49 113.47 77.5434 113.47 64.04C113.47 50.5366 102.523 39.59 89.02 39.59C75.5166 39.59 64.57 50.5366 64.57 64.04C64.57 77.5434 75.5166 88.49 89.02 88.49Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.59C102.52 39.59 113.47 50.54 113.47 64.04C113.47 77.54 102.52 88.49 89.02 88.49C75.52 88.49 64.57 77.54 64.57 64.04C64.57 50.54 75.52 39.59 89.02 39.59ZM89.02 36.77C73.98 36.77 61.75 49 61.75 64.04C61.75 79.08 73.98 91.31 89.02 91.31C104.06 91.31 116.29 79.08 116.29 64.04C116.29 49 104.06 36.77 89.02 36.77Z"
                fill="white" />
              <path
                d="M91.35 69.75H82.81C81.68 69.75 80.76 70.67 80.76 71.8C80.76 72.93 81.68 73.85 82.81 73.85H86.97V75.36C86.97 76.49 87.89 77.41 89.02 77.41C90.15 77.41 91.07 76.49 91.07 75.36V73.85H91.35C92.92 73.85 94.44 73.22 95.55 72.12C96.65 71.02 97.28 69.49 97.28 67.92C97.28 64.65 94.62 61.99 91.35 61.99H86.96C86.47 61.99 86.01 61.8 85.66 61.45C85.32 61.11 85.13 60.65 85.13 60.15C85.13 59.14 85.95 58.32 86.96 58.32H95.23C96.36 58.32 97.28 57.4 97.28 56.27C97.28 55.14 96.36 54.22 95.23 54.22H91.07V52.71C91.07 51.58 90.15 50.66 89.02 50.66C87.89 50.66 86.97 51.58 86.97 52.71V54.22H86.95C83.68 54.22 81.02 56.88 81.02 60.15C81.02 61.74 81.64 63.23 82.77 64.36C83.9 65.47 85.39 66.08 86.95 66.08H91.34C92.35 66.08 93.17 66.9 93.17 67.91C93.17 68.4 92.98 68.87 92.64 69.21C92.3 69.55 91.83 69.74 91.34 69.74L91.35 69.75Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_18">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium"
          :style="[cp_font_size]">{{ $t('buttons.paid_repair') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "PaidRepairButton",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>