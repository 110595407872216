<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_776)">
            <path
              d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M88.9499 31.66H86.7499C85.8799 31.66 85.1899 32.36 85.1899 33.22V42.03C85.1899 42.9 85.8899 43.59 86.7499 43.59H88.9499C89.8199 43.59 90.5099 42.89 90.5099 42.03V33.22C90.5099 32.35 89.8099 31.66 88.9499 31.66Z"
                fill="#0A863C" />
              <path
                d="M88.9499 47.07H86.7499C85.8799 47.07 85.1899 47.77 85.1899 48.63V53.03C85.1899 53.9 85.8899 54.59 86.7499 54.59H88.9499C89.8199 54.59 90.5099 53.89 90.5099 53.03V48.63C90.5099 47.76 89.8099 47.07 88.9499 47.07Z"
                fill="#0A863C" />
              <path
                d="M35.54 114.05H80.27C84.59 114.05 88.09 110.55 88.09 106.23V21.87C88.09 17.55 84.59 14.05 80.27 14.05H35.54C31.22 14.05 27.72 17.55 27.72 21.87V106.24C27.72 110.56 31.22 114.06 35.54 114.06V114.05ZM80.27 17.17C81.51 17.17 82.7 17.67 83.58 18.54C84.46 19.42 84.96 20.61 84.96 21.86V106.23C84.96 107.47 84.47 108.67 83.58 109.55C82.7 110.43 81.51 110.92 80.27 110.92H35.54C32.95 110.92 30.85 108.82 30.85 106.23V21.87C30.85 19.28 32.95 17.18 35.54 17.18H80.27V17.17Z"
                fill="#0A863C" />
              <path
                d="M79.93 18.67H35.89C33.946 18.67 32.37 20.246 32.37 22.19V105.89C32.37 107.834 33.946 109.41 35.89 109.41H79.93C81.874 109.41 83.45 107.834 83.45 105.89V22.19C83.45 20.246 81.874 18.67 79.93 18.67Z"
                fill="#0A863C" />
              <path
                d="M89.0199 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0199 39.6C75.5166 39.6 64.5699 50.5466 64.5699 64.05C64.5699 77.5534 75.5166 88.5 89.0199 88.5Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                fill="white" />
              <path
                d="M89.02 71C87.15 71 85.63 72.52 85.63 74.39C85.8 78.88 92.23 78.88 92.4 74.39C92.4 72.52 90.88 71 89.01 71H89.02Z"
                fill="white" />
              <path
                d="M92.41 51.91C90.73 49.83 87.32 49.82 85.64 51.91C84.8 52.91 84.44 54.23 84.67 55.52C85.34 59.36 86.29 64.79 86.72 67.23C87.2 69.79 90.85 69.79 91.33 67.23L93.38 55.52C93.61 54.23 93.25 52.92 92.41 51.91Z"
                fill="white" />
              <path
                d="M41.74 31.4C44.2087 31.4 46.21 29.3987 46.21 26.93C46.21 24.4613 44.2087 22.46 41.74 22.46C39.2713 22.46 37.27 24.4613 37.27 26.93C37.27 29.3987 39.2713 31.4 41.74 31.4Z"
                fill="#D0F4DB" />
              <path
                d="M41.74 42.5C44.2087 42.5 46.21 40.4987 46.21 38.03C46.21 35.5613 44.2087 33.56 41.74 33.56C39.2713 33.56 37.27 35.5613 37.27 38.03C37.27 40.4987 39.2713 42.5 41.74 42.5Z"
                fill="#D0F4DB" />
              <path
                d="M39.54 68.82C39.16 68.82 38.77 68.67 38.48 68.38C37.89 67.79 37.89 66.84 38.48 66.26L60.48 44.26C61.07 43.67 62.02 43.67 62.6 44.26C63.18 44.85 63.19 45.8 62.6 46.38L40.6 68.38C40.31 68.67 39.92 68.82 39.54 68.82Z"
                fill="#D0F4DB" />
              <path
                d="M49.99 48.14C49.58 48.14 49.18 47.98 48.88 47.65C48.32 47.04 48.36 46.09 48.97 45.53L59.41 35.95C60.02 35.39 60.97 35.43 61.53 36.04C62.09 36.65 62.05 37.6 61.44 38.16L51 47.74C50.71 48 50.35 48.13 49.99 48.13V48.14Z"
                fill="#D0F4DB" />
              <path
                d="M61.96 96.19C61.6 96.19 61.23 96.06 60.95 95.8L50.51 86.22C49.9 85.66 49.86 84.71 50.42 84.1C50.98 83.49 51.93 83.45 52.54 84.01L62.98 93.59C63.59 94.15 63.63 95.1 63.07 95.71C62.77 96.03 62.37 96.2 61.96 96.2V96.19Z"
                fill="#D0F4DB" />
              <path
                d="M62.5601 87.92C62.2001 87.92 61.8301 87.79 61.5501 87.53L46.0601 73.32C45.4501 72.76 45.4101 71.81 45.9701 71.2C46.5301 70.59 47.4801 70.55 48.0901 71.11L63.5801 85.32C64.1901 85.88 64.2301 86.83 63.6701 87.44C63.3701 87.76 62.9701 87.93 62.5601 87.93V87.92Z"
                fill="#D0F4DB" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_776">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">
          {{ $t('buttons.phone_visual_condition_scratches_on_housing') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "PhoneVisualConditionScratchesOnHousing",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>