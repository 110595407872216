<template>
  <div id="regulations-step">
    <app-form-header :title="$t('form.steps.regulations.title')"></app-form-header>
    <v-row v-if="$t('form.steps.regulations.title')">
      <v-col :class="[`mt-${classes.top_padding}`]">
        <div :class="classes.title" v-text="$t('form.steps.regulations.title')">
        </div>
      </v-col>
    </v-row>
    <div class="pa-5 move-top">
      <v-row :class="['pa-3', 'darias-font-size', classes.print_label_check]">
        <v-row id="regulations-subtitle" v-if="!claim_form.payer.payer_statement"
               class="pa-3 pt-6 pl-6 mt-0">
          <div class="col-11">
            <p class="text-muted text-left">
              <b>{{ $t('form.steps.regulations.header') }}</b><br/><br/>
            </p>
          </div>
        </v-row>
        <app-regulations-rodo
            v-if="!claim_form.payer.payer_statement && claim_form.repair_type === 'paid_repair'"/>
        <v-row id="entrepreneur_statement" v-if="claim_form.payer.payer_statement"
               class="pa-3 pt-6 pl-6 mt-0">
          <div class="col-11">
            <p class="text-muted text-justify">
              <b>{{ $t('form.steps.regulations.entrepreneur_info.header') }},</b><br/><br/>
              {{ $t('form.steps.regulations.entrepreneur_info.info_1a') }}
              <a target="_blank"
                 href="https://www.ccsonline.pl/index.php?option=com_content&amp;view=article&amp;id=779&amp;Itemid=234">
                {{ $t('gdpr_lbl') }}
              </a>,
              {{ $t('form.steps.regulations.entrepreneur_info.info_1b') }}
              <a target="_blank"
                 href="https://www.ccsonline.pl/index.php?option=com_content&amp;view=article&amp;id=781&amp;Itemid=237">
                {{ $t('form.steps.regulations.entrepreneur_info.info_1c') }}
              </a>.
            </p>
          </div>
          <br>
          <div class="collapse text-muted">
            <div class="row pt-5">
              <ul style="list-style-type: none;">
                <li>
                  <a data-toggle="" href="#Example" role="button"
                     aria-expanded="false" aria-controls="Example">
                    {{ $t('form.steps.regulations.entrepreneur_info.info_2_title') }}
                  </a>
                </li>
              </ul>

            </div>
            <div class="row">
              <div class="pl-10">
                <br/>
                <p class="pb-3"><b>{{ $t('form.steps.regulations.entrepreneur_info.info_2_header') }},</b></p>
                <p class="text-justify pb-3">{{ $t('form.steps.regulations.entrepreneur_info.info_2_list_1') }}</p>
                <p class="text-justify pb-3">{{ $t('form.steps.regulations.entrepreneur_info.info_2_list_2') }}</p>
                <p class="text-justify pb-3">{{ $t('form.steps.regulations.entrepreneur_info.info_2_list_3') }}</p>
                <p class="text-justify pb-3">{{ $t('form.steps.regulations.entrepreneur_info.info_2_list_4') }}</p>
                <p class="text-justify pb-3">{{ $t('form.steps.regulations.entrepreneur_info.info_2_list_5') }}</p>
                <p class="text-justify pb-3">{{ $t('form.steps.regulations.entrepreneur_info.info_2_list_6') }}</p>
                <p class="text-justify pb-3">{{ $t('form.steps.regulations.entrepreneur_info.info_2_a') }}</p>
              </div>
            </div>
          </div>
          <v-row class="mt-0 text-left">
            <v-col :style="{ 'max-width': '30px' }">
              <v-icon
                  :icon="claim_form.entrepreneur_statement ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                  @click="updateClaimForm({ key: 'entrepreneur_statement', value: !claim_form.entrepreneur_statement })"/>
            </v-col>
            <v-col>
              <span class="mr-1"
                    :style="{ 'font-size': classes.regulations_fontsize }"
                    @click="updateClaimForm({ key: 'entrepreneur_statement', value: !claim_form.entrepreneur_statement })">
                {{ $t('form.steps.regulations.entrepreneur_statement') }}
              </span>
            </v-col>
          </v-row>
        </v-row>
        <v-row id="regulations_select_all"
               v-if="!hide_option && !claim_form.payer.payer_statement && claim_form.repair_type === 'paid_repair'"
               class="mt-4 text-left">
          <v-col :style="{ 'max-width': '30px' }">
            <v-icon
                :icon="claim_form.regulations_select_all ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                @click="updateClaimForm({ key: 'regulations_select_all', value: !claim_form.regulations_select_all }); selectAll()"/>
          </v-col>
          <v-col>
            <span :class="classes.daria_font_1" style="text-decoration: underline"
                  @click="updateClaimForm({ key: 'regulations_select_all', value: !claim_form.regulations_select_all }); selectAll()">
              {{ $t('form.steps.regulations.select_all') }}
            </span>
          </v-col>
        </v-row>
        <v-row class="pa-3 pt-0 pl-6 mt-0 mb-2">
          <v-col class="pa-0">
            <v-row id="regulations_agree" v-if="!claim_form.payer.payer_statement"
                   class="mt-0 text-left">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.regulations_agree ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'regulations_agree', value: !claim_form.regulations_agree })"/>
              </v-col>
              <v-col>
                <span class=""
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'regulations_agree', value: !claim_form.regulations_agree })">
                  {{ $t('form.steps.regulations.checkbox_regulations_agree') }}&nbsp;
                </span>
                <span :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="getCcsRegulationsForPaidRepairs('regulations', false)"><b
                    style="text-decoration: underline">
                  {{ $t('form.steps.regulations.ccs_statute_name') }}
                </b></span>
                <v-btn icon="mdi-file-download" size="small" color="primary"
                       variant="text" density="compact"
                       @click="getCcsRegulationsForPaidRepairs('regulations', true)"
                       class="ml-2"/>
              </v-col>
            </v-row>
            <v-row id="regulations_rsude" class="mt-0 text-left" v-if="!hide_option">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.regulations_rsude ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'regulations_rsude', value: !claim_form.regulations_rsude })"/>
              </v-col>
              <v-col>
                <span class="mr-1"
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'regulations_rsude', value: !claim_form.regulations_rsude })">
                  {{ $t('form.steps.regulations.regulations_rsude') }}
                </span>
                <span :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="getCcsRegulationsForPaidRepairs('rsude', false)"><b
                    style="text-decoration: underline">
                  {{ $t('form.steps.regulations.regulations_rsude_bold') }}
                </b></span>
                <v-btn icon="mdi-file-download" size="small" color="primary"
                       variant="text" density="compact"
                       @click="getCcsRegulationsForPaidRepairs('rsude', true)"
                       class="ml-2"/>
              </v-col>
            </v-row>
            <v-row id="regulations_privacy_policy" class="mt-0 text-left" v-if="!hide_option">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.regulations_privacy_policy ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'regulations_privacy_policy', value: !claim_form.regulations_privacy_policy })"/>
              </v-col>
              <v-col>
                <span class="mr-1"
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'regulations_privacy_policy', value: !claim_form.regulations_privacy_policy })">
                  {{ $t('form.steps.regulations.regulations_privacy_policy') }}
                </span>
                <span :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="getCcsRegulationsForPaidRepairs('polityka_prywatności', false)"><b
                    style="text-decoration: underline">{{
                    $t('form.steps.regulations.regulations_privacy_policy_bold')
                  }}</b></span>
                <v-btn icon="mdi-file-download" size="small" color="primary"
                       variant="text" density="compact"
                       @click="getCcsRegulationsForPaidRepairs('polityka_prywatności', true)"
                       class="ml-2"/>
              </v-col>
            </v-row>
            <v-row id="regulations_iopdo"
                   v-if="!claim_form.payer.payer_statement && claim_form.repair_type === 'paid_repair'"
                   class="mt-0 text-left">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.regulations_iopdo ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'regulations_iopdo', value: !claim_form.regulations_iopdo })"/>
              </v-col>
              <v-col>
                <span class="mr-1"
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'regulations_iopdo', value: !claim_form.regulations_iopdo })">
                  {{ $t('form.steps.regulations.regulations_iopdo') }}
                </span>
                <span :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="getCcsRegulationsForPaidRepairs('klauzula_informacyjna', false)"><b
                    style="text-decoration: underline">
                  {{ $t('form.steps.regulations.regulations_iopdo_bold') }}
                </b></span>
                <v-btn icon="mdi-file-download" size="small" color="primary"
                       variant="text" density="compact"
                       @click="getCcsRegulationsForPaidRepairs('klauzula_informacyjna', true)"
                       class="ml-2"/>
              </v-col>
            </v-row>
            <v-row id="regulatons_pdoou"
                   v-if="!hide_option && !claim_form.payer.payer_statement && claim_form.repair_type === 'paid_repair'"
                   class="mt-0 text-left">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.regulatons_pdoou ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'regulatons_pdoou', value: !claim_form.regulatons_pdoou })"/>
              </v-col>
              <v-col>
                <span class="mr-1"
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'regulatons_pdoou', value: !claim_form.regulatons_pdoou })">
                  {{ $t('form.steps.regulations.regulatons_pdoou') }}
                </span>
              </v-col>
            </v-row>
            <v-row id="regulations_important_note"
                   v-if="!hide_option && !claim_form.payer.payer_statement" class="mt-0 text-left">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.regulations_important_note ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'regulations_important_note', value: !claim_form.regulations_important_note })"/>
              </v-col>
              <v-col>
                <span class=""
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'regulations_important_note', value: !claim_form.regulations_important_note })">
                  {{ $t('form.steps.regulations.important_note') }}<b
                    style="text-decoration: underline">{{ $t('form.steps.regulations.important_note_bold') }}</b>
                </span>
                <app-regulations-important-note
                    :style="{ 'display': claim_form.regulations_important_note ? 'block' : 'none' }"/>
              </v-col>
            </v-row>
            <v-row id="xiaomi_privacy_policy"
                   v-if="!hide_option && claim_form.maker.name === 'XIAOMI' && claim_form.repair_type === 'warranty_repair'" class="mt-0 text-left">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.xiaomi_privacy_policy ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'xiaomi_privacy_policy', value: !claim_form.xiaomi_privacy_policy })"/>
              </v-col>
              <v-col>
                <span class=""
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'xiaomi_privacy_policy', value: !claim_form.xiaomi_privacy_policy })">
                  {{ $t('form.steps.regulations.xiaomi_privacy_policy_a') }}
                  <b style="text-decoration: underline">
                    <a target="_blank" style="color: inherit;" href="https://www.mi.com/pl/about/privacy/">
                      {{ $t('form.steps.regulations.xiaomi_privacy_policy_b') }}
                    </a>
                  </b>

                  {{ $t('form.steps.regulations.xiaomi_privacy_policy_c') }}
                </span>
              </v-col>
            </v-row>

            <v-row id="expertise_agreement"
                   v-if="!hide_option && !claim_form.payer.payer_statement && claim_form.repair_type === 'paid_repair'"
                   class="mt-0 text-left">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.expertise_agreement ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'expertise_agreement', value: !claim_form.expertise_agreement })"/>
              </v-col>
              <v-col>
                <span class=""
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'expertise_agreement', value: !claim_form.expertise_agreement })">
                  {{ $t('form.steps.regulations.expertise_agreement_1_1') }}
                  <v-tooltip location="top" content-class="regulations-tooltip"
                             close-delay="500" max-width="250px">
                    <template #activator="{ props }">
                      <span
                          v-bind="props"><b>{{ $t('form.steps.regulations.expertise_agreement_1_2b') }}</b></span>
                    </template>
                    <span>{{ $t('form.steps.regulations.expertise_agreement_1_2b_tooltip') }}</span>
                  </v-tooltip>
                  {{ $t('form.steps.regulations.expertise_agreement_1_3') }}
                  <v-tooltip location="top" content-class="regulations-tooltip"
                             close-delay="500" max-width="250px">
                    <template #activator="{ props }">
                      <span
                          v-bind="props"><b>{{ $t('form.steps.regulations.expertise_agreement_1_4b') }}</b></span>
                    </template>
                    <span>{{ $t('form.steps.regulations.expertise_agreement_1_4b_tooltip') }}</span>
                  </v-tooltip>

                  {{ $t('form.steps.regulations.expertise_agreement_1_5') }}
                  <b>{{ $t('form.steps.regulations.expertise_agreement_1_6b') }}</b>
                  {{ $t('form.steps.regulations.expertise_agreement_1_7') }}
                  <b>{{ $t('form.steps.regulations.expertise_agreement_1_8b') }}</b>
                  <span class="d-block mt-2">
                    {{ $t('form.steps.regulations.expertise_agreement_2') }}
                  </span>
                </span>
              </v-col>
            </v-row>
            <v-row id="anticipatory_expertise_request"
                   v-if="!hide_option && !claim_form.payer.payer_statement && claim_form.repair_type === 'paid_repair'"
                   class="mt-0 text-left">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.anticipatory_expertise_request ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'anticipatory_expertise_request', value: !claim_form.anticipatory_expertise_request })"/>
              </v-col>
              <v-col>
                <span class=""
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'anticipatory_expertise_request', value: !claim_form.anticipatory_expertise_request })">
                  {{ $t('form.steps.regulations.anticipatory_expertise_request') }}
                </span>
              </v-col>
            </v-row>
            <v-row id="commercial_information_consent"
                   v-if="$i18n.locale ==='pl' && !hide_option && claim_form.repair_type === 'paid_repair'"
                   class="mt-0 text-left">
              <v-col :style="{ 'max-width': '30px' }">
                <v-icon
                    :icon="claim_form.commercial_information_consent ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`"
                    @click="updateClaimForm({ key: 'commercial_information_consent', value: !claim_form.commercial_information_consent })"/>
              </v-col>
              <v-col>
                <span class=""
                      :style="{ 'font-size': classes.regulations_fontsize }"
                      @click="updateClaimForm({ key: 'commercial_information_consent', value: !claim_form.commercial_information_consent })">
                  {{ $t('form.steps.regulations.commercial_information_consent') }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
      <v-row :class="['pa-3']" v-if="!isModal">
        <v-col>
          <app-graphic-regulations class="float-end"
                                   :height="classes.graphic_size" :width="classes.graphic_size"/>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {useDisplay} from "vuetify";
import {computed} from "vue";
import {mapActions, mapGetters} from "vuex";
import * as Api from "@/services/Api";

export default {
  name: "RegulationsStep",
  data() {
    let links_map = new Map();
    links_map.set('pl', process.env.VUE_APP_REGULATIONS_LINK_PL);
    links_map.set('en', process.env.VUE_APP_REGULATIONS_LINK_EN);
    links_map.set('ua', process.env.VUE_APP_REGULATIONS_LINK_UA);
    return {
      regulations_link: links_map,
      dialog: false,
    }
  },
  setup() {
    const {name} = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return {
            top_padding: 2,
            title: ["title-xs", "pa-2"],
            print_label_check: ['print-label-check', 'print-label-check-align-center', 'mt-3'],
            graphic_size: 100,
          };
        case "sm":
          return {
            top_padding: 3,
            title: ["title-sm", "pa-4"],
            print_label_check: ['print-label-check', 'print-label-check-align-center', 'pa-2', 'mt-3'],
            graphic_size: 150
          };
        default:
          return {
            top_padding: 4,
            title: ["title-default", "pa-5"],
            print_label_check: ['print-label-check', 'print-label-check-align-justify', 'mt-3'],
            graphic_size: 200,
            regulations_fontsize: '15px'
          };
      }
    });
    return {
      classes
    };
  },
  computed: {
    ...mapGetters("ClaimForm", {claim_form: "claim_form"}),
    hide_option() {
      /** #2426 - XIAOMI_DE CLIENT */
      return this.$route.query.client
          && (this.$route.query.client.toUpperCase() === "XIAOMI_DE" || this.$route.query.client.toUpperCase() === "XIAOMI_FR");
    },
    isModal() {
      return this.$store.state.isModal;
    }
  },
  methods: {
    ...mapActions("ClaimForm", {updateClaimForm: "updateClaimForm", syncValue: "syncValue",}),
    ...mapActions('AppAlert', {showAlert: 'showAlert'}),
    ...mapActions('AppConfirm', {showConfirm: 'showConfirm'}),
    selectAll() {
      let select = this.claim_form.regulations_select_all
      this.claim_form.regulations_agree = select;
      this.claim_form.regulations_important_note = select;

      if(this.claim_form.maker.name === 'XIAOMI' && this.claim_form.repair_type === 'warranty_repair') {
        this.claim_form.xiaomi_privacy_policy = select;
      }

      this.claim_form.expertise_agreement = select;
      this.claim_form.anticipatory_expertise_request = select;
      this.claim_form.commercial_information_consent = select;
      this.claim_form.regulations_rsude = select;
      this.claim_form.regulations_privacy_policy = select;
      this.claim_form.regulations_iopdo = select;
      if( this.$i18n.locale === 'pl') {
        this.claim_form.regulatons_pdoou = select;
      }
    },
    getCcsRegulationsForPaidRepairs(name, download) {
      let link = 'getCurrentRegulations/' + name + '/' + this.$i18n.locale;
      Api.client
          .get(link, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            if (download) {
              link.download = name + '_' + this.$i18n.locale + ".pdf";
            } else {
              link.target = "_blank";
            }
            link.click();
            URL.revokeObjectURL(link.href)
          }).catch(() => {
        this.showAlert({
          type: 'danger',
          message: this.$t('technical_error')
        })
      })
    }
  }
}
</script>

<style scoped>.regulations-tooltip {
  background: rgba(0, 0, 0, 0.8);
}

.darias-font-size {
  font-size: 16px;
}</style>