<template>
  <div id="device-details-step" class="form-font">
    <app-form-header :title="campaignString('title')"></app-form-header>
    <v-container fluid :class="classes.container" class="move-top">
      <v-row class="mt-1">
        <v-col v-for="(maker, id) in filteredMakers" :key="id" :class="classes.maker_button">
          <v-btn class="text-capitalize btn_maker"
            :variant="claim_form.maker && claim_form.maker.name === maker.name ? `contained` : `outlined`"
            color="primary" elevation="0" :density="classes.density"
            @click="loadDeviceModels({ key: 'maker', value: maker })">
            {{ maker.name }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col class="device-data-form" :class="classes.data_form">
          <label class="sender-form-label">{{$t('form.steps.device_details.device_model_label')}}</label>
          <v-autocomplete v-model="claim_form.device_model" item-children="[]"
            :items="filteredDevices" item-title="model_handel" return-object
            color="primary"
            :density="classes.density"
            :hide-details="classes.hide_details"
                          :class="classes.input"
            :loading="device_models_loading"
            :disabled="device_models_loading || claim_form.maker == null"
            variant="outlined" class="device-model-select"></v-autocomplete>
          <label class="sender-form-label">{{campaignString('device_imei_sn')}}</label>
          <v-text-field v-model="claim_form.device_imei_sn"
            variant="outlined" color="primary" :density="classes.density"
            :hide-details="classes.hide_details"
                        :class="classes.input"
            :loading="sn_loading" :disabled="sn_loading"
            @change="onSNChange()"></v-text-field>
          <v-checkbox v-model="claim_form.no_imei_sn" id="no-imei-checkbox"
            v-if="!(claim_form.maker?.name === 'Tineco' && claim_form.repair_type === 'warranty_repair')"
            :true-value="1" :false-value="0" :density="classes.density"
            :label="campaignString('no_imei_sn')"
            :hide-details="classes.hide_details"

            color="primary"></v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay v-model="sn_overlay_loading" class="align-center justify-center">
      <v-card class="pa-4 align-center justify-center">
        <div v-text="$t('form.steps.device_details.checking_number')"></div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>

import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "DeviceDetailsStep",
  setup() {
    const { name } = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      const isModal = window.self !== window.top;
      if(isModal) {
        return {
          top_padding: 2,
          density: 'compact',
          title: ['title-xs', 'pa-2'],
          input: ['mb-2'],
          container: ['pa-2'],
          data_form: ['mt-3'],
          maker_button: ['px-3', 'py-2'],
          hide_details: true
        }
      } else {
        switch (name.value) {
          case 'xs':
            return {
              top_padding: 2,
              density: 'compact',
              title: ['title-xs', 'pa-2'],
              container: ['pa-2'],
              data_form: ['mt-15'],
              maker_button: ['pa-3'],
              hide_details: false
            }
          case 'sm':
            return {
              top_padding: 3,
              density: 'comfortable',
              title: ['title-sm', 'pa-4'],
              container: ['px-10', 'py-4'],
              data_form: ['mt-15'],
              maker_button: ['pa-3'],
              hide_details: false
            }

          default:
            return {
              top_padding: 4,
              density: 'default',
              title: ['ccs-title', 'pa-5'],
              container: ['px-10', 'py-4'],
              data_form: ['mt-15'],
              maker_button: ['pa-3'],
              hide_details: false
            }
        }
      }
    })
    return { classes }
  },
  computed: {
    ...mapGetters('Maker', { makers: 'makers', maker_errors: 'error' }),
    ...mapGetters('DeviceModel', { device_models: 'device_models', device_models_loading: 'loading', device_errors: 'error' }),
    ...mapGetters('DeviceNumber', { sn_loading: 'sn_loading', sn_overlay_loading: 'sn_overlay_loading' }),
    ...mapGetters('ClaimForm', { claim_form: 'claim_form' }),
    techErrorStr() {
      return this.$t('technical_error')
    },
    fixedInventModelKinds() {
      let kinds = [];
      if (this.$route.query.imk) {
        kinds = this.$route.query.imk.split(',')
      }
      return kinds.map(kind => kind.toUpperCase());
    },
    fixedProducer() {
      return this.$route.params.producer_nm;
    },
    d_model() {
      return this.claim_form.device_model;
    },
    filteredDevices() {
      var tempDevices = [];
      this.device_models.forEach(element => {
          if(this.fixedInventModelKinds.includes(element.invent_model_kind.toUpperCase())){
            tempDevices.push(element);
          }
      });
      if (tempDevices.length > 0) {
        return tempDevices;
      }
      return this.device_models;
    },
    filteredMakers() {
      var tempMakers = [];
      this.makers.forEach(element => {
        if (this.fixedProducer.toUpperCase() === element.name.toUpperCase() || (!this.fixedProducer)) {
          tempMakers.push(element)
          if (this.fixedProducer) {
            this.loadDeviceModels({ key: 'maker', value: element });
          }
        }
      })
      if (tempMakers.length > 0) {
        return tempMakers;
      }
      return this.makers;
    }
  },
  methods: {
    ...mapActions('Maker', { getMakers: 'getMakers' }),
    ...mapActions('DeviceModel', { getDeviceModels: 'getDeviceModels' }),
    ...mapActions('ClaimForm', { updateClaimForm: 'updateClaimForm' }),
    ...mapActions('AppAlert', { showAlert: 'showAlert' }),
    loadDeviceModels: function (payload) {
      this.claim_form.device_model = null;
      this.updateClaimForm(payload);
      this.getDeviceModels(this, this.claim_form.maker);
    },
      onSNChange: function () {
      this.claim_form.device_number_check_status = 'waiting';
    },
    campaignString(label) {
      if(this.claim_form.campaigne && this.$te('campaigns.'+this.claim_form.campaigne+'.device_details.' + label)){
        return this.$t('campaigns.'+this.claim_form.campaigne+'.device_details.' + label);
      }
      return this.$t('form.steps.device_details.' + label);
    },
    getSpinnerVisibility: function () {
      if (!this.device_models_loading) {
        return 'visibility: hidden';
      }
      return null;
    }
  },
  mounted() {
    let getMakersStatus = this.getMakers(this);
    const checkStatus = async () => {
      const status = await getMakersStatus;
      if (status && (this.claim_form.fixed_client !== null && (this.claim_form.fixed_client.toUpperCase() === 'XIAOMI_DE' || this.$route.query.client.toUpperCase() === "XIAOMI_FR"))) {
        this.loadDeviceModels({key: 'maker', value: this.makers[0]})
      }
    };
    checkStatus();
  },
  watch: {
    d_model() {
      this.onSNChange();
      },
    "claim_form.device_imei_sn": function() {
        this.claim_form.device_imei_sn = this.claim_form.device_imei_sn.toUpperCase();
    }
  }
}
</script>

<style>
  label[for|="no-imei-checkbox"] {
    white-space: normal;
  }
</style>