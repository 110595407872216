import {createStore} from 'vuex'

/** Import modules */
import modules from "./modules/modules";


export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules
})