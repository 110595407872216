<template>
  <div id="submit-menu">
    <div v-if="current_step !== last_step + 1">
      <div id="large-devices" class="d-flex">
        <v-btn v-if="current_step > 0" dark variant="outlined" color="white"
               class="menu-btn text-capitalize menu-btn--back font-weight-bold"
               @click="$emit('move', 'back'); sendStatistics(getEvent())">
          {{ $t("back_btn") }}
        </v-btn>
        <v-btn id="btn_go_forward" v-if="current_step !== last_step"
               color="primary" class="menu-btn text-capitalize font-weight-bold"
               variant="contained" @click="$emit('move', 'forward')">
          {{ $t("forward_btn") }}
        </v-btn>
        <v-btn v-else color="primary" class="menu-btn text-capitalize"
               variant="contained" @click="$emit('submitForm'); sendStatistics('approvals')">
          {{ $t("submit_btn") }}
        </v-btn>
      </div>

      <div id="small-devices" class="d-none">
        <v-btn v-if="current_step > 0" variant="outlined" color="primary"
               size="x-small" @click="$emit('move', 'back'); sendStatistics(getEvent())"/>
        <v-btn id="btn_go_forward" v-if="current_step !== last_step"
               color="primary" size="x-small" variant="contained"
               @click="$emit('move', 'forward')"/>

        <v-btn v-else color="primary" class="menu-btn text-capitalize"
               @click="$emit('submitForm'); sendStatistics('approvals')" variant="contained">
          {{ $t("submit_btn") }}
        </v-btn>
      </div>
    </div>

    <div v-else>
      <v-btn color="primary" class="menu-btn text-capitalize"
             @click="$emit('newClaimForm')" variant="contained">
        {{ $t("new_claim_btn") }}
      </v-btn>
      <v-btn color="primary" class="menu-btn text-capitalize" @click="closeModal(); sendStatistics(getEvent())"
             variant="outlined">
        {{ $t("close_claim_btn") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {Statistics} from "@/mixins/Statistics";

export default {
  name: "SubmitMenu",
  mixins: [Statistics],
  props: {
    current_step: {
      type: Number,
      required: true,
    },
    last_step: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isModal() {
      return this.$store.state.isModal;
    }
  },
   mounted() {
    let ref = this;
    window.onbeforeunload = function() {
      ref.sendStatistics(this.getEvent());
    }
  },
  methods: {
    closeModal() {
      if (this.isModal) {
        window.parent.postMessage({
          closeModal: true,
        }, '*');
      } else {
        window.location.href = 'https://www.ccsonline.pl/';
      }
    },
    getEvent() {
      switch (this.current_step - 1) {
        case 1:
          return "manufacturer";
        case 2:
          return "faults"
        case 3:
          return "accessories";
        case 4:
          return "defects"
        case 5:
          return "address";
        case 6:
          return "pickup"
        case 7:
          return "return";
        default:
          return '';
      }
    }
  },
};
</script>

<style scoped>
.menu-btn {
  padding: 10px 18px 12px;
  min-height: 45px;
}

.menu-btn--back {
  background: #D0D0D0;
}
</style>