<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_1198)">
            <path
              d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">
              <path
                d="M19.54 57.44L28.67 51.77L28.91 51.62L29.43 51.57L31.18 51.41L26.24 42.25L34.28 51.12L40.81 50.52L43.47 48.9L47.38 46.15L40.42 36.44L39.97 35.8L40 35.33L40.62 27.43L29.3 14.62L42.25 26.52L42.43 26.7L42.48 27.11L43.49 34.45L52.17 43.39L58.23 46.33L61.08 43.22L62.98 44.96L63.65 37.99L61.46 25.69L61.31 24.88L61.73 24.43L72.65 13.81L64.58 25.57L68 37.39L68.03 37.5L68.06 37.93L68.58 46.32L81.6 40.03L82.08 39.8H82.32L98.41 39.99L83.23 43.27L73.88 49.59L75.33 58.64L100.75 76.21L74.31 63.79L69.69 64.07L71.06 71.25L81.56 74.36L71.57 73.9L75.12 92.44L65.96 69.06L65.23 71.72L57.95 69.7L43.15 113.88L49.41 81.71L44.32 72.26L50.07 78.61L51.8 70.35L44.72 71.81L19.48 94.21L42.08 66.67L44.44 58.16L35.35 55.29L29.86 55.15L19.93 59.25L19.31 59.51L18.95 59.12L12.22 51.68L19.59 57.45L19.54 57.44ZM56.45 63.56L61.9 65.89L63.62 59.7L69 59.37L69.28 56.94L71.6 54.22L67.31 47.86L63.31 50.79L59.58 48.5L53.81 51.38L51.21 47.42L46.11 54.05L50.35 57.82L48.4 67.13L56.45 63.55V63.56Z"
                fill="#0A863C" />
              <path
                d="M89.0201 112.5C102.523 112.5 113.47 101.553 113.47 88.05C113.47 74.5466 102.523 63.6 89.0201 63.6C75.5167 63.6 64.5701 74.5466 64.5701 88.05C64.5701 101.553 75.5167 112.5 89.0201 112.5Z"
                fill="#E28888" />
              <path
                d="M89.0201 113C75.2601 113 64.0701 101.81 64.0701 88.05C64.0701 74.29 75.2601 63.1 89.0201 63.1C102.78 63.1 113.97 74.29 113.97 88.05C113.97 101.81 102.78 113 89.0201 113ZM89.0201 64.1C75.8101 64.1 65.0701 74.84 65.0701 88.05C65.0701 101.26 75.8101 112 89.0201 112C102.23 112 112.97 101.26 112.97 88.05C112.97 74.84 102.23 64.1 89.0201 64.1Z"
                fill="white" />
              <path
                d="M89.02 63.6C102.52 63.6 113.47 74.55 113.47 88.05C113.47 101.55 102.52 112.5 89.02 112.5C75.52 112.5 64.57 101.55 64.57 88.05C64.57 74.55 75.52 63.6 89.02 63.6ZM89.02 60.78C73.98 60.78 61.75 73.01 61.75 88.05C61.75 103.09 73.98 115.32 89.02 115.32C104.06 115.32 116.29 103.09 116.29 88.05C116.29 73.01 104.06 60.78 89.02 60.78Z"
                fill="white" />
              <path
                d="M89.0199 95C87.1499 95 85.6299 96.52 85.6299 98.39C85.7999 102.88 92.2299 102.88 92.3999 98.39C92.3999 96.52 90.8799 95 89.0099 95H89.0199Z"
                fill="white" />
              <path
                d="M92.4099 75.91C90.7299 73.83 87.3199 73.82 85.6399 75.91C84.7999 76.91 84.4399 78.23 84.6699 79.52C85.3399 83.36 86.2899 88.79 86.7199 91.23C87.1999 93.79 90.8499 93.79 91.3299 91.23L93.3799 79.52C93.6099 78.23 93.2499 76.92 92.4099 75.91Z"
                fill="white" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_2_1198">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">
          {{ $t('buttons.eco_visual_condition_cracks') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "EcoVisualConditionCracks",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>