<template>
  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false"
          :width="width" :height="height" viewBox="0 0 128 128" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2_739)">
            <path
              d="M50.32 102.12C71.6437 102.12 88.93 84.8337 88.93 63.51C88.93 42.1863 71.6437 24.9 50.32 24.9C28.9962 24.9 11.71 42.1863 11.71 63.51C11.71 84.8337 28.9962 102.12 50.32 102.12Z"
              :fill="c_base_color" />
            <g :class="{ hovered: hovered }">

              <path
                d="M29.29 31.66H31.49C32.36 31.66 33.05 32.36 33.05 33.22V42.03C33.05 42.9 32.35 43.59 31.49 43.59H29.29C28.42 43.59 27.73 42.89 27.73 42.03V33.22C27.73 32.35 28.43 31.66 29.29 31.66Z"
                fill="#0A863C" />
              <path
                d="M29.29 47.07H31.49C32.36 47.07 33.05 47.77 33.05 48.63V53.03C33.05 53.9 32.35 54.59 31.49 54.59H29.29C28.42 54.59 27.73 53.89 27.73 53.03V48.63C27.73 47.76 28.43 47.07 29.29 47.07Z"
                fill="#0A863C" />
              <path
                d="M82.7 114.05H37.97C33.65 114.05 30.15 110.55 30.15 106.23V21.87C30.15 17.55 33.65 14.05 37.97 14.05H82.7C87.02 14.05 90.52 17.55 90.52 21.87V106.24C90.52 110.56 87.02 114.06 82.7 114.06V114.05ZM37.97 17.17C36.73 17.17 35.54 17.67 34.66 18.54C33.78 19.42 33.28 20.61 33.28 21.86V106.23C33.28 107.47 33.77 108.67 34.66 109.55C35.54 110.43 36.73 110.92 37.97 110.92H82.7C85.29 110.92 87.39 108.82 87.39 106.23V21.87C87.39 19.28 85.29 17.18 82.7 17.18H37.97V17.17Z"
                fill="#0A863C" />
              <path
                d="M82.33 18.67H38.29C36.346 18.67 34.77 20.246 34.77 22.19V105.89C34.77 107.834 36.346 109.41 38.29 109.41H82.33C84.2741 109.41 85.85 107.834 85.85 105.89V22.19C85.85 20.246 84.2741 18.67 82.33 18.67Z"
                fill="#D0F4DB" />
              <path
                d="M89.0199 88.5C102.523 88.5 113.47 77.5534 113.47 64.05C113.47 50.5466 102.523 39.6 89.0199 39.6C75.5166 39.6 64.5699 50.5466 64.5699 64.05C64.5699 77.5534 75.5166 88.5 89.0199 88.5Z"
                fill="#0A863C" />
              <path
                d="M89.02 39.6C102.52 39.6 113.47 50.55 113.47 64.05C113.47 77.55 102.52 88.5 89.02 88.5C75.52 88.5 64.57 77.55 64.57 64.05C64.57 50.55 75.52 39.6 89.02 39.6ZM89.02 36.78C73.98 36.78 61.75 49.01 61.75 64.05C61.75 79.09 73.98 91.32 89.02 91.32C104.06 91.32 116.29 79.09 116.29 64.05C116.29 49.01 104.06 36.78 89.02 36.78Z"
                fill="white" />
              <path
                d="M89.02 71C87.15 71 85.63 72.52 85.63 74.39C85.8 78.88 92.23 78.88 92.4 74.39C92.4 72.52 90.88 71 89.01 71H89.02Z"
                fill="white" />
              <path
                d="M92.41 51.91C90.73 49.83 87.32 49.82 85.64 51.91C84.8 52.91 84.44 54.23 84.67 55.52C85.34 59.36 86.29 64.79 86.72 67.23C87.2 69.79 90.85 69.79 91.33 67.23L93.38 55.52C93.61 54.23 93.25 52.92 92.41 51.91Z"
                fill="white" />
              <path
                d="M45.18 53.21C44.8 53.21 44.41 53.06 44.12 52.77C43.53 52.18 43.53 51.23 44.12 50.65L66.12 28.65C66.71 28.06 67.66 28.06 68.24 28.65C68.82 29.24 68.83 30.19 68.24 30.77L46.24 52.77C45.95 53.06 45.56 53.21 45.18 53.21Z"
                fill="#0A863C" />
              <path
                d="M46.2199 43.44C45.8099 43.44 45.4099 43.28 45.1099 42.95C44.5499 42.34 44.5899 41.39 45.1999 40.83L55.6399 31.25C56.2499 30.69 57.1999 30.73 57.7599 31.34C58.3199 31.95 58.2799 32.9 57.6699 33.46L47.2299 43.04C46.9399 43.3 46.5799 43.43 46.2199 43.43V43.44Z"
                fill="#0A863C" />
              <path
                d="M59.68 94.92C59.32 94.92 58.95 94.79 58.67 94.53L48.23 84.95C47.62 84.39 47.58 83.44 48.14 82.83C48.7 82.22 49.65 82.18 50.26 82.74L60.7 92.32C61.31 92.88 61.35 93.83 60.79 94.44C60.49 94.76 60.09 94.93 59.68 94.93V94.92Z"
                fill="#0A863C" />
              <path
                d="M60.79 86.14C60.43 86.14 60.06 86.01 59.78 85.75L44.29 71.54C43.68 70.98 43.64 70.03 44.2 69.42C44.76 68.81 45.71 68.77 46.32 69.33L61.81 83.54C62.42 84.1 62.46 85.05 61.9 85.66C61.6 85.98 61.2 86.15 60.79 86.15V86.14Z"
                fill="#0A863C" />
            </g>
          </g>

          <defs>
            <clipPath id="clip0_2_739">
              <rect width="128" height="128" fill="white" />
            </clipPath>
          </defs>
        </svg>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium"
          :style="[cp_font_size]">{{ $t('buttons.phone_fault_not_display') }}</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>

export default {
  name: "PhoneFaultNotDisplay",
  props: {
    selected: { type: Boolean, required: false },
    height: { type: Number, required: 200 },
    width: { type: Number, required: 180 },
    font_size: { type: String, required: '15px' },

    base_color: { type: String, required: true },
    base_hovered_color: { type: String, required: true },
    base_selected_color: { type: String, required: true },

    base_border_color: { type: String, required: true },
    base_border_hovered_color: { type: String, required: true },
    base_border_selected_color: { type: String, required: true },

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style scoped></style>