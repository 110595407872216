<template>

  <div id="service-logo">
    <img :src="logo" :height="height" alt="Cyfrowe Centrum Serwisowe S.A. - Logo">
  </div>

</template>

<script>
import {useDisplay} from "vuetify";
import {computed} from "vue";

export default {
  name: "ServiceLogo",
  setup() {
    const {name} = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const height = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs':
          return 40
        default:
          return 60
      }
    })
    const logo = computed(() => {
      return require('../../assets/logo-ccs-online.svg')
    })
    return {
      height,
      logo
    }
  },
}
</script>

<style scoped>

</style>