// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import pl from './vuetify_pl'

// Vuetify
import {createVuetify} from 'vuetify'

export default createVuetify({
    lang: {
        locales: {pl},
        current: 'pl',
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#258569',
                    black: '#1D3A37',
                    alert_danger: '#FF4D4D',

                    options_menu: '#EBEDED',

                }
            },
        },
    },
})
