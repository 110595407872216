<template>

  <div>

    <v-row>
      <v-col class="pb-0">
        <svg @mouseenter="hovered = true" @mouseleave="hovered = false" :width="width" :height="height" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2_3)">
        <path d="M50.32 102.11C71.6437 102.11 88.93 84.8237 88.93 63.5C88.93 42.1763 71.6437 24.89 50.32 24.89C28.9963 24.89 11.71 42.1763 11.71 63.5C11.71 84.8237 28.9963 102.11 50.32 102.11Z" :fill="c_base_color"/>
        <g :class="{hovered:hovered}">
        <path d="M89.15 17C91.36 17 93.15 18.79 93.15 21V107C93.15 109.21 91.36 111 89.15 111H33.15C30.94 111 29.15 109.21 29.15 107V21C29.15 18.79 30.94 17 33.15 17H89.15ZM89.15 14H33.15C29.29 14 26.15 17.14 26.15 21V107C26.15 110.86 29.29 114 33.15 114H89.15C93.01 114 96.15 110.86 96.15 107V21C96.15 17.14 93.01 14 89.15 14Z" fill="#0A863C"/>
        <path d="M87.36 19H34.94C32.8469 19 31.15 20.6968 31.15 22.79V105.21C31.15 107.303 32.8469 109 34.94 109H87.36C89.4532 109 91.15 107.303 91.15 105.21V22.79C91.15 20.6968 89.4532 19 87.36 19Z" fill="#D0F4DB"/>
        <path d="M74.47 63.64H39.8C38.97 63.64 38.3 62.97 38.3 62.14C38.3 61.31 38.97 60.64 39.8 60.64H74.47C75.3 60.64 75.97 61.31 75.97 62.14C75.97 62.97 75.3 63.64 74.47 63.64Z" fill="#9FCCB0"/>
        <path d="M74.69 54.49H40.02C39.19 54.49 38.52 53.82 38.52 52.99C38.52 52.16 39.19 51.49 40.02 51.49H74.69C75.52 51.49 76.19 52.16 76.19 52.99C76.19 53.82 75.52 54.49 74.69 54.49Z" fill="#9FCCB0"/>
        <path d="M74.69 72.78H40.02C39.19 72.78 38.52 72.11 38.52 71.28C38.52 70.45 39.19 69.78 40.02 69.78H74.69C75.52 69.78 76.19 70.45 76.19 71.28C76.19 72.11 75.52 72.78 74.69 72.78Z" fill="#9FCCB0"/>
        <path d="M40.48 101.03C40.13 101.03 39.78 101.01 39.43 100.96C38.9 100.89 38.52 100.4 38.59 99.87C38.66 99.34 39.15 98.96 39.68 99.03C41.04 99.21 42.44 98.91 43.66 98.27C42.19 96.44 41.88 93.73 43.05 91.64C43.96 90.03 46 88.87 47.73 89.81C48.51 90.23 49.03 90.97 49.25 91.96C49.41 92.7 49.38 93.56 49.17 94.38C48.8 95.82 48.03 97.14 46.94 98.24C48.35 98.66 50.01 98.3 51.02 97.23C51.2 97.04 51.38 96.81 51.56 96.58C51.86 96.2 52.17 95.8 52.58 95.45C53 95.09 53.5 94.83 54.01 94.71C54.65 94.56 55.27 94.63 55.79 94.93C56.66 95.42 56.97 96.31 57.21 97.02C57.24 97.1 57.27 97.18 57.3 97.26C57.48 97.76 57.72 97.99 57.8 98C57.89 98 58.12 97.81 58.26 97.7C58.56 97.47 58.93 97.18 59.43 97.05C60.34 96.81 61.15 97.22 61.74 97.51C61.81 97.55 61.89 97.58 61.96 97.62C64.29 98.74 67.47 97.88 68.93 95.75C69.23 95.31 69.83 95.19 70.28 95.49C70.72 95.79 70.84 96.4 70.54 96.84C69.57 98.27 68.06 99.31 66.29 99.79C64.52 100.27 62.68 100.12 61.13 99.37C61.05 99.33 60.96 99.29 60.88 99.25C60.53 99.08 60.13 98.87 59.93 98.93C59.81 98.96 59.63 99.1 59.45 99.24C59.09 99.52 58.6 99.9 57.92 99.94C57.88 99.94 57.84 99.94 57.8 99.94C56.8 99.94 55.94 99.19 55.48 97.92C55.45 97.83 55.42 97.74 55.39 97.65C55.24 97.2 55.07 96.74 54.84 96.61C54.63 96.49 54.19 96.63 53.86 96.91C53.59 97.14 53.36 97.45 53.1 97.77C52.9 98.03 52.68 98.3 52.44 98.56C51.32 99.74 49.73 100.34 48.14 100.34C47.13 100.34 46.12 100.1 45.24 99.6C43.77 100.53 42.13 101.01 40.49 101.01L40.48 101.03ZM46.42 91.44C45.82 91.44 45.1 91.98 44.74 92.61C43.94 94.02 44.21 95.95 45.26 97.17C46.23 96.29 46.96 95.17 47.29 93.91C47.53 92.98 47.44 91.87 46.81 91.53C46.69 91.47 46.56 91.44 46.42 91.44Z" fill="#9FCCB0"/>
        <path d="M57.36 81.93H40.25C39.42 81.93 38.75 81.26 38.75 80.43C38.75 79.6 39.42 78.93 40.25 78.93H57.36C58.19 78.93 58.86 79.6 58.86 80.43C58.86 81.26 58.19 81.93 57.36 81.93Z" fill="#9FCCB0"/>
        <path d="M89.02 88.49C102.523 88.49 113.47 77.5434 113.47 64.04C113.47 50.5366 102.523 39.59 89.02 39.59C75.5166 39.59 64.57 50.5366 64.57 64.04C64.57 77.5434 75.5166 88.49 89.02 88.49Z" fill="#0A863C"/>
        <path d="M89.02 39.59C102.52 39.59 113.47 50.54 113.47 64.04C113.47 77.54 102.52 88.49 89.02 88.49C75.52 88.49 64.57 77.54 64.57 64.04C64.57 50.54 75.52 39.59 89.02 39.59ZM89.02 36.77C73.98 36.77 61.75 49 61.75 64.04C61.75 79.08 73.98 91.31 89.02 91.31C104.06 91.31 116.29 79.08 116.29 64.04C116.29 49 104.06 36.77 89.02 36.77Z" fill="white"/>
        <path d="M100.29 54.64C95.62 54.64 92.04 53.31 89.02 50.36C86 53.3 82.42 54.64 77.75 54.64C77.75 62.3 76.17 73.27 89.02 77.73C101.87 73.27 100.29 62.3 100.29 54.64ZM89.02 72.93C80.67 70.04 81.7 62.91 81.7 57.94C84.73 57.94 87.05 57.07 89.02 55.16C90.98 57.07 93.31 57.94 96.34 57.94C96.34 62.91 97.37 70.04 89.02 72.93Z" fill="white"/>
        <path d="M74.47 37.82H39.8C38.97 37.82 38.3 37.15 38.3 36.32C38.3 35.49 38.97 34.82 39.8 34.82H74.47C75.3 34.82 75.97 35.49 75.97 36.32C75.97 37.15 75.3 37.82 74.47 37.82Z" fill="#9FCCB0"/>
        </g>  
        </g>
        <defs>
        <clipPath id="clip0_2_3">
        <rect width="128" height="128" fill="white"/>
        </clipPath>
        </defs>
        </svg>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-5">
        <span class="font-weight-medium" :style="[cp_font_size]">{{ $t('buttons.warranty_repair') }}</span>
      </v-col>
    </v-row>
  </div>
</template>
<script>

export default {
  name: "WarrantyRepairButton",
  props: {
    selected: {type: Boolean, required: false},
    height: {type: Number, required: 200},
    width: {type: Number, required: 180},
    font_size: {type: String, required: '15px'},

    base_color: {type: String, required: true},
    base_hovered_color: {type: String, required: true},
    base_selected_color: {type: String, required: true},

    base_border_color: {type: String, required: true},
    base_border_hovered_color: {type: String, required: true},
    base_border_selected_color: {type: String, required: true},

  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    cp_font_size() {
      return `font-size: ${this.font_size}`
    },
    c_base_color() {
      let color = this.base_color
      if (this.selected) {
        color = this.base_selected_color
      } else {
        if (this.hovered) color = this.base_hovered_color
      }
      return color
    },
    c_base_border_color() {
      let color = this.base_border_color
      if (this.selected) {
        color = this.base_border_selected_color
      } else {
        if (this.hovered) color = this.base_border_hovered_color
      }
      return color
    }
  }
}
</script>

<style>
svg{
  cursor: pointer;
}
g{
  transition: .3s;
}
g.hovered{
  transition: .3s;
  transform: translateY(-10px) scale(1.05);
}
</style>