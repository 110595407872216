import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/:producer_nm?',
        name: 'ClaimForm',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "claim-form" */ '../views/ClaimForm')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
