<template>
  <div id="device-faults-step">
    <app-form-header :title="$t('form.steps.device_faults.title')"></app-form-header>
    <v-row class="move-top">
      <v-col>
        <div
          :class="classes.subtitle"
          v-text="$t('form.steps.device_faults.subtitle')"
        ></div>
      </v-col>
    </v-row>

    <v-container
      v-if="claim_form.device_model.courier_type === 'DHL'"
      id="regular-devices"
      fluid
      class="pa-5"
    >
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button
            variant="phone_fault_not_turn_on"
            :selected="
              claim_form.device_faults.includes('phone_fault_not_turn_on')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'phone_fault_not_turn_on',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_fault_not_display"
            :selected="
              claim_form.device_faults.includes('phone_fault_not_display')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'phone_fault_not_display',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_fault_broken_display"
            :selected="
              claim_form.device_faults.includes('phone_fault_broken_display')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'phone_fault_broken_display',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_fault_not_charging"
            :selected="
              claim_form.device_faults.includes('phone_fault_not_charging')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'phone_fault_not_charging',
              })
            "
          />
        </v-col>
      </v-row>

      <v-row dense no-gutters>
        <v-col>
          <app-animated-button
            variant="phone_fault_freezes"
            :selected="claim_form.device_faults.includes('phone_fault_freezes')"
            @click="
              syncValue({ key: 'device_faults', value: 'phone_fault_freezes' })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_fault_audio_problems"
            :selected="
              claim_form.device_faults.includes('phone_fault_audio_problems')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'phone_fault_audio_problems',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_fault_other"
            :selected="claim_form.device_faults.includes('device_fault_other')"
            @click="
              syncValue({ key: 'device_faults', value: 'device_fault_other' })
            "
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else id="eco-devices" fluid class="pa-5">
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button
            variant="eco_fault_spring_review"
            :selected="
              claim_form.device_faults.includes('eco_fault_spring_review')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'eco_fault_spring_review',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="eco_fault_not_turn_on"
            :selected="
              claim_form.device_faults.includes('eco_fault_not_turn_on')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'eco_fault_not_turn_on',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="eco_fault_not_charging"
            :selected="
              claim_form.device_faults.includes('eco_fault_not_charging')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'eco_fault_not_charging',
              })
            "
          />
        </v-col>
      </v-row>

      <v-row dense no-gutters>
        <v-col>
          <app-animated-button
            variant="eco_fault_short_working_time"
            :selected="
              claim_form.device_faults.includes('eco_fault_short_working_time')
            "
            @click="
              syncValue({
                key: 'device_faults',
                value: 'eco_fault_short_working_time',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="eco_fault_other"
            :selected="claim_form.device_faults.includes('device_fault_other')"
            @click="
              syncValue({ key: 'device_faults', value: 'device_fault_other' })
            "
          />
        </v-col>
      </v-row>
    </v-container>

    <v-row style="min-height: 175px">
      <v-col class="px-10 pt-3 pb-0" v-if="claim_form.device_faults.includes('device_fault_other')">
        <v-textarea
          v-model="claim_form.device_faults_other"
          @update:modelValue="
            updateClaimForm({ key: 'device_faults_other', value: $event })
          "
          :label="$t('form.steps.device_faults.phone_fault_other')"
          variant="outlined"
          color="primary"
          dense
          rows="4"
          :hint="$t('max_500_signs_msg')"
          no-resize
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DeviceFaultsStep",
  setup() {
    const { name } = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return {
            top_padding: 2,
            buttons_menu_width: 150,
            title: ["title-xs", "pa-2"],
            subtitle: ["subtitle-xs"],
          };
        case "sm":
          return {
            top_padding: 3,
            buttons_menu_width: 75,
            title: ["title-sm", "pa-4"],
            subtitle: ["subtitle-sm"],
          };
        default:
          return {
            top_padding: 4,
            buttons_menu_width: 75,
            title: ["ccs-title", "pa-5"],
            subtitle: ["subtitle-default"],
          };
      }
    });
    return { classes };
  },

  computed: {
    ...mapGetters("ClaimForm", { claim_form: "claim_form" }),
  },

  methods: {
    ...mapActions("ClaimForm", {
      updateClaimForm: "updateClaimForm",
      syncValue: "syncValue",
    }),
  },

  mounted() {
    if(this.claim_form.campaigne === 'spring24'){
      this.syncValue({
        key: 'device_faults',
        value: 'eco_fault_spring_review',
      });
      this.$emit('move', 'forward')
    }
  }
};
</script>

<style scoped>
</style>