<template>
  <div id="app-alert">
    <v-overlay :model-value="show" class="align-center justify-center">
      <v-snackbar app v-model="show" :color="type" elevation="5" timeout="10000"
        location="center" transition="scale-transition" variant="contained-flat"
        rounded shaped multi-line class="text-white">
        <p class="text-white font-weight-black" :class="textClass"
          v-html="message"></p>
        <template v-slot:actions>
          <v-btn color="white" icon @click="resetState()">
            <v-icon dark>mdi-window-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AppAlert",
  computed: {
    ...mapGetters('AppAlert', { show: 'show', type: 'type', message: 'message', textClass: 'textClass' }),
  },
  methods: {
    ...mapActions('AppAlert', { resetState: 'resetState' }),
  },
}
</script>

<style scoped>

</style>