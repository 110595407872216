<template>

  <div id="language-selector">
    <v-row>
      <v-col>
        <v-spacer/>
        <app-language-polish class="language-button mr-3"
                             @click="changeLanguage('pl')"/>
        <app-language-english class="language-button mr-3"
                              @click="changeLanguage('en')"/>
        <app-language-ukrainian class="language-button"
                                @click="changeLanguage('uk')"/>
      </v-col>
    </v-row>
  </div>

</template>

<script>

export default {
  name: "LanguageSelector",
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang
    }
  },
}
</script>

<style scoped>

.language-button {
  cursor: pointer;
}

</style>