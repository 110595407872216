<template>
  <div id="form-step-complaint-type" class="form-font">
    <app-form-header
      :title="$t('form.steps.complaint_type.title')"></app-form-header>
    <v-row class="move-top">
      <v-col :class="[`mt-${classes.top_padding}`]">
        <div :class="classes.subtitle"
          v-text="$t('form.steps.complaint_type.subtitle')">
        </div>
      </v-col>
    </v-row>
    <v-row :class="[`w-${classes.buttons_menu_width}`, 'pb-8']">
      <v-col>
        <app-animated-button variant="warranty_repair"
          :selected="claim_form.repair_type === 'warranty_repair'"
          @click="updateClaimForm({ key: 'repair_type', value: 'warranty_repair' }); goNextStep(); sendStatistics('warranty_repair');" />
      </v-col>
      <v-col>
        <app-animated-button variant="paid_repair"
          :selected="claim_form.repair_type === 'paid_repair'"
          @click="updateClaimForm({ key: 'repair_type', value: 'paid_repair' }); goNextStep(); sendStatistics('paid_repair');" />
      </v-col>
      <v-col v-if="claim_form.fixed_client === 'terg'">
        <app-customer-request-type-dialog />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Statistics } from "@/mixins/Statistics";

export default {
  name: "ComplaintTypeStep",
  mixins: [Statistics],
  setup() {
    const { name } = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs':
          return {
            top_padding: 2,
            buttons_menu_width: 150,
            title: ['title-xs', 'pa-2',],
            subtitle: ['subtitle-xs', 'pa-2'],
          }
        case 'sm':
          return {
            top_padding: 3,
            buttons_menu_width: 75,
            title: ['title-sm', 'pa-4'],
            subtitle: ['subtitle-sm', 'font-weight-light', 'pa-4'],
          }

        default:
          return {
            top_padding: 4,
            buttons_menu_width: 75,
            title: ['ccs-title', 'pa-5',],
            subtitle: ['subtitle-default', 'pa-5'],
          }
      }
    })
    return { classes }
  },
  computed: {
    ...mapGetters('ClaimForm', { claim_form: 'claim_form' }),
    ...mapGetters('Maker', { makers: 'makers', maker_errors: 'error' }),
    fixedProducer() {
      return this.$route.params.producer_nm;
    },
    fixedRepairType() {
      if(this.$route.query.rt && this.repair_types.includes(this.$route.query.rt.toLowerCase())){
        return this.$route.query.rt.toLowerCase();
      }
      return false;
    },
  },
  watch: {
    makers: {
      handler() {
        if (this.makers) {
          let tempMakers = [];
          this.makers.forEach(element => {
            if (this.fixedProducer.toUpperCase() === element.name.toUpperCase() || (!this.fixedProducer)) {
              tempMakers.push(element)
              if (this.fixedProducer) {
                this.setMaker({ key: 'maker', value: element });
              }
            }
          })
        }
      }
    }
  },
  methods: {
    ...mapActions('Maker', { getMakers: 'getMakers' }),
    ...mapActions('ClaimForm', { updateClaimForm: 'updateClaimForm' }),
    ...mapActions('AppAlert', { showAlert: 'showAlert' }),
    goNextStep() {
      const forward = document.querySelector('#btn_go_forward');
      if (forward) {
        forward.click();
      }
    },
    setMaker: function (payload) {
      this.updateClaimForm(payload);
    },
  },
  data() {
    return {
      selected: null,
      repair_types: [
        'warranty_repair', 'paid_repair'
      ]
    }
  },
  mounted() {
    this.getMakers(this);

    if(this.fixedRepairType){
      this.updateClaimForm({ key: 'repair_type', value: this.fixedRepairType });
      this.goNextStep();
    }
  },
}
</script>

<style>
.form__header {
  border-radius: 20px 20px 0 0;
  padding: 5px 40px;
  background: radial-gradient(circle at 0%,
      rgba(174, 215, 200, 0.5) 0%,
      #c4dfd4 10%,
      #e9f8f3 40%);
  height: fit-content;
  min-height: 90px;
  width: 100%;
  z-index: 100;
  background-color: #bbdbc8;
}
</style>