<template>
  <div>
    <component
      :is="variant"
      :selected="selected"
      :height="dimensions.height"
      :width="dimensions.width"
      :font_size="dimensions.font_size"
      :base_color="base_color"
      :base_hovered_color="base_hovered_color"
      :base_selected_color="base_selected_color"
      :base_border_color="base_border_color"
      :base_border_hovered_color="base_border_hovered_color"
      :base_border_selected_color="base_border_selected_color"
    ></component>
  </div>
</template>

<script>
import * as animated_buttons from "./index";
import { useDisplay } from "vuetify";
import { computed } from "vue";

export default {
  name: "AnimatedButton",
  components: {
    ...animated_buttons,
  },
  props: {
    variant: { type: String, required: true },
    selected: { type: Boolean, default: false },

    base_color: { type: String, default: "#F6F6F6" },
    base_hovered_color: { type: String, default: "#8FCBB9" },
    base_selected_color: { type: String, default: "#258569" },

    base_border_color: { type: String, default: "#a3a3a4" },
    base_border_hovered_color: { type: String, default: "#6CA89D" },
    base_border_selected_color: { type: String, default: "#0C6F5D" },
  },
  setup() {
    const { name } = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const dimensions = computed(() => {
      // name is reactive and
      // must use .value
      const isModal = window.self !== window.top;
      switch (name.value) {
        case "xs":
          return {
            width: 80,
            height: 80,
            font_size: "12px;",
          };
        case "sm":
          return {
            width: 110,
            height: 110,
            font_size: "12px;",
          };
        case "md":
          return {
            width: (isModal)?110:190,
            height: (isModal)?110:190,
            font_size: "13px;",
          };
        case "lg":
          return {
            width: 190,
            height: 190,
            font_size: "13px;",
          };
        default:
          return {
            width: 190,
            height: 190,
            font_size: "13px;",
          };
      }
    });
    return { dimensions };
  },
};
</script>

<style>
.btn-animated {
  cursor: pointer;
}
 span {
  color: #1D3A37;
}
.btn-transition {
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
</style>