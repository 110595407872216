export const Statistics = {
    methods: {
        /**
         * Send statistics during work with form
         *
         * @param eventName
         */
        sendStatistics(eventName) {
            this.$gtm.trackEvent({
                event: eventName,
                category: null,
                action: 'click',
                label: '',
            })
        },
    },

}