export default {
    badge: 'Odznaka',
    close: 'Zamknij',
    dataIterator: {
        noResultsText: 'Nie znaleziono danych odpowiadających wyszukiwaniu',
        loadingText: 'Wczytywanie danych...',
    },
    dataTable: {
        itemsPerPageText: 'Wierszy na stronie:',
        ariaLabel: {
            sortDescending: 'Sortowanie malejąco. Kliknij aby zmienić.',
            sortAscending: 'Sortowanie rosnąco. Kliknij aby zmienić.',
            sortNone: 'Bez sortowania. Kliknij aby posortować rosnąco.',
            activateNone: 'Kliknij aby usunąć sortowanie.',
            activateDescending: 'Kliknij aby posortować malejąco.',
            activateAscending: 'Kliknij aby posortować rosnąco.',
        },
        sortBy: 'Sortuj według',
    },
    dataFooter: {
        itemsPerPageText: 'Pozycji na stronie:',
        itemsPerPageAll: 'Wszystkie',
        nextPage: 'Następna strona',
        prevPage: 'Poprzednia strona',
        firstPage: 'Pierwsza strona',
        lastPage: 'Ostatnia strona',
        pageText: '{0}-{1} z {2}',
    },
    datePicker: {
        itemsSelected: '{0} dat(y)',
        nextMonthAriaLabel: 'Następny miesiąc',
        nextYearAriaLabel: 'Następny rok',
        prevMonthAriaLabel: 'Poprzedni miesiąc',
        prevYearAriaLabel: 'Poprzedni rok',
    },
    noDataText: 'Brak danych',
    carousel: {
        prev: 'Poprzedni obraz',
        next: 'Następny obraz',
        ariaLabel: {
            delimiter: 'Carousel slide {0} of {1}',
        },
    },
    calendar: {
        moreEvents: '{0} więcej',
    },
    fileInput: {
        counter: 'Liczba plików: {0}',
        counterSize: 'Liczba plików: {0} (łącznie {1})',
    },
    timePicker: {
        am: 'AM',
        pm: 'PM',
    },
    pagination: {
        ariaLabel: {
            wrapper: 'Nawigacja paginacyjna',
            next: 'Następna strona',
            previous: 'Poprzednia strona',
            page: 'Idź do strony {0}',
            currentPage: 'Bieżąca strona, strona {0}',
        },
    },
    rating: {
        ariaLabel: {
            icon: 'Rating {0} of {1}',
        },
    },
}
