<template>
      <div>

            <v-row>
                  <v-col class="pb-0">
                        <svg @mouseenter="hovered = true"
                              @mouseleave="hovered = false" :width="width"
                              :height="height" viewBox="0 0 128 128" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_2_1248)">
                                    <path d="M50.32 113.11C71.6437 113.11 88.93 95.8237 88.93 74.5C88.93 53.1763 71.6437 35.89 50.32 35.89C28.9962 35.89 11.71 53.1763 11.71 74.5C11.71 95.8237 28.9962 113.11 50.32 113.11Z"
                                          :fill="c_base_color" />
                                    <g :class="{ hovered: hovered }">
                                          <path d="M68.3967 16.1585L20.9569 63.5983C18.4849 66.0703 18.4849 70.0782 20.9569 72.5503L32.8929 84.4862C35.3649 86.9582 39.3729 86.9582 41.8449 84.4862L89.2847 37.0464C91.7567 34.5744 91.7567 30.5665 89.2847 28.0945L77.3487 16.1585C74.8767 13.6865 70.8688 13.6865 68.3967 16.1585Z"
                                                fill="#D0F4DB" />
                                          <path d="M37.38 87.05C35.57 87.05 33.7699 86.36 32.3899 84.99L20.45 73.05C17.7 70.3 17.7 65.83 20.45 63.08L67.8899 15.65C70.6399 12.9 75.11 12.9 77.86 15.65L89.8 27.59C91.13 28.92 91.86 30.69 91.86 32.58C91.86 34.47 91.13 36.24 89.8 37.56L42.36 85C40.99 86.38 39.1799 87.06 37.3699 87.06L37.38 87.05ZM72.87 15.03C71.43 15.03 70 15.58 68.9 16.67L21.4599 64.1C19.2699 66.29 19.2699 69.85 21.4599 72.04L33.4 83.98C35.59 86.17 39.15 86.17 41.34 83.98L88.78 36.54C89.84 35.48 90.42 34.07 90.42 32.57C90.42 31.07 89.84 29.66 88.78 28.6L76.8399 16.66C75.7499 15.57 74.31 15.02 72.87 15.02V15.03Z"
                                                fill="white" />
                                          <path d="M52.1399 26.45L41.8499 16.16C39.3799 13.69 35.3699 13.69 32.8999 16.16L20.9599 28.1C18.4899 30.57 18.4899 34.58 20.9599 37.05L31.2499 47.34L52.1399 26.45Z"
                                                fill="#D0F4DB" />
                                          <path d="M31.2499 48.35L20.4499 37.55C19.1199 36.22 18.3899 34.45 18.3899 32.57C18.3899 30.69 19.1199 28.91 20.4499 27.58L32.3899 15.64C35.1399 12.89 39.6099 12.89 42.3599 15.64L53.1599 26.44L31.2499 48.35ZM37.3699 15.02C35.9299 15.02 34.4999 15.57 33.3999 16.66L21.4599 28.6C20.3999 29.66 19.8199 31.07 19.8199 32.57C19.8199 34.07 20.3999 35.48 21.4599 36.54L31.2399 46.32L51.1099 26.45L41.3299 16.67C40.2399 15.58 38.7999 15.03 37.3599 15.03L37.3699 15.02Z"
                                                fill="white" />
                                          <path d="M89.2901 63.59L79.0001 53.3L58.1101 74.19L68.4001 84.48C70.8701 86.95 74.8801 86.95 77.3501 84.48L89.2901 72.54C91.7601 70.07 91.7601 66.06 89.2901 63.59Z"
                                                fill="#D0F4DB" />
                                          <path d="M72.8701 87.05C70.9901 87.05 69.2101 86.32 67.8801 84.99L57.0801 74.19L78.9901 52.28L89.7901 63.08C91.1201 64.41 91.8501 66.18 91.8501 68.07C91.8501 69.96 91.1201 71.73 89.7901 73.06L77.8501 85C76.5201 86.33 74.7501 87.06 72.8601 87.06L72.8701 87.05ZM59.1201 74.19L68.9001 83.97C69.9601 85.03 71.3701 85.61 72.8701 85.61C74.3701 85.61 75.7801 85.03 76.8401 83.97L88.7801 72.03C90.9701 69.84 90.9701 66.28 88.7801 64.09L79.0001 54.31L59.1301 74.18L59.1201 74.19Z"
                                                fill="white" />
                                          <path d="M37.38 87.41C35.48 87.41 33.58 86.69 32.14 85.24L20.2 73.3C17.31 70.41 17.31 65.71 20.2 62.82L67.63 15.39C70.52 12.5 75.22 12.5 78.11 15.39L90.05 27.33C91.45 28.73 92.22 30.59 92.22 32.57C92.22 34.55 91.45 36.41 90.05 37.81L42.61 85.25C41.17 86.7 39.27 87.42 37.37 87.42L37.38 87.41ZM72.87 15.39C71.53 15.39 70.18 15.9 69.16 16.93L21.72 64.36C19.67 66.41 19.67 69.74 21.72 71.79L33.66 83.73C35.71 85.78 39.04 85.78 41.09 83.73L88.53 36.29C89.52 35.3 90.07 33.98 90.07 32.58C90.07 31.18 89.53 29.86 88.53 28.87L76.59 16.93C75.57 15.91 74.22 15.39 72.88 15.39H72.87Z"
                                                fill="#0A863C" />
                                          <path d="M71.5431 24.9562L68.5591 27.9402L71.5431 30.9241L74.5271 27.9402L71.5431 24.9562Z"
                                                fill="#0A863C" />
                                          <path d="M71.54 32.45L67.03 27.94L71.54 23.43L76.05 27.94L71.54 32.45ZM70.08 27.94L71.54 29.4L73 27.94L71.54 26.48L70.08 27.94Z"
                                                fill="#0A863C" />
                                          <path d="M65.5729 30.929L62.5889 33.913L65.5729 36.897L68.5569 33.913L65.5729 30.929Z"
                                                fill="#0A863C" />
                                          <path d="M65.5701 38.42L61.0601 33.91L65.5701 29.4L70.0801 33.91L65.5701 38.42ZM64.1101 33.91L65.5701 35.37L67.0301 33.91L65.5701 32.45L64.1101 33.91Z"
                                                fill="#0A863C" />
                                          <path d="M32.7359 63.7506L29.752 66.7346L32.7359 69.7185L35.7199 66.7346L32.7359 63.7506Z"
                                                fill="#0A863C" />
                                          <path d="M32.74 71.25L28.23 66.74L32.74 62.23L37.25 66.74L32.74 71.25ZM31.28 66.74L32.74 68.2L34.2 66.74L32.74 65.28L31.28 66.74Z"
                                                fill="#0A863C" />
                                          <path d="M38.7059 57.7777L35.7219 60.7617L38.7059 63.7457L41.6899 60.7617L38.7059 57.7777Z"
                                                fill="#0A863C" />
                                          <path d="M38.7099 65.28L34.2 60.77L38.7099 56.26L43.22 60.77L38.7099 65.28ZM37.25 60.77L38.7099 62.23L40.1699 60.77L38.7099 59.31L37.25 60.77Z"
                                                fill="#0A863C" />
                                          <path d="M38.7088 69.7206L35.7249 72.7046L38.7088 75.6886L41.6928 72.7046L38.7088 69.7206Z"
                                                fill="#0A863C" />
                                          <path d="M38.7099 77.21L34.2 72.7L38.7099 68.19L43.22 72.7L38.7099 77.21ZM37.25 72.7L38.7099 74.16L40.1699 72.7L38.7099 71.24L37.25 72.7Z"
                                                fill="#0A863C" />
                                          <path d="M44.6788 63.7477L41.6948 66.7317L44.6788 69.7157L47.6628 66.7317L44.6788 63.7477Z"
                                                fill="#0A863C" />
                                          <path d="M44.6799 71.25L40.1699 66.74L44.6799 62.23L49.1899 66.74L44.6799 71.25ZM43.2199 66.74L44.6799 68.2L46.1399 66.74L44.6799 65.28L43.2199 66.74Z"
                                                fill="#0A863C" />
                                          <path d="M50.6499 65.28L40.1699 54.8L59.5999 35.37L70.0799 45.85L50.6499 65.28ZM43.2199 54.8L50.6499 62.23L67.0299 45.85L59.5999 38.42L43.2199 54.8Z"
                                                fill="#0A863C" />
                                          <path d="M71.5458 36.899L68.5618 39.883L71.5458 42.867L74.5298 39.883L71.5458 36.899Z"
                                                fill="#0A863C" />
                                          <path d="M71.54 44.39L67.03 39.88L71.54 35.37L76.05 39.88L71.54 44.39ZM70.08 39.88L71.54 41.34L73 39.88L71.54 38.42L70.08 39.88Z"
                                                fill="#0A863C" />
                                          <path d="M77.5157 30.9262L74.5317 33.9102L77.5157 36.8941L80.4997 33.9102L77.5157 30.9262Z"
                                                fill="#0A863C" />
                                          <path d="M77.51 38.42L73 33.91L77.51 29.4L82.02 33.91L77.51 38.42ZM76.05 33.91L77.51 35.37L78.97 33.91L77.51 32.45L76.05 33.91Z"
                                                fill="#0A863C" />
                                          <path d="M31.25 48.86L20.2 37.81C18.8 36.41 18.03 34.55 18.03 32.57C18.03 30.59 18.8 28.73 20.2 27.33L32.14 15.39C35.03 12.5 39.73 12.5 42.62 15.39L53.67 26.44L31.25 48.86ZM37.37 15.38C36.03 15.38 34.68 15.89 33.66 16.92L21.72 28.86C20.73 29.85 20.19 31.17 20.19 32.57C20.19 33.97 20.73 35.29 21.72 36.28L31.25 45.81L50.61 26.45L41.08 16.93C40.06 15.91 38.71 15.39 37.37 15.39V15.38Z"
                                                fill="#0A863C" />
                                          <path d="M72.8701 87.41C70.8901 87.41 69.0301 86.64 67.6301 85.24L56.5801 74.19L79.0001 51.77L90.0501 62.82C92.9401 65.71 92.9401 70.41 90.0501 73.3L78.1101 85.24C76.7101 86.64 74.8501 87.41 72.8701 87.41ZM59.6301 74.19L69.1501 83.71C70.1401 84.7 71.4601 85.25 72.8601 85.25C74.2601 85.25 75.5801 84.71 76.5701 83.71L88.5101 71.77C90.5601 69.72 90.5601 66.39 88.5101 64.34L78.9901 54.82L59.6301 74.18V74.19Z"
                                                fill="#0A863C" />
                                          <path d="M90.72 115.34C104.223 115.34 115.17 104.393 115.17 90.89C115.17 77.3866 104.223 66.44 90.72 66.44C77.2167 66.44 66.27 77.3866 66.27 90.89C66.27 104.393 77.2167 115.34 90.72 115.34Z"
                                                fill="#E28888" />
                                          <path d="M90.73 115.84C76.97 115.84 65.78 104.65 65.78 90.89C65.78 77.13 76.97 65.94 90.73 65.94C104.49 65.94 115.68 77.13 115.68 90.89C115.68 104.65 104.49 115.84 90.73 115.84ZM90.73 66.94C77.52 66.94 66.78 77.68 66.78 90.89C66.78 104.1 77.52 114.84 90.73 114.84C103.94 114.84 114.68 104.1 114.68 90.89C114.68 77.68 103.94 66.94 90.73 66.94Z"
                                                fill="white" />
                                          <path d="M90.72 66.45C104.22 66.45 115.17 77.4 115.17 90.9C115.17 104.4 104.22 115.35 90.72 115.35C77.22 115.35 66.2699 104.4 66.2699 90.9C66.2699 77.4 77.22 66.45 90.72 66.45ZM90.72 63.63C75.68 63.63 63.45 75.86 63.45 90.9C63.45 105.94 75.68 118.17 90.72 118.17C105.76 118.17 117.99 105.94 117.99 90.9C117.99 75.86 105.76 63.63 90.72 63.63Z"
                                                fill="white" />
                                          <path d="M90.7201 97.84C88.8501 97.84 87.3301 99.36 87.3301 101.23C87.5001 105.72 93.9301 105.72 94.1001 101.23C94.1001 99.36 92.5801 97.84 90.7101 97.84H90.7201Z"
                                                fill="white" />
                                          <path d="M94.1101 78.76C92.4301 76.68 89.0201 76.67 87.3401 78.76C86.5001 79.76 86.1401 81.08 86.3701 82.37C87.0401 86.21 87.9901 91.64 88.4201 94.08C88.9001 96.64 92.5501 96.64 93.0301 94.08L95.0801 82.37C95.3101 81.08 94.9501 79.77 94.1101 78.76Z"
                                                fill="white" />
                                    </g>
                              </g>
                              <defs>
                                    <clipPath id="clip0_2_1248">
                                          <rect width="128" height="128"
                                                fill="white" />
                                    </clipPath>
                              </defs>
                        </svg>



                  </v-col>
            </v-row>

            <v-row>
                  <v-col class="pt-5">
                        <span class="font-weight-medium"
                              :style="[cp_font_size]">{{ $t('buttons.eco_fault_other') }}</span>
                  </v-col>
            </v-row>

      </div>
</template>

<script>

export default {
      name: "EcoFaultOther",
      props: {
            selected: { type: Boolean, required: false },
            height: { type: Number, required: 200 },
            width: { type: Number, required: 180 },
            font_size: { type: String, required: '15px' },

            base_color: { type: String, required: true },
            base_hovered_color: { type: String, required: true },
            base_selected_color: { type: String, required: true },

            base_border_color: { type: String, required: true },
            base_border_hovered_color: { type: String, required: true },
            base_border_selected_color: { type: String, required: true },

      },
      data() {
            return {
                  hovered: false
            }
      },
      computed: {
            cp_font_size() {
                  return `font-size: ${this.font_size}`
            },
            c_base_color() {
                  let color = this.base_color
                  if (this.selected) {
                        color = this.base_selected_color
                  } else {
                        if (this.hovered) color = this.base_hovered_color
                  }
                  return color
            },
            c_base_border_color() {
                  let color = this.base_border_color
                  if (this.selected) {
                        color = this.base_border_selected_color
                  } else {
                        if (this.hovered) color = this.base_border_hovered_color
                  }
                  return color
            }
      }
}
</script>

<style scoped></style>