<template>
  <div id="device-visual-conditions-step">
    <app-form-header :title="$t('form.steps.device_visual_conditions.title')"></app-form-header>

    <v-row class="move-top" v-if="$t('form.steps.device_faults.subtitle')">
      <v-col :class="[`mt-${classes.top_padding}`]">
        <div
          :class="classes.subtitle"
          v-text="$t('form.steps.device_faults.subtitle')"
        ></div>
      </v-col>
    </v-row>

    <v-container
      v-if="claim_form.device_model.courier_type === 'DHL'"
      id="regular-devices"
      fluid
      class="pa-5"
    >
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button
            variant="phone_visual_condition_broken_display"
            :selected="
              claim_form.device_visual_conditions.includes(
                'phone_visual_condition_broken_display'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'phone_visual_condition_broken_display',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_visual_condition_scratches_on_screen"
            :selected="
              claim_form.device_visual_conditions.includes(
                'phone_visual_condition_scratches_on_screen'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'phone_visual_condition_scratches_on_screen',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_visual_condition_cracked_cover"
            :selected="
              claim_form.device_visual_conditions.includes(
                'phone_visual_condition_cracked_cover'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'phone_visual_condition_cracked_cover',
              })
            "
          />
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button
            variant="phone_visual_condition_scratches_on_housing"
            :selected="
              claim_form.device_visual_conditions.includes(
                'phone_visual_condition_scratches_on_housing'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'phone_visual_condition_scratches_on_housing',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_visual_condition_broken_case"
            :selected="
              claim_form.device_visual_conditions.includes(
                'phone_visual_condition_broken_case'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'phone_visual_condition_broken_case',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="phone_visual_condition_other"
            :selected="
              claim_form.device_visual_conditions.includes(
                'device_visual_condition_other'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'device_visual_condition_other',
              })
            "
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else id="eco-devices" fluid class="pa-5">
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button
            variant="eco_visual_condition_cracks"
            :selected="
              claim_form.device_visual_conditions.includes(
                'eco_visual_condition_cracks'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'eco_visual_condition_cracks',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="eco_visual_condition_scratches"
            :selected="
              claim_form.device_visual_conditions.includes(
                'eco_visual_condition_scratches'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'eco_visual_condition_scratches',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="eco_visual_condition_damages"
            :selected="
              claim_form.device_visual_conditions.includes(
                'eco_visual_condition_damages'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'eco_visual_condition_damages',
              })
            "
          />
        </v-col>
        <v-col>
          <app-animated-button
            variant="eco_visual_condition_other"
            :selected="
              claim_form.device_visual_conditions.includes(
                'device_visual_condition_other'
              )
            "
            @click="
              syncValue({
                key: 'device_visual_conditions',
                value: 'device_visual_condition_other',
              })
            "
          />
        </v-col>
      </v-row>
    </v-container>

    <v-row style="min-height: 175px">
      <v-col
      class="px-10 pt-3 pb-0"
        v-if="
          claim_form.device_visual_conditions.includes(
            'device_visual_condition_other'
          )
        "
      >
        <v-textarea
          v-model="claim_form.device_visual_conditions_other"
          @update:modelValue="
            updateClaimForm({
              key: 'device_visual_conditions_other',
              value: $event,
            })
          "
          :label="
            $t(
              'form.steps.device_visual_conditions.device_visual_conditions_other'
            )
          "
          variant="outlined"
          color="primary"
          dense
          rows="4"
          :hint="$t('max_500_signs_msg')"
          no-resize
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DeviceVisualConditionsStep",
  setup() {
    const { name } = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return {
            top_padding: 2,
            buttons_menu_width: 150,
            title: ["title-xs", "pa-2"],
            subtitle: ["subtitle-xs"],
          };
        case "sm":
          return {
            top_padding: 3,
            buttons_menu_width: 75,
            title: ["title-sm", "pa-4"],
            subtitle: ["subtitle-sm"],
          };
        default:
          return {
            top_padding: 4,
            buttons_menu_width: 75,
            title: ["ccs-title", "pa-5"],
            subtitle: ["subtitle-default"],
          };
      }
    });
    return { classes };
  },

  computed: {
    ...mapGetters("ClaimForm", { claim_form: "claim_form" }),
  },

  methods: {
    ...mapActions("ClaimForm", {
      updateClaimForm: "updateClaimForm",
      syncValue: "syncValue",
    }),
  },
};
</script>

<style scoped>
</style>