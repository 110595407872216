<template>
  <div id="personal-data-step">
    <app-form-header
      :title="$t('form.steps.personal_data.title')"></app-form-header>
    <div class="pa-5 pt-10">
      <div id="sender-form" class="text-left">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0 pt-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.first_name') }}:</label>
            <v-text-field v-model="claim_form.sender.first_name"
              variant="outlined" color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              :rules="first_name_rules"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0 pt-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.last_name') }}:</label>
            <v-text-field v-model="claim_form.sender.last_name" variant="outlined"
              color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              :rules="last_name_rules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.company_name') }}:</label>
            <v-text-field v-model="claim_form.sender.company_name"
              variant="outlined" color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              @change="onSenderCompanyNameChange()"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="8" lg="6" xl="6" xxl="6" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.street') }}:</label>
            <v-text-field v-model="claim_form.sender.street" variant="outlined"
              color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              :rules="street_name_rules"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2" lg="3" xl="3" xxl="3" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.building_number') }}:</label>
            <v-text-field v-model="claim_form.sender.building_number"
              variant="outlined" color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              :rules="building_number_rules"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="2" lg="3" xl="3" xxl="3" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.apartment_number') }}:</label>
            <v-text-field v-model="claim_form.sender.apartment_number"
              variant="outlined" color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              :rules="apartment_number_rules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!xiaomi_de_client" cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.postal_code') }}:</label>
            <v-text-field v-maska:[postalMask]
              v-model="claim_form.sender.postal_code" variant="outlined"
              color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              :hint="$t('form.steps.personal_data.postal_code_hint')"
              :rules="postal_code_rules"></v-text-field>
          </v-col>
          <v-col v-else cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
            <label class="sender-form-label">{{ $t('form.steps.personal_data.postal_code') }}:</label>
            <v-text-field v-model="claim_form.sender.postal_code"
                          variant="outlined" color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
                          :rules="xiaomi_de_postal_code_rules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.city') }}:</label>
            <v-text-field v-model="claim_form.sender.city" variant="outlined"
              color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              :rules="city_name_rules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.email_address') }}:</label>
            <v-text-field v-model="claim_form.sender.email_address"
              variant="outlined" color="primary" :density="classes.density" class="dense-details"
                          :class="classes.input" :hide-details="classes.hide_details"
              :rules="email_rules"></v-text-field>

          </v-col>
          <v-col v-if="!xiaomi_de_client" cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.phone_number') }}:</label>
            <v-text-field v-model="claim_form.sender.phone_number"
              variant="outlined" color="primary" :density="classes.density" class="dense-details"
                          :class="classes.input" :hide-details="false"
              :hint="$t('form.steps.personal_data.phone_number_hint')"
              :rules="phone_number_rules"></v-text-field>
          </v-col>
          <v-col v-else cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
            <label
                class="sender-form-label">{{ $t('form.steps.personal_data.phone_number') }}:</label>
            <v-text-field v-model="claim_form.sender.phone_number"
                          variant="outlined" color="primary" :density="classes.density" class="dense-details"
                          :class="classes.input" :hide-details="false"
                          :rules="xiaomi_de_phone_number_rules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="claim_form.fixed_client === 'terg'">
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.terg_email_address') }}:</label>
            <v-select v-model="claim_form.sender.terg_email_address"
              :items="terg_emails" color="primary" :density="classes.density"
                      :class="classes.input" :hide-details="classes.hide_details"
                      variant="outlined"
              class="terg-email-address-select"
              :rules="terg_email_address_rules"></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
            <label
              class="sender-form-label">{{ $t('form.steps.personal_data.nr_rma') }}:</label>
            <v-text-field v-model="claim_form.sender.nr_rma" variant="outlined"
              color="primary" :density="classes.density"
                          :class="classes.input" :hide-details="classes.hide_details"
              :rules="nr_rma_rules"></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
<!--    <v-row :class="['pa-3', claim_form.show_recipient || claim_form.show_payer ? 'mt-10' : '']">-->
    <v-row class="px-5 py-0">
      <v-col
        :class="[...classes.options_starter, claim_form.show_recipient ? 'options-starter-checked' : '']"
        @click="claim_form.show_recipient = !claim_form.show_recipient">
        <v-icon
          :icon="claim_form.show_recipient ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`" />
        {{ $t('form.steps.personal_data.show_recipient_from_btn') }}
      </v-col>
    </v-row>
    <div v-if="claim_form.show_recipient" id="recipient-form" class="options-starter-transition mt-1 pa-5 text-left">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0 pt-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.first_name') }}:</label>
          <v-text-field v-model="claim_form.recipient.first_name"
            variant="outlined" color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_recipient ? first_name_rules : []"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0 pt-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.last_name') }}:</label>
          <v-text-field v-model="claim_form.recipient.last_name"
            variant="outlined" color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_recipient ? last_name_rules : []"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.company_name') }}:</label>
          <v-text-field v-model="claim_form.recipient.company_name"
            variant="outlined" color="primary"
            :density="classes.density"
            :class="classes.input" :hide-details="classes.hide_details"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="8" lg="6" xl="6" xxl="6" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.street') }}:</label>
          <v-text-field v-model="claim_form.recipient.street" variant="outlined"
            color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_recipient ? street_name_rules : []"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="2" lg="3" xl="3" xxl="3" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.building_number') }}:</label>
          <v-text-field v-model="claim_form.recipient.building_number"
            variant="outlined" color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_recipient ? building_number_rules : []"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="2" lg="3" xl="3" xxl="3" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.apartment_number') }}:</label>
          <v-text-field v-model="claim_form.recipient.apartment_number"
            variant="outlined" color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_recipient ? apartment_number_rules : []"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!xiaomi_de_client" cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.postal_code') }}:</label>
          <v-text-field v-maska:[postalMask]
            v-model="claim_form.recipient.postal_code" variant="outlined"
            color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_recipient ? postal_code_rules : []"></v-text-field>
        </v-col>
        <v-col v-else cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
          <label class="sender-form-label">{{ $t('form.steps.personal_data.postal_code') }}:</label>
          <v-text-field v-model="claim_form.recipient.postal_code"
                        variant="outlined" color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
                        :rules="xiaomi_de_postal_code_rules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.city') }}:</label>
          <v-text-field v-model="claim_form.recipient.city" variant="outlined"
            color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_recipient ? city_name_rules : []"></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="claim_form.fixed_client !== 'terg' && !hide_option" class="px-5 py-0">
      <v-col
        :class="[...classes.options_starter, claim_form.show_payer ? 'options-starter-checked' : '']"
        @click="claim_form.show_payer = !claim_form.show_payer; copySenderToPayer();">
        <v-icon
          :icon="claim_form.show_payer ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`" />
        {{ $t('form.steps.personal_data.show_payer_from_btn') }}
      </v-col>
    </v-row>
    <div v-if="claim_form.show_payer" id="payer-form" class="options-starter-transition mt-1 pa-5 text-left">
      <v-row :class="['pa-0', 'pt-0', 'mb-5']">
        <v-col class="d-flex"
          :class="[...classes.options_starter, claim_form.payer.payer_statement ? 'options-starter-checked' : '']"
          @click="claim_form.payer.payer_statement = !claim_form.payer.payer_statement;">
          <v-icon
            :icon="claim_form.payer.payer_statement ? `mdi-checkbox-marked-outline` : `mdi-checkbox-blank-outline`" />
          <span
            class="ml-1">{{ $t('form.steps.personal_data.payer_statement') }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.company_name') }}:</label>
          <v-text-field v-model="claim_form.payer.company_name" variant="outlined"
            color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_payer ? required_company_name_rules : []"></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.nip') }}:</label>
          <v-text-field v-model="claim_form.payer.nip" variant="outlined"
            color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :hint="$t('form.steps.personal_data.nip_hint')"
            :rules="claim_form.show_payer ? nip_rules : []"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="8" lg="6" xl="6" xxl="6" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.street') }}:</label>
          <v-text-field v-model="claim_form.payer.street" variant="outlined"
            color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_payer ? street_name_rules : []"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="2" lg="3" xl="3" xxl="3" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.building_number') }}:</label>
          <v-text-field v-model="claim_form.payer.building_number"
            variant="outlined" color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_payer ? building_number_rules : []"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="2" lg="3" xl="3" xxl="3" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.apartment_number') }}:</label>
          <v-text-field v-model="claim_form.payer.apartment_number"
            variant="outlined" color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_payer ? apartment_number_rules : []"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!xiaomi_de_client" cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.postal_code') }}:</label>
          <v-text-field v-maska:[postalMask]
            v-model="claim_form.payer.postal_code" variant="outlined"
            color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_payer ? postal_code_rules : []"></v-text-field>
        </v-col>
        <v-col v-else cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
          <label class="sender-form-label">{{ $t('form.steps.personal_data.postal_code') }}:</label>
          <v-text-field v-model="claim_form.payer.postal_code"
                        variant="outlined" color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
                        :rules="xiaomi_de_postal_code_rules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6" xxl="6" class="pb-0">
          <label
            class="sender-form-label">{{ $t('form.steps.personal_data.city') }}:</label>
          <v-text-field v-model="claim_form.payer.city" variant="outlined"
            color="primary" :density="classes.density"
                        :class="classes.input" :hide-details="classes.hide_details"
            :rules="claim_form.show_payer ? city_name_rules : []"></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapActions, mapGetters } from "vuex";
import { Validator } from "@/mixins/Validator";
import { vMaska } from "maska";

export default {
  name: "PersonalDataStep",
  directives: { maska: vMaska },
  mixins: [Validator],
  setup() {
    const postalMask = { mask: '##-###' };
    const { name } = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property

    const classes = computed(() => {
      // name is reactive and
      // must use .value
      const isModal = window.self !== window.top;
      if(isModal) {
        return {
          top_padding: 2,
          density: 'compact',
          title: ['title-xs', 'pa-2'],
          input: ['mb-2'],
          buttons_menu_width: 150,
          container: ['pa-2'],
          data_form: ['mt-3'],
          options_starter: ['options-starter'],
          hide_details: "auto"
        }
      } else {
        switch (name.value) {
          case 'xs':
            return {
              top_padding: 2,
              buttons_menu_width: 150,
              density: 'compact',
              title: ['title-xs', 'pa-2'],
              options_starter: ['options-starter'],
            }
          case 'sm':
            return {
              top_padding: 3,
              buttons_menu_width: 75,
              density: 'comfortable',
              title: ['title-sm', 'pa-4'],
              options_starter: ['options-starter', 'pa-2'],
            }
          default:
            return {
              top_padding: 4,
              buttons_menu_width: 75,
              density: 'default',
              title: ['ccs-title', 'pa-5'],
              options_starter: ['options-starter', 'starter-align-justify'],
            }
        }
      }
    })
    return { classes, screen_size: name.value, postalMask }
  },
  data() {
    return {
      valid: true,
      terg_emails: [
        'koordynator1.rma@me.pl',
        'koordynator2.rma@me.pl',
        'koordynator3.rma@me.pl',
        'koordynator4.rma@me.pl',
        'koordynator5.rma@me.pl'
      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
    ...mapGetters('ClaimForm', { claim_form: 'claim_form', error: 'error' }),
    hide_option() {
      /** #2426 - XIAOMI_DE CLIENT */
      return this.$route.query.client
          && (this.$route.query.client.toUpperCase() === "XIAOMI_DE" || this.$route.query.client.toUpperCase() === "XIAOMI_FR");
    },
    xiaomi_de_client() {
      /** #2426 - XIAOMI_DE CLIENT */
      return this.$route.query.client
          && (this.$route.query.client.toUpperCase() === "XIAOMI_DE" || this.$route.query.client.toUpperCase() === "XIAOMI_FR");
    }
  },
  methods: {
    ...mapActions('ClaimForm', { updateClaimForm: 'updateClaimForm', syncValue: 'syncValue' }),
    onSenderCompanyNameChange() {
      if (this.claim_form.fixed_client === null) {
        this.claim_form.show_payer = true;
        this.copySenderToPayer();
      }
    },
    copySenderToPayer() {
      this.claim_form.payer.company_name = this.claim_form.sender.company_name;
      this.claim_form.payer.street = this.claim_form.sender.street;
      this.claim_form.payer.building_number = this.claim_form.sender.building_number;
      this.claim_form.payer.apartment_number = this.claim_form.sender.apartment_number;
      this.claim_form.payer.postal_code = this.claim_form.sender.postal_code;
      this.claim_form.payer.city = this.claim_form.sender.city;
    }
  },
  mounted() {
    if (this.claim_form.payer.company_name) {
      this.claim_form.show_payer = true;
    }
  },
}
</script>

<style>
.sender-form-label {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 160%;
  text-transform: uppercase;
  color: #7d7d7d;
  display: block;
  text-align: left;
  min-height: 18px;
}
.dense-details {
  position: relative;
}
.dense-details .v-input__details {
  margin: 0!important;
}
</style>