<template>
  <div id="included-items-step">
    <app-form-header :title="$t('form.steps.included_items.title')"></app-form-header>
    <v-container fluid class="move-top-custom px-4 py-1">
      <v-row dense no-gutters>
        <v-col v-if="!hide_option">
          <app-animated-button variant="included_item_proof_of_purchase"
            :selected="claim_form.included_items.includes('included_item_proof_of_purchase')"
            @click="syncValue({ key: 'included_items', value: 'included_item_proof_of_purchase' })" />
        </v-col>
        <v-col v-if="!hide_option">
          <app-animated-button variant="included_item_battery"
            :selected="claim_form.included_items.includes('included_item_battery')"
            @click="syncValue({ key: 'included_items', value: 'included_item_battery' })" />
        </v-col>
        <v-col>
          <app-animated-button variant="included_item_charger"
            :selected="claim_form.included_items.includes('included_item_charger')"
            @click="syncValue({ key: 'included_items', value: 'included_item_charger' })" />
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col>
          <app-animated-button variant="included_item_usb_cable"
            :selected="claim_form.included_items.includes('included_item_usb_cable')"
            @click="syncValue({ key: 'included_items', value: 'included_item_usb_cable' })" />
        </v-col>
        <v-col>
          <app-animated-button variant="included_item_original_packaging"
            :selected="claim_form.included_items.includes('included_item_original_packaging')"
            @click="syncValue({ key: 'included_items', value: 'included_item_original_packaging' })" />
        </v-col>
        <v-col>
          <app-animated-button variant="included_item_other"
            :selected="claim_form.included_items.includes('included_item_other')"
            @click="syncValue({ key: 'included_items', value: 'included_item_other' })" />
        </v-col>
      </v-row>
      <v-row dense no-gutters v-if="claim_form.device_model.courier_type !== 'DPD'">
        <v-col>
          <div :class="classes.note">
            {{ $t('form.steps.included_items.note') }}<span
              class="font-weight-black">{{ $t('form.steps.included_items.note_bold') }}</span>{{ $t('form.steps.included_items.note2') }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-row style="min-height: 150px;">
      <v-col class="px-10 pt-3 pb-0" v-if="claim_form.included_items.includes('included_item_other')">
        <v-textarea v-model="claim_form.included_items_other"
          @update:modelValue="updateClaimForm({ key: 'included_items_other', value: $event })"
          :label="$t('form.steps.included_items.included_items_other')"
          rows="3"
          variant="outlined" color="primary" dense :hint="$t('max_500_signs_msg')"
          no-resize></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed } from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "IncludedItemsStep",
  setup() {
    const { name } = useDisplay()
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      const isModal = window.self !== window.top;
      switch (name.value) {
        case 'xs':
          return {
            top_padding: 2,
            buttons_menu_width: 150,
            title: ['title-xs', 'pa-2'],
            note: ['text-caution', 'pt-9', 'font-fix', 'text-decoration-underline'],
          }
        case 'sm':
          return {
            top_padding: 3,
            buttons_menu_width: 75,
            title: ['title-sm', 'pa-4'],
            note: ['text-caution', 'pt-11', 'font-fix', 'text-decoration-underline'],
          }
        default:
          return {
            top_padding: 4,
            buttons_menu_width: 75,
            title: ['ccs-title', 'pa-5'],
            note: ['text-caution', (isModal)?'pt-4':'pt-12', 'font-fix', 'text-decoration-underline'],
          }
      }
    })
    return { classes }
  },

  computed: {
    ...mapGetters('ClaimForm', { claim_form: 'claim_form' }),
    hide_option() {
      /** #2426 - XIAOMI_DE CLIENT */
      return this.$route.query.client
          && (this.$route.query.client.toUpperCase() === "XIAOMI_DE" || this.$route.query.client.toUpperCase() === "XIAOMI_FR");
    }
  },

  methods: {
    ...mapActions('ClaimForm', { updateClaimForm: 'updateClaimForm', syncValue: 'syncValue' }),
  },

}
</script>

<style scoped>
.font-fix {
  font-size: 1rem !important;
  line-height: 1.5rem;
  letter-spacing: 0.03125em !important;
}
</style>