<template>
  <div class="mt-2 mb-2 ml-5" v-html="$t('form.steps.regulations.important_note_desc')"></div>
</template>

<script>
import {useDisplay} from "vuetify";
import {computed} from "vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RegulationsImportantNote",
  setup() {
    const {name} = useDisplay();
    // eslint-disable-next-line vue/return-in-computed-property
    const classes = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case "xs":
          return {
            top_padding: 2,
            title: ["title-xs", "pa-2"],
            print_label_check: ['print-label-check', 'print-label-check-align-center', 'mt-3'],
            graphic_size: 100,
          };
        case "sm":
          return {
            top_padding: 3,
            title: ["title-sm", "pa-4"],
            print_label_check: ['print-label-check', 'print-label-check-align-center', 'pa-2', 'mt-3'],
            graphic_size: 150
          };
        default:
          return {
            top_padding: 4,
            title: ["title-default", "pa-5"],
            print_label_check: ['print-label-check', 'print-label-check-align-justify', 'mt-3'],
            graphic_size: 200
          };
      }
    });
    return {
      classes
    };
  },
  computed: {
    ...mapGetters("ClaimForm", {claim_form: "claim_form"}),
  },
  methods: {
    ...mapActions("ClaimForm", {updateClaimForm: "updateClaimForm", syncValue: "syncValue",}),
  }
}
</script>

<style scoped>

</style>