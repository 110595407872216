<template>

  <div id="app-confirm">

    <v-dialog
        v-model="show"
        persistent
        width="auto"
    >
      <v-card>
        <v-card-text>
          <v-row class="mt-0 text-left">
            <v-col>
              <p>{{ message }}</p>
              <p>{{ question }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="menu-btn text-capitalize" variant="contained"
              @click="resetState()"
          >
            {{ cancel_btn }}
          </v-btn>
          <v-btn color="primary" class="menu-btn text-capitalize" variant="contained"
              @click="do_confirm(onConfirm)"
          >
            {{ confirm_btn }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AppConfirm",
  computed: {
    ...mapGetters('AppConfirm', {
      show: 'show',
      message: 'message',
      question: 'question',
      confirm_btn: 'confirm_btn',
      cancel_btn: 'cancel_btn',
      onConfirm: 'onConfirm'
    }),
  },
  methods: {
    ...mapActions('AppConfirm', {resetState: 'resetState', do_confirm: 'do_confirm'}),
  },
}
</script>

<style scoped>

</style>